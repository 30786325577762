import { downloadFile, post } from 'utils/api';

export const getChargerStatus = (data) => {
  const page = data.page ? data.page : 1;
  if (page && data.search && data.sortBy) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['page'];
    delete data['sortBy'];
    delete data['search'];
    return post(`/chargers/status?page=${parseInt(page)}&sortBy=${sortBy}&search=${search}&limit=20`, data);
  } else if (page && data.search) {
    const search = data.search;
    delete data['page'];
    delete data['search'];
    return post(`/chargers/status?page=${parseInt(page)}&search=${search}&limit=20`, data);
  } else if (data.sortBy && data.search) {
    const sortBy = data.sortBy;
    const search = data.search;
    delete data['search'];
    delete data['sortBy'];
    return post(`/chargers/status?search=${search}&sortBy=${sortBy}&limit=20`, data);
  } else if (data.sortBy && page) {
    const sortBy = data.sortBy;
    delete data['page'];
    delete data['sortBy'];
    return post(`/chargers/status?page=${parseInt(page)}&sortBy=${sortBy}&limit=20`, data);
  } else if (data.search) {
    const search = data.search;
    delete data['search'];
    return post(`/chargers/status?search=${search}&limit=20`, data);
  } else if (data.sortBy) {
    const sortBy = data.sortBy;
    delete data['sortBy'];
    return post(`/chargers/status?sortBy=${sortBy}&limit=20`, data);
  } else {
    delete data['page'];
    return post(`/chargers/status?page=${parseInt(page)}&limit=20`, data);
  }
};

export const downloadChargerStatus = (data) => {
  return downloadFile(`/chargers/status`, data);
};

export const getChargerWidgets = (data) => {
  return post(`/chargers/chargerStatusWidgets`, data);
};

export const chargerStatus = {
  getChargerStatus,
  downloadChargerStatus,
  getChargerWidgets,
};
