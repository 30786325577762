import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { BsLightningCharge } from 'react-icons/bs';
import { BsBarChartFill } from 'react-icons/bs';
// import { IoTimeOutline } from 'react-icons/io5';
import { BsPlugin } from 'react-icons/bs';
import { GET_DASHBOARD_INCEPTION_WIDGET_DATA } from 'actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { roundOfDigit } from 'components/common/utils';
import { commaSeperate } from 'components/common/utils';
import { TbCurrencyDirham } from 'react-icons/tb';
import { RxLapTimer } from 'react-icons/rx';
import { GiCharging } from 'react-icons/gi';
import { CgSandClock } from 'react-icons/cg';
import { MdMultilineChart } from 'react-icons/md';
import { FaFileInvoice, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import moment from 'moment-timezone';

const InceptionWidgets = () => {
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);

  const isLoading = useSelector((state) => state.dashboardWidget.isLoading);
  const inceptionWidgetList = useSelector((state) => state.dashboardWidget.inceptionWidgets);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const getDashboardInceptionWidgetData = useCallback(() => {
    dispatch({ type: GET_DASHBOARD_INCEPTION_WIDGET_DATA });
  }, []);

  useEffect(() => {
    getDashboardInceptionWidgetData();
  }, []);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 300;
      checkArrowVisibility();
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 300;
      checkArrowVisibility();
    }
  };

  const checkArrowVisibility = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;

      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    const handleResize = () => {
      checkArrowVisibility();
    };

    if (container) {
      checkArrowVisibility();
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="dashboard-widget-title">
        {'Since Inception'}
        <span className="dashboard-widget-date">- {moment('2024-05-01').format('MMMM D, YYYY')}</span>
      </div>
      <div className="scroll-container">
        {showLeftArrow && <FaChevronLeft className="scroll-button left" onClick={scrollLeft} />}
        {showRightArrow && <FaChevronRight className="scroll-button right" onClick={scrollRight} />}
        <div className="status-blocks" ref={scrollContainerRef} onScroll={checkArrowVisibility}>
          <div className="status-block" style={{ cursor: 'default' }} title="Total Number of Sessions Till Date">
            <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
              <BsLightningCharge color="#3C7CDD" size={35} className="icon--color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{_.toNumber(_.get(inceptionWidgetList, 'total_session_count', 0)).toLocaleString('en-US')}</h1>
              )}
              <p>{'Total Sessions'}</p>
            </div>
          </div>
          <div className="status-block" style={{ cursor: 'default' }} title="Number of Units Consumed Till Date">
            <div className="breating-area" style={{ background: '#be210b21' }}>
              <GiCharging color="#BE210B" size={35} className="icon-inner-color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{commaSeperate(_.get(inceptionWidgetList, 'total_unit_consumed', 0), 2)}</h1>
              )}
              <p>{'Unit Consumed (kWh)'}</p>
            </div>
          </div>
          <div className="status-block" style={{ cursor: 'default' }} title="Average Units Consumed Till Date">
            <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
              <BsBarChartFill color="#3C7CDD" size={35} className="icon-inner-color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{commaSeperate(_.get(inceptionWidgetList, 'average_unitconsumption', 0), 2)}</h1>
              )}
              <p>{'Average Consumption (kWh)'}</p>
            </div>
          </div>
          <div className="status-block" style={{ cursor: 'default' }} title="Total Amount Collected Till Date in AED">
            <div className="breating-area" style={{ background: '#be210b21' }}>
              <TbCurrencyDirham color="#BE210B" size={35} className="icon--color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{commaSeperate(_.get(inceptionWidgetList, 'total_amount_collected', 0), 2)}</h1>
              )}
              <p>{'Total Amount (AED)'}</p>
            </div>
          </div>
          <div className="status-block" style={{ cursor: 'default' }} title="Average Duration Till Date in Minutes">
            <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
              <CgSandClock color="#3C7CDD" size={35} className="icon-inner-color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{`${roundOfDigit(_.get(inceptionWidgetList, 'average_duration', 0))}m`}</h1>
              )}
              <p>{'Average Duration (min)'}</p>
            </div>
          </div>
          <div className="status-block" style={{ cursor: 'default' }} title="Total of Overstay Count Till Date">
            <div className="breating-area" style={{ background: '#be210b21' }}>
              <MdMultilineChart color="#BE210B" size={35} className="icon-inner-color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{_.toNumber(_.get(inceptionWidgetList, 'total_overstay_count', 0)).toLocaleString('en-US')}</h1>
              )}
              <p>{'OverStay Count'}</p>
            </div>
          </div>
          <div className="status-block" style={{ cursor: 'default' }} title="Total of Overstay Minutes Till Date">
            <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
              <RxLapTimer color="#3C7CDD" size={35} className="icon-inner-color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{`${_.toNumber(_.get(inceptionWidgetList, 'total_overstay_mins', 0)).toLocaleString('en-US')}m`}</h1>
              )}
              <p>{'OverStay Minutes'}</p>
            </div>
          </div>
          <div className="status-block" style={{ cursor: 'default' }} title="Total of Overstay Amount Till Date">
            <div className="breating-area" style={{ background: '#be210b21' }}>
              <FaFileInvoice color="#BE210B" size={35} className="icon-inner-color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{`${commaSeperate(_.get(inceptionWidgetList, 'total_overstay_amount', 0), 2)}`}</h1>
              )}
              <p>{'OverStay Amount'}</p>
            </div>
          </div>
          <div className="status-block" style={{ cursor: 'default' }} title="Total Number of Charge Points">
            <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
              <BsPlugin color="#3C7CDD" size={35} className="icon--color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{_.get(inceptionWidgetList, 'charger_points', 0)}</h1>
              )}
              <p>{'No. of Charge Points'}</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InceptionWidgets;
