import { get, downloadFile } from 'utils/api';

export const fetchReportList = (data) => {
  return get(`/report_list`, data);
};

export const fetchReportDownloadRequest = (data) => {
  return get(`/report_download_request`, data);
};

export const createReportDownloadRequest = (data) => {
  return downloadFile(`/report_download_request`, data);
};

export const getSignedUrl = (id) => {
  return get(`/report_download_request/getSignedUrl/${id}`);
};

export const reportDownloadRequest = {
  fetchReportList,
  fetchReportDownloadRequest,
  createReportDownloadRequest,
  getSignedUrl,
};
