import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header/index';
import { Col, Row, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Avatar from 'components/inputs/Avatar';
import { GET_DASHBOARD_WIDGET_DATA, GET_DASHBOARD_PIECHART_DATA, GET_DASHBOARD_ENERGY_SOLD, GET_DASHBOARD_SESSION_COUNT } from 'actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/inputs/Card';
import * as _ from 'lodash';
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Doughnut, Line, Bar } from 'react-chartjs-2';
import moment from 'moment-timezone';
import Select from 'components/inputs/Select';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { FETCH_ZONES } from 'actions/zoneManagement';
import seedrandom from 'seedrandom';
import FullScreenWrapper from './FullScreen';
import DailyWidgets from './DailyWidgets';
import InceptionWidgets from './InceptionWidgets';
import { styles } from 'components/inputs/Select';
import AmountCollectedGraph from './AmountCollectedGraph';
import ChargerAvailability from './ChargerAvailabilityChart';

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const dispatch = useDispatch();
  const lineChartRef = useRef(null);
  const barChartRef = useRef(null);
  const previousSelectionRef = useRef({ year: null, month: null });
  const previousSelectionRefForBookingCount = useRef({ year: null, month: null });
  const hasUserNavigatedForEnergySold = useRef(false);
  const hasUserNavigatedForBookingCount = useRef(false);

  const isLoad = useSelector((state) => state.dashboardPieChart.isLoading);
  const dashboardPieChartData = useSelector((state) => state.dashboardPieChart.dashboardPiechart);
  const dashboardEnergySoldData = useSelector((state) => state.dashboardEnergySold.dashboardEnergySold);
  const isLoaded = useSelector((state) => state.dashboardEnergySold.isLoading);
  const dashboardBookingCountData = useSelector((state) => state.dashboardSessionCount.dashboardSessionCount);
  const isLoader = useSelector((state) => state.dashboardSessionCount.isLoading);
  const allStation = useSelector((state) => state?.chargingStation?.chargingStations);
  const allZones = useSelector((state) => state?.zoneManagement?.zones);

  const [selectedWeekForEnergySold, setSelectedWeekForEnergySold] = useState();
  const [selectedWeekForBookingCount, setSelectedWeekForBookingCount] = useState();
  const [selectedYearForEnergySold, setSelectedYearForEnergySold] = useState(moment().year());
  const [selectedYearForBookingCount, setSelectedYearForBookingCount] = useState(moment().year());
  const [selectedMonthForEnergySold, setSelectedMonthForEnergySold] = useState(moment().month() + 1);
  const [selectedMonthForBookingCount, setSelectedMonthForBookingCount] = useState(moment().month() + 1);
  const [selectedZoneForEnergySold, setSelectedZoneForEnergySold] = useState('');
  const [selectedZoneForBookingCount, setSelectedZoneForBookingCount] = useState('');
  const [selectedStationForEnergySold, setSelectedStationForEnergySold] = useState([]);
  const [selectedStationForBookingCount, setSelectedStationForBookingCount] = useState([]);
  const [stations, setStations] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [filterState, setFilterState] = useState({
    zone: '',
    stations: [],
    schedule: 'Weekly',
    from: moment().tz('Asia/Dubai').startOf('week'),
    to: moment().tz('Asia/Dubai').endOf('week'),
  });

  const [sessionCountFilterState, setSessionCountFilterState] = useState({
    zone: '',
    stations: [],
    schedule: 'Weekly',
    from: moment().tz('Asia/Dubai').startOf('week'),
    to: moment().tz('Asia/Dubai').endOf('week'),
  });

  useEffect(() => {
    let payload = {
      from: filterState?.from,
      to: filterState?.to,
    };
    if (!_.isEmpty(filterState.zone)) {
      payload = {
        ...payload,
        zone: filterState?.zone,
      };
    } else if (!_.isEmpty(filterState?.stations)) {
      payload = {
        ...payload,
        stations: filterState?.stations,
      };
    }
    getDashboardEnergySoldData(payload);
  }, [filterState]);

  useEffect(() => {
    let payload = {
      from: sessionCountFilterState?.from,
      to: sessionCountFilterState?.to,
    };
    if (!_.isEmpty(sessionCountFilterState.zone)) {
      payload = {
        ...payload,
        zone: sessionCountFilterState?.zone,
      };
    } else if (!_.isEmpty(sessionCountFilterState?.stations)) {
      payload = {
        ...payload,
        stations: sessionCountFilterState?.stations,
      };
    }
    getDashboardSessionCountData(payload);
  }, [sessionCountFilterState]);

  const [weeksForEnerghSold, setWeeksForEnergySold] = useState([]);
  const [weeksForBookingCount, setWeeksForBookingCount] = useState([]);

  const getDashboardWidgetData = useCallback(() => {
    dispatch({ type: GET_DASHBOARD_WIDGET_DATA });
  }, []);

  const getDashboardPiechartData = useCallback(() => {
    dispatch({ type: GET_DASHBOARD_PIECHART_DATA });
  }, []);

  const getDashboardEnergySoldData = useCallback(
    (values) => {
      dispatch({ type: GET_DASHBOARD_ENERGY_SOLD, payload: values });
    },
    [dispatch]
  );

  const getDashboardSessionCountData = useCallback(
    (values) => {
      dispatch({ type: GET_DASHBOARD_SESSION_COUNT, payload: values });
    },
    [dispatch]
  );

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  const getAllZones = useCallback((data = {}) => {
    dispatch({ type: FETCH_ZONES, payload: data });
  }, []);

  useEffect(() => {
    getAllStation();
    getAllZones({ limit: 999 });
    getDashboardWidgetData();
    getDashboardPiechartData();
  }, []);

  const currentYear = moment().year();

  const years = Array.from({ length: currentYear - 2024 + 1 }, (_, i) => 2024 + i);

  const generateMonths = (year) => {
    const currentYear = moment().year();
    const currentMonth = moment().month();

    let startMonth = 1;
    let endMonth = 12;

    if (year === 2024) {
      startMonth = 5;
    } else if (year === currentYear) {
      endMonth = currentMonth + 1;
    }

    return Array.from({ length: endMonth - startMonth + 1 }, (_, i) => startMonth + i);
  };

  const [monthsForEnergySold, setMonthsforEnergySold] = useState(generateMonths(selectedYearForEnergySold));

  const [monthsForBookingCount, setMonthsforBookingCount] = useState(generateMonths(selectedYearForBookingCount));

  useEffect(() => {
    const updatedMonths = generateMonths(selectedYearForEnergySold);
    setMonthsforEnergySold(updatedMonths);

    if (selectedMonthForEnergySold !== null && !updatedMonths.includes(selectedMonthForEnergySold)) {
      setSelectedMonthForEnergySold(updatedMonths[updatedMonths.length - 1]);
    }
  }, [selectedYearForEnergySold]);

  useEffect(() => {
    const updatedMonths = generateMonths(selectedYearForBookingCount);
    setMonthsforBookingCount(updatedMonths);

    if (selectedMonthForBookingCount !== null && !updatedMonths.includes(selectedMonthForBookingCount)) {
      setSelectedMonthForBookingCount(updatedMonths[updatedMonths.length - 1]);
    }
  }, [selectedYearForBookingCount]);

  function getWeeksInMonth(date) {
    const firstDay = moment(date).startOf('month').startOf('week');
    const lastDay = moment(date).endOf('month').endOf('week');

    return lastDay.diff(firstDay, 'weeks') + 1;
  }

  useEffect(() => {
    if (selectedYearForEnergySold && selectedMonthForEnergySold) {
      const weeksInMonth = getWeeksInMonth(moment([selectedYearForEnergySold, selectedMonthForEnergySold - 1]));

      const weekData = Array.from({ length: weeksInMonth }, (_, i) => {
        const start = moment([selectedYearForEnergySold, selectedMonthForEnergySold - 1])
          .startOf('month')
          .add(i, 'weeks')
          .startOf('week');

        const end = moment(start).endOf('week');

        return {
          value: i + 1,
          label: `${start.format('MMM DD')} - ${end.format('MMM DD')}`,
          startDate: start,
          endDate: end,
        };
      });

      setWeeksForEnergySold(weekData);

      const isCurrentYear = selectedYearForEnergySold === moment().year();
      const isCurrentMonth = selectedMonthForEnergySold === moment().month() + 1;

      if (isCurrentYear && isCurrentMonth && hasUserNavigatedForEnergySold.current) {
        setSelectedWeekForEnergySold(null);
        hasUserNavigatedForEnergySold.current = false;
      } else {
        setSelectedWeekForEnergySold(weekData.find((w) => moment().isBetween(w.startDate, w.endDate, null, '[]'))?.value);
      }

      if (previousSelectionRef.current.year !== selectedYearForEnergySold || previousSelectionRef.current.month !== selectedMonthForEnergySold) {
        hasUserNavigatedForEnergySold.current = true;
      }

      previousSelectionRef.current = { year: selectedYearForEnergySold, month: selectedMonthForEnergySold };
    }
  }, [selectedMonthForEnergySold, selectedYearForEnergySold]);

  useEffect(() => {
    if (selectedYearForBookingCount && selectedMonthForBookingCount) {
      const weeksInMonth = getWeeksInMonth(moment([selectedYearForBookingCount, selectedMonthForBookingCount - 1]));

      const weekData = Array.from({ length: weeksInMonth }, (_, i) => {
        const start = moment([selectedYearForBookingCount, selectedMonthForBookingCount - 1])
          .startOf('month')
          .add(i, 'weeks')
          .startOf('week');

        const end = moment(start).endOf('week');

        return {
          value: i + 1,
          label: `${start.format('MMM DD')} - ${end.format('MMM DD')}`,
          startDate: start,
          endDate: end,
        };
      });

      setWeeksForBookingCount(weekData);

      const isCurrentYear = selectedYearForBookingCount === moment().year();
      const isCurrentMonth = selectedMonthForBookingCount === moment().month() + 1;

      if (isCurrentYear && isCurrentMonth && hasUserNavigatedForBookingCount.current) {
        setSelectedWeekForBookingCount(null);
        hasUserNavigatedForBookingCount.current = false;
      } else {
        setSelectedWeekForBookingCount(weekData.find((w) => moment().isBetween(w.startDate, w.endDate, null, '[]'))?.value);
      }

      if (
        previousSelectionRefForBookingCount.current.year !== selectedYearForBookingCount ||
        previousSelectionRefForBookingCount.current.month !== selectedMonthForBookingCount
      ) {
        hasUserNavigatedForBookingCount.current = true;
      }

      previousSelectionRefForBookingCount.current = { year: selectedYearForBookingCount, month: selectedMonthForBookingCount };
    }
  }, [selectedMonthForBookingCount, selectedYearForBookingCount]);

  useEffect(() => {
    if (allStation && allStation.length > 0) {
      const uniqueStations = _.uniqBy(allStation, 'id');
      setStations(uniqueStations);
    }
  }, [allStation]);

  const colorMap = {
    Available: '#14AC7F',
    Unavailable: '#FF8B00',
    'In Use': '#C9A11B',
    Preparing: '#ffffff',
    SuspendedEVSE: 'rgba(153, 102, 255, 0.6)',
    SuspendedEV: 'rgb(230, 169, 236)',
    Finishing: '#1e3249',
    Reserved: 'rgb(230, 246, 157)',
    Faulted: '#be210b',
    Charging: '#3C7CDD',
  };

  const filteredEntries = Object.entries(dashboardPieChartData).filter(([, value]) => value !== 0);

  const labels = filteredEntries.map(([key]) => key);
  const data = filteredEntries.map(([, value]) => value);
  const backgroundColors = labels.map((label) => colorMap[label]);

  const chargePointStatus = {
    datasets: [
      {
        label: 'ChargePoint Status',
        data: data,
        backgroundColor: backgroundColors,
        borderColor: backgroundColors,
        borderWidth: 1,
      },
    ],
    labels: labels,
  };
  const hashStationID = (id) => {
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
      const char = id.charCodeAt(i);
      hash = (hash << 27) - hash + char;
      hash &= hash;
    }
    return Math.abs(hash).toString();
  };

  const generateRandomColor = (stationID) => {
    const seed = hashStationID(stationID);

    const rng = seedrandom(seed);

    const hue = Math.floor(rng() * 360);
    const saturation = Math.floor(rng() * 50) + 50;
    const lightness = Math.floor(rng() * 40) + 40;

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const energySold = useMemo(() => {
    const datasets = [];

    if (filterState?.schedule === 'Weekly') {
      if (!_.isEmpty(filterState?.stations)) {
        filterState?.stations?.forEach((stationId) => {
          const station = stations.find((s) => s.id === stationId);
          if (station) {
            const stationData = dashboardEnergySoldData[station.code]?.unitConsumed || [];
            const color = generateRandomColor(station.id);
            datasets.push({
              label: station.code,
              data: stationData.map((item) => (item.total === null ? null : item.total)),
              borderColor: color,
              backgroundColor: color,
              tension: 0.4,
              spanGaps: false,
            });
          }
        });
      } else {
        const stationData = stations?.filter((s) => s.zones?.includes(filterState?.zone));
        stationData.forEach((stationId) => {
          const station = stations.find((s) => s.id === stationId?.id);
          if (station) {
            const stationData = dashboardEnergySoldData[station.code]?.unitConsumed || [];
            const color = generateRandomColor(station.id);
            datasets.push({
              label: station.code,
              data: stationData.map((item) => (item.total === null ? null : item.total)),
              borderColor: color,
              backgroundColor: color,
              tension: 0.4,
              spanGaps: false,
            });
          }
        });
      }
    } else if (filterState?.schedule === 'Yearly') {
      if (!_.isEmpty(filterState?.stations)) {
        filterState?.stations.forEach((stationId) => {
          const station = stations.find((s) => s.id === stationId);
          if (station) {
            const stationData = dashboardEnergySoldData[station.code]?.unitConsumed || [];
            const color = generateRandomColor(station.id);
            datasets.push({
              label: station.code,
              data: stationData.map((item) => (item.total === null ? null : item.total)),
              borderColor: color,
              backgroundColor: color,
              tension: 0.4,
              spanGaps: false,
            });
          }
        });
      } else {
        const stationData = stations?.filter((s) => s.zones?.includes(filterState?.zone));
        stationData.forEach((stationId) => {
          const station = stations.find((s) => s.id === stationId?.id);
          if (station) {
            const stationData = dashboardEnergySoldData[station.code]?.unitConsumed || [];
            const color = generateRandomColor(station.id);
            datasets.push({
              label: station.code,
              data: stationData.map((item) => (item.total === null ? null : item.total)),
              borderColor: color,
              backgroundColor: color,
              tension: 0.4,
              spanGaps: false,
            });
          }
        });
      }
    } else {
      if (!_.isEmpty(filterState?.stations)) {
        filterState?.stations.forEach((stationId) => {
          const station = stations.find((s) => s.id === stationId);
          if (station) {
            const stationData = dashboardEnergySoldData[station.code]?.unitConsumed || [];
            const color = generateRandomColor(station.id);
            datasets.push({
              label: station.code,
              data: stationData.map((item) => (item.total === null ? null : item.total)),
              borderColor: color,
              backgroundColor: color,
              tension: 0.4,
              spanGaps: false,
            });
          }
        });
      } else {
        const stationData = stations?.filter((s) => s.zones?.includes(filterState?.zone));
        stationData.forEach((stationId) => {
          const station = stations.find((s) => s.id === stationId?.id);
          if (station) {
            const stationData = dashboardEnergySoldData[station.code]?.unitConsumed || [];
            const color = generateRandomColor(station.id);
            datasets.push({
              label: station.code,
              data: stationData.map((item) => (item.total === null ? null : item.total)),
              borderColor: color,
              backgroundColor: color,
              tension: 0.4,
              spanGaps: false,
            });
          }
        });
      }
    }

    if (filterState?.schedule === 'Weekly') {
      if (dashboardEnergySoldData.average) {
        datasets.push({
          label: 'Total Energy Sold',
          data: dashboardEnergySoldData?.average?.unitConsumed.map((item) => (item.total === null ? null : item.total)),
          borderColor: '#34D399',
          tension: 0.4,
          spanGaps: false,
        });
        if (dashboardEnergySoldData.average.unitConsumed.some((item) => item?.average !== undefined)) {
          datasets.push({
            label: 'Average Energy Sold',
            data: dashboardEnergySoldData.average.unitConsumed.map((item) => (item.average === null ? null : item.average)),
            borderColor: '#FED035',
            tension: 0.4,
            spanGaps: false,
          });
        }
      }
    } else if (filterState?.schedule === 'Yearly') {
      if (dashboardEnergySoldData.average) {
        datasets.push({
          label: 'Total Energy Sold',
          data: dashboardEnergySoldData?.average?.unitConsumed.map((item) => (item.total === null ? null : item.total)),
          borderColor: '#34D399',
          tension: 0.4,
          spanGaps: false,
        });
        if (dashboardEnergySoldData.average.unitConsumed.some((item) => item?.average !== undefined)) {
          datasets.push({
            label: 'Average Energy Sold',
            data: dashboardEnergySoldData.average.unitConsumed.map((item) => (item.average === null ? null : item.average)),
            borderColor: '#FED035',
            tension: 0.4,
            spanGaps: false,
          });
        }
      }
    } else {
      if (dashboardEnergySoldData.average) {
        datasets.push({
          label: 'Total Energy Sold',
          data: dashboardEnergySoldData?.average?.unitConsumed.map((item) => (item.total === null ? null : item.total)),
          borderColor: '#34D399',
          tension: 0.4,
          spanGaps: false,
        });
        if (dashboardEnergySoldData.average.unitConsumed.some((item) => item?.average !== undefined)) {
          datasets.push({
            label: 'Average Energy Sold',
            data: dashboardEnergySoldData.average.unitConsumed.map((item) => (item.average === null ? null : item.average)),
            borderColor: '#FED035',
            tension: 0.4,
            spanGaps: false,
          });
        }
      }
    }

    return {
      labels: dashboardEnergySoldData.average
        ? filterState?.schedule === 'Weekly'
          ? dashboardEnergySoldData?.average?.unitConsumed.map((item) => item.weekDay)
          : filterState?.schedule === 'Yearly'
          ? dashboardEnergySoldData?.average?.unitConsumed.map((item) => item.month)
          : dashboardEnergySoldData?.average?.unitConsumed.map((item) => item.date)
        : [],
      datasets,
    };
  }, [dashboardEnergySoldData, selectedStationForEnergySold, stations]);

  const chargerBookingCount = useMemo(() => {
    if (sessionCountFilterState?.schedule === 'Weekly') {
      return {
        labels: _.map(dashboardBookingCountData?.results?.sessionCount, (item) => item.weekDay),
        datasets: [
          {
            label: 'Success Sessions',
            data: _.map(dashboardBookingCountData?.results?.sessionCount, (item) => item.success),
            backgroundColor: '#14AC7F',
            barThickness: 15,
          },
          {
            label: 'Failed Sessions',
            data: _.map(dashboardBookingCountData?.results?.sessionCount, (item) => item.failed),
            backgroundColor: '#be210b',
            barThickness: 15,
          },
        ],
      };
    } else if (sessionCountFilterState?.schedule === 'Yearly') {
      return {
        labels: _.map(dashboardBookingCountData?.results?.sessionCount, (item) => item.month),
        datasets: [
          {
            label: 'Success Sessions',
            data: _.map(dashboardBookingCountData?.results?.sessionCount, (item) => item.success),
            backgroundColor: '#14AC7F',
            barThickness: 15,
          },
          {
            label: 'Failed Sessions',
            data: _.map(dashboardBookingCountData?.results?.sessionCount, (item) => item.failed),
            backgroundColor: '#be210b',
            barThickness: 15,
          },
        ],
      };
    } else {
      return {
        labels: _.map(dashboardBookingCountData?.results?.sessionCount, (item) => item.date),
        datasets: [
          {
            label: 'Success Sessions',
            data: _.map(dashboardBookingCountData?.results?.sessionCount, (item) => item.success),
            backgroundColor: '#14AC7F',
            barThickness: 15,
          },
          {
            label: 'Failed Sessions',
            data: _.map(dashboardBookingCountData?.results?.sessionCount, (item) => item.failed),
            backgroundColor: '#be210b',
            barThickness: 15,
          },
        ],
      };
    }
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 10,
          color: '#ffffff',
        },
      },
    },
  };
  const downloadLineGraph = () => {
    if (lineChartRef.current) {
      const chartInstance = lineChartRef.current;
      const originalCanvas = chartInstance.canvas;

      const tempCanvas = document.createElement('canvas');
      const extraHeight = 60;
      tempCanvas.width = originalCanvas.width;
      tempCanvas.height = originalCanvas.height + extraHeight;

      const ctx = tempCanvas.getContext('2d');

      ctx.fillStyle = '#1b2a38';
      ctx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

      const combinedTextElement = document.querySelector('.dashboard-energySold_name');
      const [graphTitle, dateInfo] = combinedTextElement ? combinedTextElement.innerText.split('\n') : ['Line Chart', ''];

      ctx.font = '22px Arial';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';

      ctx.fillText(graphTitle, tempCanvas.width / 2, 20);

      ctx.font = '17px Arial';
      ctx.fillStyle = '#999';
      ctx.fillText(dateInfo, tempCanvas.width / 2, 50);

      ctx.drawImage(originalCanvas, 0, extraHeight);

      const imageURL = tempCanvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = `${graphTitle.replace(/\s+/g, '_')}_${dateInfo.replace(/[^\w\d_-]/g, '')}.png`;
      link.click();
    }
  };

  const downloadBarGraph = () => {
    if (barChartRef.current) {
      const chartInstance = barChartRef.current;
      const originalCanvas = chartInstance.canvas;

      const tempCanvas = document.createElement('canvas');
      const extraHeight = 60;
      tempCanvas.width = originalCanvas.width;
      tempCanvas.height = originalCanvas.height + extraHeight;

      const ctx = tempCanvas.getContext('2d');

      ctx.fillStyle = '#1b2a38';
      ctx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

      const combinedTextElement = document.querySelector('.dashboard-bookingCount_name');
      const [graphTitle, dateInfo] = combinedTextElement ? combinedTextElement.innerText.split('\n') : ['Bar Chart', ''];

      ctx.font = '22px Arial';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';

      ctx.fillText(graphTitle, tempCanvas.width / 2, 20);

      ctx.font = '17px Arial';
      ctx.fillStyle = '#999';
      ctx.fillText(dateInfo, tempCanvas.width / 2, 50);

      ctx.drawImage(originalCanvas, 0, extraHeight);

      const imageURL = tempCanvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = `${graphTitle.replace(/\s+/g, '_')}_${dateInfo.replace(/[^\w\d_-]/g, '')}.png`;
      link.click();
    }
  };

  return (
    <React.Fragment>
      <LayoutContainer>
        <AdminHeader title="Dashboard" />
        <div className="page-content-wrapper scrollable">
          <DailyWidgets />
          <InceptionWidgets />
          <div className="dashboard-details__main">
            <div className="dashboard-details--inner">
              <div className="dashboard-table__main">
                <Row>
                  <Col xl={6} lg={12} md={12}>
                    <Card>
                      <FullScreenWrapper isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} downloadCharts={downloadLineGraph}>
                        <div className="dashboard-energySold_chart_main">
                          <div className="dashboard-energySold_chart_inner">
                            <div className="dashboard-energySold_chart_block">
                              <div
                                className="dashboard-energySold_name"
                                style={{
                                  margin: isFullScreen ? '0 1vw' : '0',
                                }}
                              >
                                Energy Sold (kWh)
                                {filterState.schedule === 'Yearly' ? (
                                  <span className="chart-date-info">{selectedYearForEnergySold}</span>
                                ) : filterState.schedule === 'Monthly' && selectedMonthForEnergySold && selectedMonthForEnergySold !== 'All' ? (
                                  <span className="chart-date-info">{`${selectedYearForEnergySold} - ${moment()
                                    .month(selectedMonthForEnergySold - 1)
                                    .format('MMMM')}`}</span>
                                ) : filterState.schedule === 'Weekly' && selectedWeekForEnergySold && selectedWeekForEnergySold !== 'All' ? (
                                  <span className="chart-date-info">{`${moment(
                                    weeksForEnerghSold.find((w) => w.value === selectedWeekForEnergySold)?.startDate
                                  ).format('DD/MM/YYYY')} to ${moment(
                                    weeksForEnerghSold.find((w) => w.value === selectedWeekForEnergySold)?.endDate
                                  ).format('DD/MM/YYYY')}`}</span>
                                ) : null}
                              </div>
                            </div>

                            <div
                              className="dashboard-energySold_filters"
                              style={{
                                margin: isFullScreen ? '1vw' : '',
                              }}
                            >
                              {_.isEmpty(selectedStationForEnergySold) ? (
                                <Select
                                  options={_.map(allZones, (item) => {
                                    return { label: item.name, value: item.id };
                                  })}
                                  placeholder="Select Zones"
                                  value={selectedZoneForEnergySold}
                                  onChange={(val) => {
                                    setFilterState({
                                      ...filterState,
                                      zone: val,
                                    });
                                    setSelectedZoneForEnergySold(val);
                                  }}
                                  className="select-container"
                                  isClearable
                                  styles={{
                                    ...styles(),
                                    menu: (provided) => ({
                                      ...provided,
                                      width: '350px',
                                    }),
                                  }}
                                />
                              ) : null}
                              {!selectedZoneForEnergySold ? (
                                <Select
                                  options={
                                    selectedStationForEnergySold?.includes('All')
                                      ? [{ label: 'All', value: 'All' }]
                                      : [
                                          { value: 'All', label: 'All' },
                                          ...stations.map((station) => ({
                                            label: station?.name,
                                            value: station?.id,
                                          })),
                                        ]
                                  }
                                  placeholder="Select Station"
                                  isMulti
                                  value={selectedStationForEnergySold}
                                  onChange={(val) => {
                                    if (val === null) {
                                      setFilterState({
                                        ...filterState,
                                        stations: [],
                                      });
                                    } else if (val.includes('All')) {
                                      setFilterState({
                                        ...filterState,
                                        stations: stations.map((station) => station?.id),
                                      });
                                    } else {
                                      setFilterState({
                                        ...filterState,
                                        stations: val,
                                      });
                                    }
                                    setSelectedStationForEnergySold(val);
                                  }}
                                  className="select-container"
                                  isClearable
                                  styles={{
                                    ...styles(),
                                    menu: (provided) => ({
                                      ...provided,
                                      width: '350px',
                                    }),
                                  }}
                                />
                              ) : null}
                              <Select
                                options={_.map(years, (year) => {
                                  return { label: year, value: year };
                                })}
                                placeholder="Select Year"
                                value={selectedYearForEnergySold}
                                onChange={(e) => {
                                  setFilterState({
                                    ...filterState,
                                    schedule: 'Yearly',
                                    from: moment().year(Number(e)).tz('Asia/Dubai').startOf('year'),
                                    to: moment().year(Number(e)).tz('Asia/Dubai').endOf('year'),
                                  });
                                  setSelectedYearForEnergySold(Number(e));
                                  setSelectedMonthForEnergySold(null);
                                }}
                                className="select-container"
                              />
                              <Select
                                options={[
                                  { value: 'All', label: 'All' },
                                  ..._.map(monthsForEnergySold, (month) => {
                                    return {
                                      label: moment()
                                        .month(month - 1)
                                        .format('MMMM'),
                                      value: month,
                                    };
                                  }),
                                ]}
                                value={selectedMonthForEnergySold ?? 'All'}
                                onChange={(e) => {
                                  if (e === null || e === 'All') {
                                    setFilterState({
                                      ...filterState,
                                      schedule: 'Yearly',
                                      from: moment(filterState?.from).tz('Asia/Dubai').startOf('year'),
                                      to: moment(filterState?.to).tz('Asia/Dubai').endOf('year'),
                                    });
                                  } else {
                                    setFilterState({
                                      ...filterState,
                                      schedule: 'Monthly',
                                      from: moment(filterState?.from)
                                        .set({ month: Number(e) - 1 })
                                        .tz('Asia/Dubai')
                                        .startOf('month'),
                                      to: moment(filterState?.to)
                                        .set({ month: Number(e) - 1 })
                                        .tz('Asia/Dubai')
                                        .endOf('month'),
                                    });
                                  }
                                  setSelectedMonthForEnergySold(e);
                                  setSelectedWeekForEnergySold(null);
                                }}
                                className="select-container"
                                isClearable
                              />
                              {selectedMonthForEnergySold ? (
                                selectedMonthForEnergySold !== 'All' ? (
                                  <Select
                                    options={[
                                      { value: 'All', label: 'All' },
                                      ...weeksForEnerghSold.map((w) => ({
                                        value: w.value,
                                        label: w.label,
                                      })),
                                    ]}
                                    placeholder="Select Week"
                                    value={selectedWeekForEnergySold ?? 'All'}
                                    onChange={(e) => {
                                      if (e === null || e === 'All') {
                                        setFilterState({
                                          ...filterState,
                                          schedule: 'Monthly',
                                          from: moment(filterState?.from)
                                            .year(selectedYearForEnergySold)
                                            .set({ month: selectedMonthForEnergySold - 1 })
                                            .tz('Asia/Dubai')
                                            .startOf('month'),
                                          to: moment(filterState?.to)
                                            .year(selectedYearForEnergySold)
                                            .set({ month: selectedMonthForEnergySold - 1 })
                                            .tz('Asia/Dubai')
                                            .endOf('month'),
                                        });
                                      } else {
                                        setFilterState({
                                          ...filterState,
                                          schedule: 'Weekly',
                                          from: moment(weeksForEnerghSold.find((w) => w.value === parseInt(e, 10))?.startDate).tz('Asia/Dubai', true),
                                          to: moment(weeksForEnerghSold.find((w) => w.value === parseInt(e, 10))?.endDate).tz('Asia/Dubai', true),
                                        });
                                      }
                                      setSelectedWeekForEnergySold(e);
                                    }}
                                    className="select-weekly-container"
                                    isClearable
                                  />
                                ) : null
                              ) : null}
                            </div>
                          </div>
                          <div className="dashboard-energySold_chart-img">
                            {isLoaded ? (
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                              </div>
                            ) : (
                              <div
                                className="energySold_chart-img"
                                style={{
                                  flexGrow: '1',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  height: isFullScreen ? 'calc(90vh - 100px)' : 'calc(70vh - 200px)',
                                  // minHeight: '400px',
                                  margin: isFullScreen ? '0 1vw' : '0',
                                }}
                              >
                                <Line
                                  ref={lineChartRef}
                                  data={energySold}
                                  options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                      legend: {
                                        position: 'bottom',
                                        labels: {
                                          boxWidth: 10,
                                          color: '#ffffff',
                                        },
                                      },
                                    },
                                  }}
                                  height="100vh"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </FullScreenWrapper>
                    </Card>
                  </Col>
                  <Col xl={6} lg={12} md={12}>
                    <Card>
                      <FullScreenWrapper isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} downloadCharts={downloadBarGraph}>
                        <div className="dashboard-bookingCount_chart_main">
                          <div className="dashboard-bookingCount_chart_inner">
                            <div className="dashboard-bookingCount_chart_block">
                              <div
                                className="dashboard-bookingCount_name"
                                style={{
                                  margin: isFullScreen ? '0 1vw' : '',
                                }}
                              >
                                Session Count
                                {sessionCountFilterState.schedule === 'Yearly' && selectedYearForBookingCount ? (
                                  <span className="chart-date-info">{selectedYearForBookingCount}</span>
                                ) : sessionCountFilterState.schedule === 'Monthly' &&
                                  selectedMonthForBookingCount &&
                                  selectedMonthForBookingCount !== 'All' ? (
                                  <span className="chart-date-info">{`${selectedYearForBookingCount} - ${moment()
                                    .month(selectedMonthForBookingCount - 1)
                                    .format('MMMM')}`}</span>
                                ) : sessionCountFilterState.schedule === 'Weekly' &&
                                  selectedWeekForBookingCount &&
                                  selectedWeekForBookingCount !== 'All' ? (
                                  <span className="chart-date-info">{`${moment(
                                    weeksForBookingCount.find((w) => w.value === selectedWeekForBookingCount)?.startDate
                                  ).format('DD/MM/YYYY')} to ${moment(
                                    weeksForBookingCount.find((w) => w.value === selectedWeekForBookingCount)?.endDate
                                  ).format('DD/MM/YYYY')}`}</span>
                                ) : null}
                              </div>
                            </div>
                            <div
                              className="dashboard-bookingCount_filters"
                              style={{
                                margin: isFullScreen ? '1vw' : '',
                              }}
                            >
                              {_.isEmpty(selectedStationForBookingCount) ? (
                                <Select
                                  options={_.map(allZones, (item) => {
                                    return { label: item.name, value: item.id };
                                  })}
                                  placeholder="Select Zones"
                                  value={selectedZoneForBookingCount}
                                  onChange={(val) => {
                                    setSessionCountFilterState({
                                      ...sessionCountFilterState,
                                      zone: val,
                                    });
                                    setSelectedZoneForBookingCount(val);
                                  }}
                                  className="select-container"
                                  isClearable
                                  styles={{
                                    ...styles(),
                                    menu: (provided) => ({
                                      ...provided,
                                      width: '350px',
                                    }),
                                  }}
                                />
                              ) : null}
                              {!selectedZoneForBookingCount ? (
                                <Select
                                  options={
                                    selectedStationForBookingCount?.includes('All')
                                      ? [{ label: 'All', value: 'All' }]
                                      : [
                                          { value: 'All', label: 'All' },
                                          ...stations.map((station) => ({
                                            label: station?.name,
                                            value: station?.id,
                                          })),
                                        ]
                                  }
                                  placeholder="Select Station"
                                  isMulti
                                  value={selectedStationForBookingCount}
                                  onChange={(val) => {
                                    if (val === null) {
                                      setSessionCountFilterState({
                                        ...sessionCountFilterState,
                                        stations: [],
                                      });
                                    } else if (val.includes('All')) {
                                      setSessionCountFilterState({
                                        ...sessionCountFilterState,
                                        stations: stations.map((station) => station?.id),
                                      });
                                    } else {
                                      setSessionCountFilterState({
                                        ...sessionCountFilterState,
                                        stations: val,
                                      });
                                    }
                                    setSelectedStationForBookingCount(val);
                                  }}
                                  className="select-container"
                                  styles={{
                                    ...styles(),
                                    menu: (provided) => ({
                                      ...provided,
                                      width: '350px',
                                    }),
                                  }}
                                />
                              ) : null}
                              <Select
                                options={_.map(years, (year) => {
                                  return { label: year, value: year };
                                })}
                                placeholder="Select Year"
                                value={selectedYearForBookingCount}
                                onChange={(e) => {
                                  setSessionCountFilterState({
                                    ...sessionCountFilterState,
                                    schedule: 'Yearly',
                                    from: moment().year(Number(e)).tz('Asia/Dubai').startOf('year'),
                                    to: moment().year(Number(e)).tz('Asia/Dubai').endOf('year'),
                                  });
                                  setSelectedYearForBookingCount(Number(e));
                                  setSelectedMonthForBookingCount(null);
                                }}
                                className="select-container"
                              />
                              <Select
                                options={[
                                  { value: 'All', label: 'All' },
                                  ..._.map(monthsForBookingCount, (month) => {
                                    return {
                                      label: moment()
                                        .month(month - 1)
                                        .format('MMMM'),
                                      value: month,
                                    };
                                  }),
                                ]}
                                value={selectedMonthForBookingCount ?? 'All'}
                                onChange={(e) => {
                                  if (e === null || e === 'All') {
                                    setSessionCountFilterState({
                                      ...sessionCountFilterState,
                                      schedule: 'Yearly',
                                      from: moment(sessionCountFilterState?.from).tz('Asia/Dubai').startOf('year'),
                                      to: moment(sessionCountFilterState?.to).tz('Asia/Dubai').endOf('year'),
                                    });
                                  } else {
                                    setSessionCountFilterState({
                                      ...sessionCountFilterState,
                                      schedule: 'Monthly',
                                      from: moment(sessionCountFilterState?.from)
                                        .set({ month: Number(e) - 1 })
                                        .tz('Asia/Dubai')
                                        .startOf('month'),
                                      to: moment(sessionCountFilterState?.to)
                                        .set({ month: Number(e) - 1 })
                                        .tz('Asia/Dubai')
                                        .endOf('month'),
                                    });
                                  }
                                  setSelectedMonthForBookingCount(e);
                                  setSelectedWeekForBookingCount(null);
                                }}
                                isClearable
                                className="select-container"
                              />
                              {selectedMonthForBookingCount ? (
                                selectedMonthForBookingCount !== 'All' ? (
                                  <Select
                                    options={[
                                      { value: 'All', label: 'All' },
                                      ...weeksForBookingCount.map((w) => ({
                                        value: w.value,
                                        label: w.label,
                                      })),
                                    ]}
                                    placeholder="Select Week"
                                    value={selectedWeekForBookingCount ?? 'All'}
                                    onChange={(e) => {
                                      if (e === null || e === 'All') {
                                        setSessionCountFilterState({
                                          ...sessionCountFilterState,
                                          schedule: 'Monthly',
                                          from: moment(sessionCountFilterState?.from)
                                            .year(selectedYearForBookingCount)
                                            .set({ month: selectedMonthForBookingCount - 1 })
                                            .tz('Asia/Dubai')
                                            .startOf('month'),
                                          to: moment(sessionCountFilterState?.to)
                                            .year(selectedYearForBookingCount)
                                            .set({ month: selectedMonthForBookingCount - 1 })
                                            .tz('Asia/Dubai')
                                            .endOf('month'),
                                        });
                                      } else {
                                        setSessionCountFilterState({
                                          ...sessionCountFilterState,
                                          schedule: 'Weekly',
                                          from: moment(weeksForBookingCount.find((w) => w.value === parseInt(e, 10))?.startDate).tz(
                                            'Asia/Dubai',
                                            true
                                          ),
                                          to: moment(weeksForBookingCount.find((w) => w.value === parseInt(e, 10))?.endDate).tz('Asia/Dubai', true),
                                        });
                                      }
                                      setSelectedWeekForBookingCount(e);
                                    }}
                                    className="select-weekly-container"
                                    isClearable
                                  />
                                ) : null
                              ) : null}
                            </div>
                          </div>
                          <div className="dashboard-bookingCount_chart-img">
                            {isLoader ? (
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                              </div>
                            ) : (
                              <div
                                className="bookingCount_chart-img"
                                style={{
                                  flexGrow: '1',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  textAlign: 'center',
                                  height: isFullScreen ? 'calc(90vh - 100px)' : 'calc(70vh - 200px)',
                                  // minHeight: '400px',
                                  margin: isFullScreen ? '0 1vw' : '0',
                                }}
                              >
                                <Bar
                                  ref={barChartRef}
                                  data={chargerBookingCount}
                                  options={{
                                    scales: {
                                      y: {
                                        beginAtZero: true,
                                        stacked: true,
                                      },
                                      x: {
                                        stacked: true,
                                      },
                                    },
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                      legend: {
                                        position: 'bottom',
                                        labels: {
                                          color: '#ffffff',
                                        },
                                      },
                                    },
                                  }}
                                  height="100%"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </FullScreenWrapper>
                    </Card>
                  </Col>
                  <Col xl={6} lg={12} md={12}>
                    <AmountCollectedGraph fullScreen={isFullScreen} setFullScreen={setIsFullScreen} allstations={stations} zones={allZones} />
                  </Col>
                  <Col xl={6} lg={12} md={12}>
                    <ChargerAvailability fullScreen={isFullScreen} setFullScreen={setIsFullScreen} />
                  </Col>
                  <Col xl={6} lg={12} md={12}>
                    <Card>
                      <div className="dashboard-chart_main">
                        <div className="dashboard-chart_inner">
                          <div className="dashboard-chart_name">ChargePoint Status</div>
                          <div className="dashboard-chart-img_block">
                            {isLoad ? (
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                              </div>
                            ) : (
                              <div className="dashboard-chart_img">
                                <Doughnut data={chargePointStatus} options={options} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </LayoutContainer>
    </React.Fragment>
  );
};

Dashboard.propTypes = {};
export default Dashboard;

//card Component
export const DashboardCard = (props) => {
  const { icon, title, subTitle, metaIcon } = props;

  return (
    <div className="dashboard-card">
      <div className="dashboard-detail-box">
        <div className="dashboard-icon">
          {icon && <div className="session-icon">{icon}</div>}
          {metaIcon && <div className="session-meta-icon">{metaIcon}</div>}
        </div>
        <div className="dashboard__info">
          <div className="title-session">{title}</div>
          <div className="info-text">{subTitle}</div>
        </div>
      </div>
    </div>
  );
};

DashboardCard.propTypes = {
  /** card icon **/
  icon: PropTypes.node,
  metaIcon: PropTypes.node,
  /** Dashboard title **/
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  /** Dashboard sub-title **/
  subTitle: PropTypes.string,
};

//realTime Event table component
export const TableItem = (props) => {
  let { icon, text, date } = props;

  return (
    <div className="realTime-event-item__main">
      <div className="realTime-event-item--inner">
        <div className="realTime-event-icon--block">
          <div className="realTime-event_icon__inner">
            <div className="realTime-event---icon">{icon}</div>
          </div>
        </div>
        <div className="realTime-event-content__main-block">
          <div className="realTime-event-content--inner">
            <div className="realTime-event-details--content-block">
              <span className="realTime-event-timing">{date}</span>
              <div className="realTime-event-status--icon">{icon}</div>
              <div className="realTime-event-text">{text}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TableItem.propTypes = {
  /** tableList dot icon */
  icon: PropTypes.object,
  /** tableList date */
  date: PropTypes.string,
  /** tableList text */
  text: PropTypes.node,
};

//saas client component
export const SaasClient = (props) => {
  let { icon, text, number } = props;
  return (
    <div className="saasClient--item__main">
      <div className="saasClient--item__inner">
        <Avatar className="saasClient_img" />
        <div className="saasClient-text">{text}</div>
      </div>
      <div className="saasClient-content__main-block">
        <div className="saasClient---number">{number}</div>
        <div className="saasClient-details-icon">{icon}</div>
      </div>
    </div>
  );
};
SaasClient.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.node,
  number: PropTypes.number,
};

//recentTable component
const RecentTable = (props) => {
  let { icon, text, date, subText, time } = props;
  return (
    <div className="recentTable--item__main">
      <div className="recentTable--item__inner">
        <div className="recentTable--item__inner_block">
          <div className="recentTable--item__img">
            <Avatar className="recentTable_img" />
          </div>
          <div className="recentTable--item_content">
            <div className="recentTable-text">{text}</div>
            <div className="recentTable--item__inner_content">
              <div className="recentTable-details-icon">{icon}</div>
              <span>{subText}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="recentTable-content__block">
        <div className="recentTable---date">{date}</div>
        <div className="recentTable---time">{time}</div>
      </div>
    </div>
  );
};
RecentTable.propTypes = {
  icon: PropTypes.object,
  text: PropTypes.node,
  subText: PropTypes.node,
  date: PropTypes.string,
  time: PropTypes.string,
};
