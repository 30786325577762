import { DOWNLOAD_CHARGER_LOG_DATA, DOWNLOAD_CHARGER_LOG_DATA_SUCCESS, DOWNLOAD_CHARGER_LOG_DATA_FAIL } from '../actions/activityLog';
import {
  DOWNLOAD_ACCESS_REPORT,
  DOWNLOAD_ACCESS_REPORT_SUCCESS,
  DOWNLOAD_ACCESS_REPORT_FAIL,
  DOWNLOAD_CHARGE_TRANSACTION_REPORT,
  DOWNLOAD_CHARGE_TRANSACTION_REPORT_SUCCESS,
  DOWNLOAD_CHARGE_TRANSACTION_REPORT_FAIL,
  DOWNLOAD_FAILED_TRANSACTION_REPORT,
  DOWNLOAD_FAILED_TRANSACTION_REPORT_SUCCESS,
  DOWNLOAD_FAILED_TRANSACTION_REPORT_FAIL,
  DOWNLOAD_STATION_CHARGING_SUMMARY,
  DOWNLOAD_STATION_CHARGING_SUMMARY_SUCCESS,
  DOWNLOAD_STATION_CHARGING_SUMMARY_FAIL,
  DOWNLOAD_STATION_UNIT_CONSUMPTION,
  DOWNLOAD_STATION_UNIT_CONSUMPTION_SUCCESS,
  DOWNLOAD_STATION_UNIT_CONSUMPTION_FAIL,
  DOWNLOAD_STATION_DOWNTIME_REPORT,
  DOWNLOAD_STATION_DOWNTIME_REPORT_SUCCESS,
  DOWNLOAD_STATION_DOWNTIME_REPORT_FAIL,
  DOWNLOAD_PDF_STATION_DOWNTIME_REPORT,
  DOWNLOAD_PDF_STATION_DOWNTIME_REPORT_SUCCESS,
  DOWNLOAD_PDF_STATION_DOWNTIME_REPORT_FAIL,
} from '../actions/dataReport';
import { CHARGER_STATUS_DOWNLOAD, CHARGER_STATUS_DOWNLOAD_SUCCESS, CHARGER_STATUS_DOWNLOAD_FAIL } from 'actions/chargerStatus';
import { DOWNLOAD_EXCEL_VEHICLE, DOWNLOAD_EXCEL_VEHICLE_SUCCESS, DOWNLOAD_EXCEL_VEHICLE_FAIL } from 'actions/vehicle';
import { DOWNLOAD_UPDATED_TARIFF_LOG, DOWNLOAD_UPDATED_TARIFF_LOG_FAIL, DOWNLOAD_UPDATED_TARIFF_LOG_SUCCESS } from 'actions/tariff';

const initialState = {
  isLoading: false,
};

const downloadingLoaderReducer = (state = initialState, { type }) => {
  switch (type) {
    case DOWNLOAD_CHARGER_LOG_DATA:
    case DOWNLOAD_STATION_DOWNTIME_REPORT:
    case DOWNLOAD_STATION_UNIT_CONSUMPTION:
    case DOWNLOAD_STATION_CHARGING_SUMMARY:
    case CHARGER_STATUS_DOWNLOAD:
    case DOWNLOAD_EXCEL_VEHICLE:
    case DOWNLOAD_ACCESS_REPORT:
    case DOWNLOAD_CHARGE_TRANSACTION_REPORT:
    case DOWNLOAD_FAILED_TRANSACTION_REPORT:
    case DOWNLOAD_PDF_STATION_DOWNTIME_REPORT:
    case DOWNLOAD_UPDATED_TARIFF_LOG: {
      return { ...state, isLoading: true };
    }

    case DOWNLOAD_CHARGER_LOG_DATA_SUCCESS:
    case DOWNLOAD_STATION_UNIT_CONSUMPTION_SUCCESS:
    case DOWNLOAD_STATION_DOWNTIME_REPORT_SUCCESS:
    case DOWNLOAD_STATION_CHARGING_SUMMARY_SUCCESS:
    case CHARGER_STATUS_DOWNLOAD_SUCCESS:
    case DOWNLOAD_EXCEL_VEHICLE_SUCCESS:
    case DOWNLOAD_ACCESS_REPORT_SUCCESS:
    case DOWNLOAD_CHARGE_TRANSACTION_REPORT_SUCCESS:
    case DOWNLOAD_FAILED_TRANSACTION_REPORT_SUCCESS:
    case DOWNLOAD_PDF_STATION_DOWNTIME_REPORT_SUCCESS:
    case DOWNLOAD_UPDATED_TARIFF_LOG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case DOWNLOAD_CHARGER_LOG_DATA_FAIL:
    case DOWNLOAD_STATION_UNIT_CONSUMPTION_FAIL:
    case DOWNLOAD_STATION_DOWNTIME_REPORT_FAIL:
    case DOWNLOAD_STATION_CHARGING_SUMMARY_FAIL:
    case CHARGER_STATUS_DOWNLOAD_FAIL:
    case DOWNLOAD_EXCEL_VEHICLE_FAIL:
    case DOWNLOAD_ACCESS_REPORT_FAIL:
    case DOWNLOAD_CHARGE_TRANSACTION_REPORT_FAIL:
    case DOWNLOAD_FAILED_TRANSACTION_REPORT_FAIL:
    case DOWNLOAD_PDF_STATION_DOWNTIME_REPORT_FAIL:
    case DOWNLOAD_UPDATED_TARIFF_LOG_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default downloadingLoaderReducer;
