import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_TARIFF,
  FETCH_TARIFF_SUCCESS,
  FETCH_TARIFF_FAIL,
  DELETE_TARIFF,
  DELETE_TARIFF_SUCCESS,
  DELETE_TARIFF_FAIL,
  UPDATE_TARIFF,
  UPDATE_TARIFF_SUCCESS,
  UPDATE_TARIFF_FAIL,
  CREATE_TARIFF,
  CREATE_TARIFF_SUCCESS,
  CREATE_TARIFF_FAIL,
  GET_TARIFF,
  GET_TARIFF_SUCCESS,
  GET_TARIFF_FAIL,
  GET_CHARGER_TARIFF,
  GET_CHARGER_TARIFF_SUCCESS,
  GET_CHARGER_TARIFF_FAIL,
  BULK_UPLOAD_CHARGER_TARIFF,
  BULK_UPLOAD_CHARGER_TARIFF_SUCCESS,
  BULK_UPLOAD_CHARGER_TARIFF_FAIL,
  DOWNLOAD_CHARGER_TARIFF,
  DOWNLOAD_CHARGER_TARIFF_SUCCESS,
  DOWNLOAD_CHARGER_TARIFF_FAIL,
  UPDATED_TARIFF_LOG_LIST,
  UPDATED_TARIFF_LOG_LIST_SUCCESS,
  UPDATED_TARIFF_LOG_LIST_FAIL,
  DOWNLOAD_UPDATED_TARIFF_LOG,
  DOWNLOAD_UPDATED_TARIFF_LOG_SUCCESS,
  DOWNLOAD_UPDATED_TARIFF_LOG_FAIL,
  FETCH_TARIFF_V2,
  FETCH_TARIFF_V2_SUCCESS,
  FETCH_TARIFF_V2_FAIL,
  FETCH_TARIFF_V3,
  FETCH_TARIFF_V3_SUCCESS,
  FETCH_TARIFF_V3_FAIL,
} from 'actions/tariff';

function* fetchTariff(action) {
  try {
    const response = yield call(API.getTariff, action.payload);
    yield put({ type: FETCH_TARIFF_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_TARIFF_FAIL, payload: { error: e } });
  }
}

function* getTariff(action) {
  try {
    const response = yield call(API.getTariffById, action.payload);
    yield put({ type: GET_TARIFF_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_TARIFF_FAIL, payload: { error: e } });
  }
}

function* createTariff(action) {
  try {
    const response = yield call(API.addTariff, action.payload);
    yield put({ type: CREATE_TARIFF_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_TARIFF_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* updateTariff(action) {
  try {
    const response = yield call(API.updateTariff, action.payload);
    yield put({ type: UPDATE_TARIFF_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_TARIFF_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* deleteTariff(action) {
  try {
    const response = yield call(API.deleteTariff, action.payload);
    yield put({ type: DELETE_TARIFF_SUCCESS, payload: action.payload });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DELETE_TARIFF_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* getChargerTariff(action) {
  try {
    const response = yield call(API.getChargerTariff, action.payload);
    yield put({ type: GET_CHARGER_TARIFF_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: GET_CHARGER_TARIFF_FAIL, payload: { error: e } });
  }
}

function* downloadChargerTariff(action) {
  try {
    const response = yield call(API.downloadChargerTariff, action.payload);
    yield put({ type: DOWNLOAD_CHARGER_TARIFF_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CHARGER_TARIFF_FAIL, payload: { error: e } });
  }
}

function* getUpdatedTarifflogs(action) {
  try {
    const response = yield call(API.getUpdatedTarifflogs, action.payload);
    yield put({ type: UPDATED_TARIFF_LOG_LIST_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATED_TARIFF_LOG_LIST_FAIL, payload: { error: e } });
  }
}

function* downloadUpdatedTarifflogs(action) {
  try {
    const response = yield call(API.downloadUpdatedTarifflogs, action.payload);
    yield put({ type: DOWNLOAD_UPDATED_TARIFF_LOG_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_UPDATED_TARIFF_LOG_FAIL, payload: { error: e } });
  }
}

function* bulkUploadChargerTariff(action) {
  try {
    const response = yield call(API.bulkUploadChargerTariff, action.payload);
    yield put({ type: BULK_UPLOAD_CHARGER_TARIFF_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: BULK_UPLOAD_CHARGER_TARIFF_FAIL, payload: { error: e } });
  }
}

function* fetchTariffV2(action) {
  try {
    const response = yield call(API.getTariffV2, action.payload);
    yield put({ type: FETCH_TARIFF_V2_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_TARIFF_V2_FAIL, payload: { error: e } });
  }
}

function* fetchTariffV3(action) {
  try {
    const response = yield call(API.getTariffV3, action.payload);
    yield put({ type: FETCH_TARIFF_V3_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_TARIFF_V3_FAIL, payload: { error: e } });
  }
}

function* tariff() {
  yield takeLatest(FETCH_TARIFF, fetchTariff);
  yield takeLatest(GET_TARIFF, getTariff);
  yield takeLatest(CREATE_TARIFF, createTariff);
  yield takeLatest(UPDATE_TARIFF, updateTariff);
  yield takeLatest(DELETE_TARIFF, deleteTariff);
  yield takeLatest(GET_CHARGER_TARIFF, getChargerTariff);
  yield takeLatest(DOWNLOAD_CHARGER_TARIFF, downloadChargerTariff);
  yield takeLatest(UPDATED_TARIFF_LOG_LIST, getUpdatedTarifflogs);
  yield takeLatest(DOWNLOAD_UPDATED_TARIFF_LOG, downloadUpdatedTarifflogs);
  yield takeLatest(BULK_UPLOAD_CHARGER_TARIFF, bulkUploadChargerTariff);
  yield takeLatest(FETCH_TARIFF_V2, fetchTariffV2);
  yield takeLatest(FETCH_TARIFF_V3, fetchTariffV3);
}
export default tariff;
