import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { roundOfDigit } from 'components/common/utils';
import SearchBox from 'components/general/SearchBox';
import { DOWNLOAD_STATION_CHARGING_SUMMARY, STATION_CHARGING_SUMMARY, STATION_CHARGING_SUMMARY_CHART } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import { IoSearchOutline } from 'react-icons/io5';
// import { FETCH_COUNTRY, FETCH_STATE, GET_CITY_BY_STATE } from 'actions/address';
import { MdFileDownload, MdRefresh } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { FETCH_ZONES } from 'actions/zoneManagement';
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import { ImEnlarge } from 'react-icons/im';
import { MdClose } from 'react-icons/md';
import PropTypes from 'prop-types';

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend);
const FullScreenWrapper = ({ children, isFullScreen, setIsFullScreen, downloadCharts = _.noop() }) => {
  const containerRef = useRef(null);
  // const [isFullScreen, setIsFullScreen] = useState(false);

  const enterFullScreen = () => {
    if (containerRef.current.requestFullscreen) {
      containerRef.current.requestFullscreen();
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Escape') {
      exitFullScreen();
    }
  };

  const updateFullScreenState = () => {
    const isFull = !!document.fullscreenElement;
    setIsFullScreen(isFull);
  };

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    document.addEventListener('fullscreenchange', updateFullScreenState);

    updateFullScreenState();

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('fullscreenchange', updateFullScreenState);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: '100%',
        height: isFullScreen ? '100vh' : 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#1b2a38',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 1,
          right: 10,
          display: 'flex',
          gap: '15px',
          alignItems: 'center',
        }}
      >
        <MdFileDownload
          onClick={downloadCharts}
          style={{
            cursor: 'pointer',
          }}
          color={'#3c7cdd'}
          size={25}
        />
        {!isFullScreen && (
          <ImEnlarge
            onClick={enterFullScreen}
            style={{
              cursor: 'pointer',
            }}
          />
        )}
        {isFullScreen && (
          <MdClose
            onClick={exitFullScreen}
            style={{
              cursor: 'pointer',
            }}
            size={20}
          />
        )}
      </div>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </div>
    </div>
  );
};
FullScreenWrapper.propTypes = {
  children: PropTypes.node,
  isFullScreen: PropTypes.bool,
  setIsFullScreen: PropTypes.func,
  // title: PropTypes.string,
  downloadCharts: PropTypes.func,
};

const StationChargingSummary = () => {
  const dispatch = useDispatch();
  const lineChartRef = useRef(null);
  const barChartRef = useRef(null);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  // const [cityLimit, setCityLimit] = useState(false);
  // const [allStationChargingSummaryData, setAllStationChargingSummaryData] = useState([]);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [startDate, setStartDate] = useState(moment().tz('Asia/Dubai').startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz('Asia/Dubai').endOf('day'));
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  // const [changeState, setChangeState] = useState(false);
  // const [changeCity, setChangeCity] = useState(false);
  // const [country, setCountry] = useState('');
  // const [state, setState] = useState('');
  // const [changeStationType, setChangeStationType] = useState(false);
  const [selectedOption, setSelectedOption] = useState('excel');
  const reportFormats = [
    { label: 'PDF Format', value: 'pdf' },
    { label: 'Excel Sheet', value: 'excel' },
  ];
  const [unitChartData, setUnitChartData] = useState({ labels: [], datasets: [] });
  const [SessionChartData, setSessionChartData] = useState({ labels: [], datasets: [] });
  const [isFullScreen, setIsFullScreen] = useState(false);

  const stationChargingSummaryList = useSelector((state) => state.stationChargingSummary.stationChargingSummaries.results);
  const stationChargingSummaryData = useSelector((state) => state.stationChargingSummary.stationChargingSummaryChart);
  const isLoader = useSelector((state) => state.stationChargingSummary.isLoading);
  const page = useSelector((state) => state.stationChargingSummary.stationChargingSummaries.page);
  const totalData = useSelector((state) => state.stationChargingSummary.stationChargingSummaries.totalResults);
  const limit = useSelector((state) => state.stationChargingSummary.stationChargingSummaries.limit);
  const totalPages = useSelector((state) => state.stationChargingSummary.stationChargingSummaries.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const hasData = stationChargingSummaryList && stationChargingSummaryList.length > 0;

  const stationChargingSummary = useCallback(
    (value) => {
      if (searchText) {
        const stationChargingSummaryData = {
          ...value,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          search: searchText,
        };
        dispatch({
          type: STATION_CHARGING_SUMMARY,
          payload: stationChargingSummaryData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: STATION_CHARGING_SUMMARY,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText]
  );

  const stationChargingSummaryChart = useCallback(
    (value) => {
      if (searchText) {
        const stationChargingSummaryData = {
          ...value,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          search: searchText,
        };
        dispatch({
          type: STATION_CHARGING_SUMMARY_CHART,
          payload: stationChargingSummaryData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: STATION_CHARGING_SUMMARY_CHART,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (advanceFilterData) {
      if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '', access_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
        };
        stationChargingSummary(data);
        stationChargingSummaryChart(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, state: '', city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
        };
        stationChargingSummary(data);
        stationChargingSummaryChart(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, state: '', access_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
        };
        stationChargingSummary(data);
        stationChargingSummaryChart(data);
      } else if (advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
        };
        stationChargingSummary(data);
        stationChargingSummaryChart(data);
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
        };
        stationChargingSummary(data);
        stationChargingSummaryChart(data);
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
        };
        stationChargingSummary(data);
        stationChargingSummaryChart(data);
      } else if (advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
        };
        stationChargingSummary(data);
        stationChargingSummaryChart(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
        };
        stationChargingSummary(data);
        stationChargingSummaryChart(data);
      }
    } else {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
      };
      stationChargingSummary(filterDateData);
      stationChargingSummaryChart(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData, searchText]);

  // Download Excel Functionality

  const downloadStationChargingSummary = useCallback(() => {
    if (advanceFilterData) {
      if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '', access_type: '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Station Charging Summary.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, state: '', city: '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Station Charging Summary.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, state: '', access_type: '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Station Charging Summary.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', city: '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Station Charging Summary.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Station Charging Summary.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Station Charging Summary.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Station Charging Summary.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        dispatch({
          type: DOWNLOAD_STATION_CHARGING_SUMMARY,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Station Charging Summary.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      }
    } else if (searchText) {
      const data = {
        // excel: true,
        reportFormat: selectedOption,
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        search: searchText,
      };
      dispatch({
        type: DOWNLOAD_STATION_CHARGING_SUMMARY,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `Station Charging Summary.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
            }
          }
        },
      });
    } else {
      const data = {
        // excel: true,
        reportFormat: selectedOption,
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      dispatch({
        type: DOWNLOAD_STATION_CHARGING_SUMMARY,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `Station Charging Summary.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData, selectedOption, searchText]);

  const downloadFile = () => {
    downloadStationChargingSummary();
  };

  useEffect(() => {
    stationChargingSummary();
    stationChargingSummaryChart();
  }, []);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        endDate && stationChargingSummary(data) && stationChargingSummaryChart(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
        };
        stationChargingSummary(data);
        stationChargingSummaryChart(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        endDate && stationChargingSummary(data) && stationChargingSummaryChart(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        };
        stationChargingSummary(data);
        stationChargingSummaryChart(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (advanceFilterData) {
        if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, city: '', state: '', access_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
          };
          stationChargingSummary(data);
          stationChargingSummaryChart(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, state: '', city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
          };
          stationChargingSummary(data);
          stationChargingSummaryChart(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, state: '', access_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
          };
          stationChargingSummary(data);
          stationChargingSummaryChart(data);
        } else if (advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, access_type: '', city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
          };
          stationChargingSummary(data);
          stationChargingSummaryChart(data);
        } else if (advanceFilterData.state === 'All') {
          const filterData = { ...advanceFilterData, state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
          };
          stationChargingSummary(data);
          stationChargingSummaryChart(data);
        } else if (advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
          };
          stationChargingSummary(data);
          stationChargingSummaryChart(data);
        } else if (advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, access_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
          };
          stationChargingSummary(data);
          stationChargingSummaryChart(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
          };
          stationChargingSummary(data);
          stationChargingSummaryChart(data);
        }
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          search: searchText,
          page: page.selected + 1,
        };
        stationChargingSummary(data);
        stationChargingSummaryChart(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          page: page.selected + 1,
        };
        stationChargingSummary(data);
        stationChargingSummaryChart(data);
        stationChargingSummaryChart(data);
      } else {
        const data = {
          page: page.selected + 1,
        };
        stationChargingSummary(data);
        stationChargingSummaryChart(data);
      }
    },
    [sortByItem, startDate, endDate, advanceFilterData, searchText]
  );

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const search = <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />;
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  // const allCountries = useSelector((state) => state.country.countries);
  // const allState = useSelector((state) => state.state.states);
  // const totalStateData = useSelector((state) => state.state.total);
  // const allCityByState = useSelector((state) => state.cityByState.cityByStates);
  // const totalCityData = useSelector((state) => state.cityByState.total);
  // const countryPage = useSelector((state) => state.country.page);
  // const statePage = useSelector((state) => state.state.page);
  // const cityByStatePage = useSelector((state) => state.cityByState.page);
  // const countryTotalPage = useSelector((state) => state.country.totalPages);
  // const stateTotalPage = useSelector((state) => state.state.totalPages);
  // const cityByStateTotalPage = useSelector((state) => state.cityByState.totalPages);
  const allZones = useSelector((state) => state?.zoneManagement?.zones);
  const zonePage = useSelector((state) => state?.zoneManagement?.page);
  const zoneTotalPage = useSelector((state) => state?.zoneManagement?.totalPages);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);

  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { country: '', state: '', city: '', access_type: '', zones: '' };

  // const getAllCountry = useCallback((data = {}) => {
  //   dispatch({ type: FETCH_COUNTRY, payload: data });
  // }, []);

  const getAllZones = useCallback((data = {}) => {
    dispatch({ type: FETCH_ZONES, payload: data });
  }, []);

  // const getStateByCountry = useCallback((data = {}) => {
  //   dispatch({ type: FETCH_STATE, payload: data });
  // }, []);

  // const getCityByStateName = useCallback((data = {}) => {
  //   dispatch({ type: GET_CITY_BY_STATE, payload: data });
  // }, []);

  // useEffect(() => {
  //   if (showAdvanceFilterPopup) {
  //     getStateByCountry({ country_name: country, limit: totalStateData });
  //   }
  //   if (cityLimit) {
  //     getCityByStateName({ state_names: state, limit: totalCityData });
  //   }
  // }, [country, state, totalStateData, totalCityData, cityLimit]);

  useEffect(() => {
    // getAllCountry();
    getAllZones({ limit: 999 });
  }, []);

  // const allStationType = [
  //   { label: 'Public', value: 'Public' },
  //   { label: 'Private', value: 'Private' },
  //   { label: 'Commercial', value: 'Commercial' },
  // ];

  const generateIntervals = (startDate, endDate, intervalType) => {
    const intervals = [];
    let current = moment(startDate);
    if (intervalType === 'minutes') {
      while (current.isBefore(endDate)) {
        intervals.push(current.format('HH:mm'));
        current.add(5, 'minutes');
      }
    } else if (intervalType === 'HH:mm') {
      while (current.isBefore(endDate)) {
        intervals.push(current.format('HH:mm'));
        current.add(30, 'minutes');
      }
    } else if (intervalType === 'YYYY-MM-DD') {
      while (current.isBefore(endDate)) {
        intervals.push(current.format('YYYY-MM-DD'));
        current.add(1, 'day');
      }
    } else if (intervalType === 'YYYY-MM') {
      while (current.isBefore(endDate)) {
        intervals.push(current.format('YYYY-MM'));
        current.add(1, 'month');
      }
    } else if (intervalType === 'YYYY') {
      while (current.isBefore(endDate)) {
        intervals.push(current.format('YYYY'));
        current.add(1, 'year');
      }
    }

    return intervals;
  };

  const aggregateUnitData = (data, startDate, endDate, intervalType) => {
    const durationInMinutes = endDate.diff(startDate, 'minutes');
    let selectedIntervalType = intervalType;

    if (durationInMinutes <= 1440) {
      selectedIntervalType = 'HH:mm';
    } else if (durationInMinutes <= 43200) {
      selectedIntervalType = 'YYYY-MM-DD';
    } else if (durationInMinutes <= 525600) {
      selectedIntervalType = 'YYYY-MM';
    } else {
      selectedIntervalType = 'YYYY';
    }

    const allIntervals = generateIntervals(startDate, endDate, selectedIntervalType);
    const aggregated = {};

    allIntervals.forEach((interval) => {
      aggregated[interval] = 0;
    });

    const exactSessions = new Set();

    data.forEach((entry) => {
      const bookingStart = moment(entry.booking_start).tz('Asia/Dubai');
      const bookingStop = moment(entry.booking_stop).tz('Asia/Dubai');
      const unitConsumed = (entry.meterstop - entry.meterstart) / 1000;

      if (bookingStart.isBetween(startDate, endDate, null, '[]') && bookingStop.isBetween(startDate, endDate, null, '[]')) {
        let key = bookingStop.format(selectedIntervalType);

        if (!aggregated[key]) {
          aggregated[key] = 0;
        }
        aggregated[key] += unitConsumed;

        if (selectedIntervalType === 'HH:mm') {
          exactSessions.add(bookingStop.format('HH:mm'));
        }
      }
    });

    const result = allIntervals.map((interval) => ({
      label: interval,
      value: aggregated[interval] || 0,
    }));

    [...exactSessions].forEach((exactSession) => {
      if (!result.some((item) => item.label === exactSession)) {
        result.push({
          label: exactSession,
          value: aggregated[exactSession] || 0,
        });
      }
    });

    result.sort((a, b) => (moment(a.label, selectedIntervalType).isBefore(moment(b.label, selectedIntervalType)) ? -1 : 1));

    return result;
  };

  const aggregateSessionsData = (data, startDate, endDate, intervalType) => {
    const durationInMinutes = endDate.diff(startDate, 'minutes');
    let selectedIntervalType = intervalType;

    if (durationInMinutes <= 1440) {
      selectedIntervalType = 'HH:mm';
    } else if (durationInMinutes <= 43200) {
      selectedIntervalType = 'YYYY-MM-DD';
    } else if (durationInMinutes <= 525600) {
      selectedIntervalType = 'YYYY-MM';
    } else {
      selectedIntervalType = 'YYYY';
    }

    const allIntervals = generateIntervals(startDate, endDate, selectedIntervalType);
    const aggregated = {};

    allIntervals.forEach((interval) => {
      aggregated[interval] = 0;
    });

    const exactSessions = new Set();

    data.forEach((entry) => {
      const bookingStart = moment(entry.booking_start).tz('Asia/Dubai');
      const bookingStop = moment(entry.booking_stop).tz('Asia/Dubai');
      const sessionCount = entry.no_of_session;

      if (bookingStart.isBetween(startDate, endDate, null, '[]') && bookingStop.isBetween(startDate, endDate, null, '[]')) {
        let key = bookingStop.format(selectedIntervalType);

        if (!aggregated[key]) {
          aggregated[key] = 0;
        }
        aggregated[key] += sessionCount;

        if (selectedIntervalType === 'HH:mm') {
          exactSessions.add(bookingStop.format('HH:mm'));
        }
      }
    });

    const result = allIntervals.map((interval) => ({
      label: interval,
      value: aggregated[interval] || 0,
    }));

    [...exactSessions].forEach((exactSession) => {
      if (!result.some((item) => item.label === exactSession)) {
        result.push({
          label: exactSession,
          value: aggregated[exactSession] || 0,
        });
      }
    });

    result.sort((a, b) => (moment(a.label, selectedIntervalType).isBefore(moment(b.label, selectedIntervalType)) ? -1 : 1));

    return result;
  };

  useEffect(() => {
    if (startDate && endDate) {
      const durationInMinutes = moment(endDate).diff(moment(startDate), 'minutes');

      const customIntervalType = durationInMinutes <= 360 ? 'minutes' : null;

      const aggregated = aggregateUnitData(stationChargingSummaryData, moment(startDate), moment(endDate), customIntervalType);

      const labels = aggregated.map((item) => item.label);
      const values = aggregated.map((item) => item.value);

      setUnitChartData({
        labels,
        datasets: [
          {
            label: 'Energy Sold',
            data: values,
            borderColor: 'rgba(75,192,192,1)',
            backgroundColor: 'rgba(75,192,192,0.2)',
            tension: 0.4,
          },
        ],
      });
    }
  }, [stationChargingSummaryData]);

  useEffect(() => {
    if (startDate && endDate) {
      const durationInMinutes = moment(endDate).diff(moment(startDate), 'minutes');

      const customIntervalType = durationInMinutes <= 360 ? 'minutes' : null;

      const aggregated = aggregateSessionsData(
        stationChargingSummaryData, // Your data
        moment(startDate),
        moment(endDate),
        customIntervalType
      );

      const labels = aggregated.map((item) => item.label);
      const values = aggregated.map((item) => item.value);

      if (labels.length > 0 && values.length > 0) {
        setSessionChartData({
          labels: labels,
          datasets: [
            {
              label: 'Session Count',
              data: values,
              backgroundColor: '#8548B4',
              barThickness: 7,
              maxBarThickness: 8,
            },
          ],
        });
      }
    }
  }, [stationChargingSummaryData]);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 10,
          color: '#ffffff',
        },
      },
    },
  };

  const downloadLineGraph = () => {
    if (lineChartRef.current) {
      const chartInstance = lineChartRef.current;
      const imageURL = chartInstance.toBase64Image();
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = 'Line_Chart.png';
      link.click();
    }
  };

  const downloadBarGraph = () => {
    if (barChartRef.current) {
      const chartInstance = barChartRef.current;
      const imageURL = chartInstance.toBase64Image();
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = 'Bar_Chart.png';
      link.click();
    }
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Station Charging Summary" />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <div className="data-report--block">
              <div className="data-report__inner">
                <Card>
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment().tz('Asia/Dubai').startOf('day')}
                            initialValueOfEndDate={moment(new Date()).tz('Asia/Dubai').endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">Search</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">Advance Filter</Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={2} md={'auto'}>
                        <Select
                          options={reportFormats}
                          value={selectedOption}
                          onChange={(value) => setSelectedOption(value)}
                          placeholder="Select Report Format"
                        />
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={hasData ? downloadFile : null}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className={`report-excel-icon  ${!hasData ? 'disabled' : ''}`}>
                            <BsDownload title={!hasData ? '' : 'Download'} size={28} color={!hasData ? '#d3d3d3' : '#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Card>
                <Row>
                  <Col lg={6} md={6}>
                    <Card>
                      <FullScreenWrapper isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} downloadCharts={downloadLineGraph}>
                        <div>
                          <h3>Energy Sold (kWh)</h3>

                          {isLoader ? (
                            <div className="loader--block">
                              <Spinner as="span" animation="border" size="sm" role="status" />
                            </div>
                          ) : (
                            <div style={{ height: isFullScreen ? 'calc(100vh - 100px)' : 'calc(70vh - 200px)' }}>
                              <Line ref={lineChartRef} data={unitChartData} options={options} height="100%" />
                            </div>
                          )}
                        </div>
                      </FullScreenWrapper>
                    </Card>
                  </Col>
                  <Col lg={6} md={6}>
                    <Card>
                      <FullScreenWrapper isFullScreen={isFullScreen} setIsFullScreen={setIsFullScreen} downloadCharts={downloadBarGraph}>
                        <div>
                          <h3>Session Count</h3>

                          {isLoader ? (
                            <div className="loader--block">
                              <Spinner as="span" animation="border" size="sm" role="status" />
                            </div>
                          ) : (
                            <div style={{ height: isFullScreen ? 'calc(100vh - 100px)' : 'calc(70vh - 200px)' }}>
                              <Bar ref={barChartRef} data={SessionChartData} options={options} height="100%" />
                            </div>
                          )}
                        </div>
                      </FullScreenWrapper>
                    </Card>
                  </Col>
                </Row>
                <Card>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>Sr No.</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Station Name</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Station Code</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Charger ID</span>
                                <span className="ico" onClick={() => handleSorting('ocpp_id', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Unit Consumed (kWh)</span>
                                <span className="ico" onClick={() => handleSorting('energy_consumed', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Session Duration (HH:MM:SS)</span>
                                <span className="ico" onClick={() => handleSorting('time_taken', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>No of Sessions</span>
                                <span className="ico" onClick={() => handleSorting('number_of_sessions', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="loader--block">
                                  <Spinner as="span" animation="border" size="sm" role="status" />
                                  <span className="ps-2">Loading...</span>
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(stationChargingSummaryList) ? (
                            <tr>
                              <td colSpan={10} className="border-0">
                                <div className="empty-data-block">No Station Charging Summary Found</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(
                              _.filter(stationChargingSummaryList, (item) => item !== null),
                              (item, key) => {
                                const serial_num = limit * (page - 1) + key;
                                return (
                                  <>
                                    <tr key={`station-charging-summary-${serial_num}`}>
                                      <td>{serial_num + 1}</td>
                                      <td>{_.get(item, 'charging_station.name', '-')}</td>
                                      <td>{_.get(item, 'charging_station.code', '-')}</td>
                                      <td>{_.get(item, 'ocpp_id', '-')}</td>
                                      <td>{roundOfDigit(_.get(item, 'energy_consumed') / 1000, 3)}</td>
                                      <td>{_.get(item, 'duration', '-')}</td>
                                      <td>{_.get(item, 'number_of_sessions')}</td>
                                    </tr>
                                  </>
                                );
                              }
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(stationChargingSummaryList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </Card>
              </div>
            </div>
          </div>
        </div>
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.country && delete values['country'];
              !values.state && delete values['state'];
              !values.city && delete values['city'];
              !values.zones && delete values['zones'];
              // !values.access_type && delete values['access_type'];
              if (values.state === 'All' && values.city === 'All' && values.access_type === 'All') {
                const stationChargingSummaryData = { ...values, state: '', city: '', access_type: '' };
                stationChargingSummary(stationChargingSummaryData);
                stationChargingSummaryChart(stationChargingSummaryData);
              } else if (values.state === 'All' && values.city === 'All') {
                const stationChargingSummaryData = { ...values, state: '', city: '' };
                stationChargingSummary(stationChargingSummaryData);
                stationChargingSummaryChart(stationChargingSummaryData);
              } else if (values.state === 'All' && values.access_type === 'All') {
                const stationChargingSummaryData = { ...values, state: '', access_type: '' };
                stationChargingSummary(stationChargingSummaryData);
                stationChargingSummaryChart(stationChargingSummaryData);
              } else if (values.city === 'All' && values.access_type === 'All') {
                const stationChargingSummaryData = { ...values, access_type: '', city: '' };
                stationChargingSummary(stationChargingSummaryData);
                stationChargingSummaryChart(stationChargingSummaryData);
              } else if (values.city === 'All') {
                const stationChargingSummaryData = { ...values, city: '' };
                stationChargingSummary(stationChargingSummaryData);
                stationChargingSummaryChart(stationChargingSummaryData);
              } else if (values.state === 'All') {
                const stationChargingSummaryData = { ...values, state: '' };
                stationChargingSummary(stationChargingSummaryData);
                stationChargingSummaryChart(stationChargingSummaryData);
              } else if (values.access_type === 'All') {
                const stationChargingSummaryData = { ...values, access_type: '' };
                stationChargingSummary(stationChargingSummaryData);
                stationChargingSummaryChart(stationChargingSummaryData);
              } else {
                stationChargingSummary(values);
                stationChargingSummaryChart(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  {/* <Select
                    label="Country"
                    options={_.map(allCountries, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder="Select Country"
                    name="country"
                    value={values.country}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllCountry}
                    page={countryPage}
                    totalPage={countryTotalPage}
                    onChange={(val) => {
                      getStateByCountry({ country_name: val });
                      setCountry(val);
                      setFieldValue(`country`, val);
                      // setFieldValue(`state`, '');
                      setFieldValue(`city`, '');
                    }}
                  /> */}
                  {/* <Select
                    label="State"
                    options={
                      !changeState
                        ? values.country &&
                        !_.isEmpty(allState) && [
                          { label: 'All', value: 'All' },
                          ..._.map(allState, (state) => {
                            return { label: state.name, value: state.name };
                          }),
                        ]
                        : values.country && !_.isEmpty(allState) && [{ label: 'All', value: 'All' }]
                    }
                    className="filter-select-box"
                    placeholder="Select State"
                    isMulti
                    name="state"
                    value={values.state}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                    page={statePage}
                    totalPage={stateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getCityByStateName({ state_names: '' });
                        setChangeState(true);
                        setFieldValue(`state`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeState(false);
                        setChangeCity(false);
                        setFieldValue(`state`, '');
                      } else {
                        getCityByStateName({ state_names: val });
                        setState(val);
                        setCityLimit(true);
                        setChangeState(false);
                        setChangeCity(false);
                        setFieldValue(`state`, val);
                      }
                      setFieldValue(`city`, '');
                    }}
                  /> */}
                  {/* <Select
                    label="City"
                    options={
                      !changeCity
                        ? !_.isEmpty(values.state) &&
                        !_.isEmpty(allCityByState) && [
                          { label: 'All', value: 'All' },
                          ..._.map(allCityByState, (state) => {
                            return { label: state.name, value: state.name };
                          }),
                        ]
                        : !_.isEmpty(values.state) && values.country && !_.isEmpty(allCityByState) && [{ label: 'All', value: 'All' }]
                    }
                    placeholder="Select City"
                    name="city"
                    isMulti
                    value={values.city}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getCityByStateName({ ...data, state_names: values.state === 'All' ? '' : values.state })}
                    page={cityByStatePage}
                    totalPage={cityByStateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        setChangeCity(true);
                        setFieldValue(`city`, 'All');
                      } else if (_.isEmpty(val)) {
                        setFieldValue(`city`, '');
                        setChangeCity(false);
                      } else {
                        setChangeCity(false);
                        setFieldValue('city', val);
                      }
                    }}
                  /> */}
                  <Select
                    label="Zones"
                    isMulti
                    options={_.map(allZones, (item) => {
                      return { label: item.name, value: item.id };
                    })}
                    className="filter-select-box"
                    placeholder="Select Zones"
                    name="zones"
                    value={values.zones}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllZones}
                    page={zonePage}
                    totalPage={zoneTotalPage}
                    onChange={(val) => {
                      setFieldValue(`zones`, val);
                    }}
                  />
                  {/* <div className="canvas-selection__block">
                    <Select
                      label="Station Type"
                      options={
                        !changeStationType
                          ? !_.isEmpty(allStationType) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allStationType, (item) => {
                                return { label: item.label, value: item.value };
                              }),
                            ]
                          : !_.isEmpty(allStationType) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder="Select Station Type"
                      name="access_type"
                      isMulti
                      value={values.access_type}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStationType(true);
                          setFieldValue(`access_type`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`access_type`, '');
                          setChangeStationType(false);
                        } else {
                          setChangeStationType(false);
                          setFieldValue('access_type', val);
                        }
                      }}
                    />
                  </div> */}
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    Apply Filters
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      stationChargingSummary();
                      stationChargingSummaryChart();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdRefresh size={24} className="reset--icon" />
                    Reset
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default StationChargingSummary;
