import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAIL,
  GET_DASHBOARD_WIDGET_DATA,
  GET_DASHBOARD_WIDGET_DATA_SUCCESS,
  GET_DASHBOARD_WIDGET_DATA_FAIL,
  GET_DASHBOARD_CHART_DATA,
  GET_DASHBOARD_CHART_DATA_SUCCESS,
  GET_DASHBOARD_CHART_DATA_FAIL,
  GET_DASHBOARD_PIECHART_DATA,
  GET_DASHBOARD_PIECHART_DATA_SUCCESS,
  GET_DASHBOARD_PIECHART_DATA_FAIL,
  GET_DASHBOARD_ENERGY_SOLD,
  GET_DASHBOARD_ENERGY_SOLD_SUCCESS,
  GET_DASHBOARD_ENERGY_SOLD_FAIL,
  GET_DASHBOARD_SESSION_COUNT,
  GET_DASHBOARD_SESSION_COUNT_SUCCESS,
  GET_DASHBOARD_SESSION_COUNT_FAIL,
  GET_DASHBOARD_DAILY_WIDGET_DATA,
  GET_DASHBOARD_DAILY_WIDGET_DATA_SUCCESS,
  GET_DASHBOARD_DAILY_WIDGET_DATA_FAIL,
  GET_DASHBOARD_INCEPTION_WIDGET_DATA,
  GET_DASHBOARD_INCEPTION_WIDGET_DATA_SUCCESS,
  GET_DASHBOARD_INCEPTION_WIDGET_DATA_FAIL,
  GET_DASHBOARD_AMOUNT_COLLECTED,
  GET_DASHBOARD_AMOUNT_COLLECTED_SUCCESS,
  GET_DASHBOARD_AMOUNT_COLLECTED_FAIL,
  GET_DASHBOARD_CHARGER_AVAILABILITY,
  GET_DASHBOARD_CHARGER_AVAILABILITY_SUCCESS,
  GET_DASHBOARD_CHARGER_AVAILABILITY_FAIL,
} from 'actions/dashboard';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';

function* getDashboardData(action) {
  try {
    const response = yield call(API.getDashboardData, action.payload);
    yield put({ type: GET_DASHBOARD_DATA_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_DATA_FAIL, payload: { error: e } });
  }
}

function* getDashboardWidgetData(action) {
  try {
    const response = yield call(API.getDashboardWidgetData, action.payload);
    yield put({ type: GET_DASHBOARD_WIDGET_DATA_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_WIDGET_DATA_FAIL, payload: { error: e } });
  }
}

function* getDashboardChartData(action) {
  try {
    const response = yield call(API.getDashboardChartData, action.payload);
    yield put({ type: GET_DASHBOARD_CHART_DATA_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_CHART_DATA_FAIL, payload: { error: e } });
  }
}

function* getDashboardPiechartData(action) {
  try {
    const response = yield call(API.getDashboardPiechartData, action.payload);
    yield put({ type: GET_DASHBOARD_PIECHART_DATA_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_PIECHART_DATA_FAIL, payload: { error: e } });
  }
}

function* getDashboardEnergySold(action) {
  try {
    const response = yield call(API.getDashboardEnergySold, action.payload);
    yield put({ type: GET_DASHBOARD_ENERGY_SOLD_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_ENERGY_SOLD_FAIL, payload: { error: e } });
  }
}

function* getDashboardSessionCount(action) {
  try {
    const response = yield call(API.getDashboardSessionCount, action.payload);
    yield put({ type: GET_DASHBOARD_SESSION_COUNT_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_SESSION_COUNT_FAIL, payload: { error: e } });
  }
}

function* getDashboardDailyWidgetData(action) {
  try {
    const response = yield call(API.getDashboardDailyWidgetData, action.payload);
    yield put({ type: GET_DASHBOARD_DAILY_WIDGET_DATA_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_DAILY_WIDGET_DATA_FAIL, payload: { error: e } });
  }
}

function* getDashboardInceptionWidgetData(action) {
  try {
    const response = yield call(API.getDashboardInceptionWidgetData, action.payload);
    yield put({ type: GET_DASHBOARD_INCEPTION_WIDGET_DATA_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_INCEPTION_WIDGET_DATA_FAIL, payload: { error: e } });
  }
}

function* getDashboardAmountCollected(action) {
  try {
    const response = yield call(API.getDashboardAmountCollectedChart, action.payload);
    yield put({ type: GET_DASHBOARD_AMOUNT_COLLECTED_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_AMOUNT_COLLECTED_FAIL, payload: { error: e } });
  }
}

function* getDashboardChargerAvailability(action) {
  try {
    const response = yield call(API.getDashboardChargerAvailability, action.payload);
    yield put({ type: GET_DASHBOARD_CHARGER_AVAILABILITY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_DASHBOARD_CHARGER_AVAILABILITY_FAIL, payload: { error: e } });
  }
}

function* dashboard() {
  yield takeLatest(GET_DASHBOARD_DATA, getDashboardData);
  yield takeLatest(GET_DASHBOARD_WIDGET_DATA, getDashboardWidgetData);
  yield takeLatest(GET_DASHBOARD_CHART_DATA, getDashboardChartData);
  yield takeLatest(GET_DASHBOARD_PIECHART_DATA, getDashboardPiechartData);
  yield takeLatest(GET_DASHBOARD_ENERGY_SOLD, getDashboardEnergySold);
  yield takeLatest(GET_DASHBOARD_SESSION_COUNT, getDashboardSessionCount);
  yield takeLatest(GET_DASHBOARD_DAILY_WIDGET_DATA, getDashboardDailyWidgetData);
  yield takeLatest(GET_DASHBOARD_INCEPTION_WIDGET_DATA, getDashboardInceptionWidgetData);
  yield takeLatest(GET_DASHBOARD_AMOUNT_COLLECTED, getDashboardAmountCollected);
  yield takeLatest(GET_DASHBOARD_CHARGER_AVAILABILITY, getDashboardChargerAvailability);
}
export default dashboard;
