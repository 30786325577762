import {
    GET_CHARGER_TARIFF,
    GET_CHARGER_TARIFF_SUCCESS,
    GET_CHARGER_TARIFF_FAIL,
  } from 'actions/tariff';
  
  const initialState = {
    isLoading: false,
    chargerTariff: {},
  };
  
  const chargerTariffReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case GET_CHARGER_TARIFF: {
        return { ...state, isLoading: true };
      }
  
      case GET_CHARGER_TARIFF_SUCCESS: {
          return {
            ...state,
            isLoading: false,
            chargerTariff: payload,
          };
      }
      case GET_CHARGER_TARIFF_FAIL: {
        return { ...state, isLoading: false };
      }
      default:
        return { ...state };
    }
  };
  export default chargerTariffReducer;
  