import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { GET_DASHBOARD_CHARGER_AVAILABILITY } from 'actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/inputs/Card';
import * as _ from 'lodash';
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from 'moment-timezone';
import FullScreenWrapper from './FullScreen';
import PropTypes from 'prop-types';

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend);

const ChargerAvailability = ({ fullScreen, setFullScreen }) => {
  const dispatch = useDispatch();
  const barChartRef = useRef(null);

  const dashboardChargerAvailabilityData = useSelector((state) => state.dashboardChargerAvailability.dashboardChargerAvailability);
  const isLoader = useSelector((state) => state.dashboardChargerAvailability.isLoading);

  const getDashboardChargerAvailability = useCallback(
    (values) => {
      const data = {
        ...values,
        from: moment().tz('Asia/Dubai').subtract(1, 'day').startOf('day'),
        to: moment().tz('Asia/Dubai').subtract(1, 'day').endOf('day'),
      };
      dispatch({ type: GET_DASHBOARD_CHARGER_AVAILABILITY, payload: data });
    },
    [dispatch]
  );

  useEffect(() => {
    getDashboardChargerAvailability();
  }, []);

  const chargerAvailability = useMemo(() => {
    return {
      labels: _.map(dashboardChargerAvailabilityData, (item) => item.code),
      datasets: [
        {
          label: 'Uptime',
          data: _.map(dashboardChargerAvailabilityData, (item) => item.uptime),
          backgroundColor: '#14AC7F',
          barThickness: 15,
        },
        {
          label: 'Downtime',
          data: _.map(dashboardChargerAvailabilityData, (item) => item.downtime),
          backgroundColor: '#be210b',
          barThickness: 15,
        },
        {
          label: 'WS Failure',
          data: _.map(dashboardChargerAvailabilityData, (item) => item.uptimeIncludingWS),
          backgroundColor: '#C9A11B',
          barThickness: 15,
        },
      ],
    };
  });

  const downloadBarGraph = () => {
    if (barChartRef.current) {
      const chartInstance = barChartRef.current;
      const originalCanvas = chartInstance.canvas;

      const tempCanvas = document.createElement('canvas');
      const extraHeight = 60;
      tempCanvas.width = originalCanvas.width;
      tempCanvas.height = originalCanvas.height + extraHeight;

      const ctx = tempCanvas.getContext('2d');

      ctx.fillStyle = '#1b2a38';
      ctx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

      const combinedTextElement = document.querySelector('.dashboard-chargerAvailability_name');
      const [graphTitle, dateInfo] = combinedTextElement ? combinedTextElement.innerText.split('\n') : ['Bar Chart', ''];

      ctx.font = '22px Arial';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';

      ctx.fillText(graphTitle, tempCanvas.width / 2, 20);

      ctx.font = '17px Arial';
      ctx.fillStyle = '#999';
      ctx.fillText(dateInfo, tempCanvas.width / 2, 50);

      ctx.drawImage(originalCanvas, 0, extraHeight);

      const imageURL = tempCanvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = `${graphTitle.replace(/\s+/g, '_')}_${dateInfo.replace(/[^\w\d_-]/g, '')}.png`;
      link.click();
    }
  };

  return (
    <React.Fragment>
      <Card>
        <FullScreenWrapper isFullScreen={fullScreen} setIsFullScreen={setFullScreen} downloadCharts={downloadBarGraph}>
          <div className="dashboard-chargerAvailability_chart_main">
            <div className="dashboard-chargerAvailability_chart_inner">
              <div className="dashboard-chargerAvailability_chart_block">
                <div
                  className="dashboard-chargerAvailability_name"
                  style={{
                    margin: fullScreen ? '0 1vw' : '',
                  }}
                >
                  Station Uptime
                  <span className="chart-date-info">{moment().tz('Asia/Dubai').subtract(1, 'day').format('DD/MM/YYYY')}</span>
                </div>
              </div>
            </div>
            <div className="dashboard-chargerAvailability_chart-img">
              {isLoader ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <div
                  className="chargerAvailability_chart-img"
                  style={{
                    flexGrow: '1',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: fullScreen ? 'calc(100vh - 100px)' : 'calc(90vh - 210px)',
                    // minHeight: '500px',
                    margin: fullScreen ? '0 1vw' : '0',
                  }}
                >
                  <Bar
                    ref={barChartRef}
                    data={chargerAvailability}
                    options={{
                      scales: {
                        y: {
                          beginAtZero: true,
                          stacked: true,
                        },
                        x: {
                          stacked: true,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: 'bottom',
                          labels: {
                            color: '#ffffff',
                          },
                        },
                      },
                    }}
                    height="100%"
                  />
                </div>
              )}
            </div>
          </div>
        </FullScreenWrapper>
      </Card>
    </React.Fragment>
  );
};

ChargerAvailability.propTypes = {
  fullScreen: PropTypes.bool,
  setFullScreen: PropTypes.func,
};

export default ChargerAvailability;
