import React, { useContext, useState } from 'react';
import * as _ from 'lodash';
import { useHistory, useLocation } from 'react-router';
import { FaUserFriends, FaPuzzlePiece, FaSnowflake, FaCity, FaCode } from 'react-icons/fa';
import { MdOutlineLogout, MdOutlineMiscellaneousServices, MdLocalOffer } from 'react-icons/md';
import { RiDashboardLine, RiFileList2Line, RiStackLine, RiChargingPile2Line } from 'react-icons/ri';
import { BsFillPlugFill, BsChevronLeft, BsChevronRight, BsBell } from 'react-icons/bs';
import { AiOutlineCar, AiOutlineSetting, AiOutlinePieChart } from 'react-icons/ai';
import { FiRefreshCcw, FiActivity } from 'react-icons/fi';
import { FaHandshake } from 'react-icons/fa';
import { HiUserGroup, HiTemplate } from 'react-icons/hi';
import { SiGoogletagmanager } from 'react-icons/si';
// import { GiSwipeCard } from 'react-icons/gi';
import { useDispatch, useSelector } from 'react-redux';
import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { SHOW_SIDEBAR } from 'actions/sidebar';
import AdnocLogo from 'assets/images/icons/adnocLogoWhite.png';
import adnocLogo from 'assets/images/icons/adnocLogo.png';
import { cookie } from 'utils/cookies/cookies';
import { ADMIN, SUPER_ADMIN, TENANT_ADMIN, CPO, FINANCE, DOCO, SUPPORT, CPO_MANAGER } from 'components/common/constant';
import { Link } from 'react-router-dom';
import { RealTimeData } from '../privateRoute/PrivateRoute';
import { NOTIFICATION, REMOVE_NOTIFICATION } from 'actions/notification';
import { FaHandshakeAngle } from 'react-icons/fa6';

const sideMenuData = [
  {
    label: 'Dashboard',
    key: 'dashboard',
    path: '/dashboard',
    icon: <RiDashboardLine />,
    accessUser: [CPO_MANAGER, ADMIN, TENANT_ADMIN, CPO, FINANCE, DOCO],
  },
  {
    label: 'Charger Status',
    key: 'chargerStatus',
    path: '/chargerStatus',
    icon: <RiChargingPile2Line />,
    accessUser: [TENANT_ADMIN, CPO, SUPPORT, CPO_MANAGER],
  },
  {
    label: 'Stations and Chargers',
    key: 'stations',
    path: '/stations',
    icon: <BsFillPlugFill />,
    accessUser: [CPO_MANAGER, ADMIN, TENANT_ADMIN, CPO, FINANCE, DOCO, SUPPORT], //ADMIN, TENANT_ADMIN, CPO, FINANCE, DOCO, SUPPORT
  },
  {
    label: 'Add ons',
    key: 'addOns',
    icon: <FaPuzzlePiece />,
    accessUser: [TENANT_ADMIN, CPO_MANAGER],
    children: [
      {
        label: 'Add Charging Station',
        key: 'addChargingStation',
        path: '/addStation',
        accessUser: [ADMIN, TENANT_ADMIN, CPO_MANAGER],
      },
      {
        label: 'Add Charger',
        key: 'addCharger',
        path: '/addCharger',
        accessUser: [ADMIN, TENANT_ADMIN, CPO_MANAGER],
      },
      {
        label: 'Add Vehicle',
        key: 'addVehicle',
        path: '/addVehicle',
        accessUser: [],
      },
      // {
      //   label: 'Add Tenant',
      //   key: 'addTenant',
      //   path: '/addTenant',
      //   accessUser: [SUPER_ADMIN],
      // },
      // {
      //   label: 'Add Chargespot Model',
      //   key: 'addChargespotModel',
      //   path: '/addChargeSpot',
      //   accessUser: [SUPER_ADMIN],
      // },
    ],
  },
  {
    label: 'Tariff Management',
    key: 'tariffManagment',
    icon: <FaSnowflake />,
    accessUser: [ADMIN, TENANT_ADMIN, FINANCE, CPO_MANAGER],
    children: [
      {
        label: 'Tariff List',
        key: 'tariffs',
        path: '/tariffs',
        accessUser: [ADMIN, TENANT_ADMIN, FINANCE, CPO_MANAGER],
      },
      {
        label: 'Tariff Operations',
        key: 'tariffOperations',
        path: '/tariffOperations',
        accessUser: [ADMIN, TENANT_ADMIN, FINANCE, CPO_MANAGER],
      },
    ],
  },
  {
    label: 'Customer Management',
    key: 'customers',
    path: '/customers',
    icon: <FaUserFriends />,
    accessUser: [],
  },
  {
    label: 'Error Code',
    key: 'errorCode',
    path: '/errorCode',
    icon: <FaCode />,
    accessUser: [ADMIN, TENANT_ADMIN, FINANCE, CPO_MANAGER],
  },
  {
    label: 'Zone Management',
    key: 'Zones',
    path: '/Zones',
    icon: <SiGoogletagmanager />,
    accessUser: [ADMIN, TENANT_ADMIN, FINANCE, CPO_MANAGER],
  },
  {
    label: 'Subscriptions',
    key: 'subscriptions',
    path: '/subscriptions',
    icon: <RiFileList2Line />,
    accessUser: [SUPER_ADMIN],
  },
  {
    label: 'Tenant Management',
    key: 'tenantsManagement',
    path: '/tenantAdmin',
    icon: <FaUserFriends />,
    accessUser: [SUPER_ADMIN],
  },
  {
    label: 'Master',
    key: 'master',
    icon: <RiStackLine />,
    accessUser: [SUPER_ADMIN],
    children: [
      {
        label: 'Region Management',
        key: 'Region Management',
        icon: <FaCity />,
        accessUser: [SUPER_ADMIN],
        children: [
          {
            label: 'Country',
            key: 'country',
            path: '/country',
            accessUser: [SUPER_ADMIN],
          },
          {
            label: 'State',
            key: 'state',
            path: '/state',
            accessUser: [SUPER_ADMIN],
          },
          {
            label: 'City',
            key: 'city',
            path: '/city',
            accessUser: [SUPER_ADMIN],
          },
          {
            label: 'Area',
            key: 'area',
            path: '/area',
            accessUser: [SUPER_ADMIN],
          },
        ],
      },
      {
        label: 'Vehicle Management',
        key: 'Vehicle Management',
        icon: <AiOutlineCar />,
        accessUser: [SUPER_ADMIN],
        children: [
          {
            label: 'EV Make',
            key: 'evMake',
            path: '/make',
            accessUser: [SUPER_ADMIN],
          },
          {
            label: 'EV Model',
            key: 'evModel',
            path: '/model',
            accessUser: [SUPER_ADMIN],
          },
        ],
      },
      {
        label: 'Charging Station Management',
        key: 'Charging Station Management',
        icon: <RiStackLine />,
        accessUser: [SUPER_ADMIN],
        children: [
          {
            label: 'Charging Station Access Type',
            key: 'stationAccessType',
            path: '/stationAccessType',
            accessUser: [SUPER_ADMIN],
          },
          {
            label: 'Amenities',
            key: 'amenities',
            path: '/amenity',
            accessUser: [SUPER_ADMIN],
          },
          {
            label: 'Electricity Supplier',
            key: 'electricitySupplier',
            path: '/electricitySupplier',
            accessUser: [SUPER_ADMIN],
          },
        ],
      },
      {
        label: 'Template',
        key: 'Template',
        icon: <HiTemplate />,
        accessUser: [SUPER_ADMIN],
        children: [
          {
            label: 'SMS Template',
            key: 'smsTemplate',
            path: '/sms',
            accessUser: [SUPER_ADMIN],
          },
          {
            label: 'Email Template',
            key: 'emailTemplate',
            path: '/email',
            accessUser: [SUPER_ADMIN],
          },
        ],
      },
      {
        label: 'Charger Management',
        key: 'Charger Management',
        icon: <BsFillPlugFill />,
        accessUser: [SUPER_ADMIN],
        children: [
          {
            label: 'OEM',
            key: 'oem',
            path: '/oem',
            accessUser: [SUPER_ADMIN],
          },
          {
            label: 'Connector Type',
            key: 'connectorType',
            path: '/connectorType',
            accessUser: [SUPER_ADMIN],
          },
          {
            label: 'Charge Speed',
            key: 'chargeSpeed',
            path: '/chargeSpeed',
            accessUser: [SUPER_ADMIN],
          },
          {
            label: 'Chargespot Model',
            key: 'ChargespotModel',
            path: '/ChargeSpot',
            accessUser: [SUPER_ADMIN],
          },
          {
            label: 'Charger State',
            key: 'chargerState',
            path: '/chargerState',
            accessUser: [SUPER_ADMIN],
          },
          {
            label: 'Error Code Mapping',
            key: 'oemErrorCode',
            path: '/oemErrorCode',
            accessUser: [SUPER_ADMIN],
          },
        ],
      },
      {
        label: 'Miscellaneous',
        key: 'Miscellaneous',
        icon: <MdOutlineMiscellaneousServices />,
        accessUser: [SUPER_ADMIN],
        children: [
          {
            label: 'FAQs',
            key: 'faqs',
            path: '/faqs',
            accessUser: [SUPER_ADMIN],
          },
        ],
      },
      {
        label: ' Access Management',
        key: ' Access Management',
        icon: <RiStackLine />,
        accessUser: [SUPER_ADMIN],
        children: [
          {
            label: 'Role',
            key: 'role',
            path: '/role',
            accessUser: [SUPER_ADMIN],
          },
          {
            label: 'Permissions',
            key: 'permission',
            path: '/permissions',
            accessUser: [SUPER_ADMIN],
          },
        ],
      },
      // {
      //   label: 'Faq',
      //   key: 'faq',
      //   path: '/faq',
      //   accessUser: [SUPER_ADMIN],
      // },
      // {
      //   label: 'Privacy',
      //   key: 'privacy',
      //   path: '/privacy',
      //   accessUser: [SUPER_ADMIN],
      // },
    ],
  },
  {
    label: 'Billing and Invoicing',
    key: 'billingInvoicing',
    path: '/billingInvoice',
    icon: <RiFileList2Line />,
    accessUser: [],
  },
  {
    label: 'User Group Management',
    key: 'userGroups',
    path: '/userGroupManagement',
    icon: <HiUserGroup />,
    accessUser: [],
  },
  {
    label: 'Business Partner',
    key: 'businessPartner',
    icon: <FaHandshake />,
    accessUser: [ADMIN, TENANT_ADMIN, CPO_MANAGER],
    children: [
      {
        label: 'Manage Business Partner',
        key: 'manageBusinessPartner',
        path: '/manageBusinessPartner',
        accessUser: [ADMIN, TENANT_ADMIN, CPO_MANAGER],
      },
      {
        label: 'Manage Partner Vehicle',
        key: 'vehicles',
        path: '/vehicles',
        accessUser: [ADMIN, TENANT_ADMIN],
      },
    ],
  },
  {
    label: 'Vehicle Management',
    key: 'vehicles',
    path: '/vehicles',
    icon: <AiOutlineCar />,
    accessUser: [],
  },
  {
    label: 'Reports',
    key: 'dataReports',
    path: '/dataReport',
    icon: <AiOutlinePieChart />,
    accessUser: [ADMIN, TENANT_ADMIN, CPO, FINANCE, DOCO, SUPPORT, CPO_MANAGER],
    children: [
      {
        label: 'Download Advance Report',
        key: 'downloadAdvanceReport',
        path: '/downloadAdvanceReport',
        accessUser: [ADMIN, TENANT_ADMIN, CPO, CPO_MANAGER],
      },
      {
        label: ' Station Charging Summary',
        key: 'stationChargingSummary',
        path: '/stationChargingSummary',
        accessUser: [ADMIN, TENANT_ADMIN, CPO, CPO_MANAGER, SUPPORT],
      },
      {
        label: ' Charging Sessions Report',
        key: 'chargingSessionsReport',
        path: '/chargingSessionsReport',
        accessUser: [ADMIN, TENANT_ADMIN, CPO, CPO_MANAGER, SUPPORT],
      },
      {
        label: ' Failed Transaction Report',
        key: 'failedTransactionReport',
        path: '/failedTransactionReport',
        accessUser: [ADMIN, TENANT_ADMIN, CPO, CPO_MANAGER, SUPPORT],
      },
      // {
      //   label: 'Capacity Utilization',
      //   key: 'Capacityutilization',
      //   path: '/Capacityutilization',
      //   accessUser: [ADMIN, TENANT_ADMIN, CPO],
      // },
      {
        label: 'Station DownTime Report',
        key: 'stationDownTimeReport',
        path: '/stationDownTimeReport',
        accessUser: [ADMIN, TENANT_ADMIN, CPO, CPO_MANAGER],
      },
      {
        label: 'Charge Transaction Report',
        key: 'chargeTransactionReport',
        path: '/chargeTransactionReport',
        accessUser: [ADMIN, TENANT_ADMIN],
      },
      {
        label: 'B2B Station Charging History',
        key: 'b2bStationChargingHistory',
        path: '/b2bStationChargingHistory',
        accessUser: [], //ADMIN, TENANT_ADMIN
      },
      {
        label: 'B2C Station Charging History',
        key: 'b2cStationChargingHistory',
        path: '/b2cStationChargingHistory',
        accessUser: [],
      },
      // {
      //   label: 'Json Request Response Report',
      //   key: 'jsonRequestResponseReport',
      //   path: '/jsonRequestResponseReport',
      //   accessUser: [],
      // },
      {
        label: 'Wallet Transaction Report',
        key: 'walletTransactionReport',
        path: '/walletTransactionReport',
        accessUser: [],
      },
      {
        label: 'Money Transaction PG Report',
        key: 'moneyTransactionPGReport',
        path: '/moneyTransactionPGReport',
        accessUser: [],
      },
      {
        label: 'App User Details List',
        key: 'appUserDetailsList',
        path: '/appUserDetailsList',
        accessUser: [],
      },
      {
        label: 'Most Vehicle Charged List',
        key: 'mostVehicleChargedList',
        path: '/mostVehicleChargedList',
        accessUser: [],
      },
      {
        label: 'Booking History',
        key: 'bookingHistory',
        path: '/bookingHistory',
        accessUser: [],
      },
      {
        label: 'User Charging History',
        key: 'userChargingHistory',
        path: '/userChargingHistory',
        accessUser: [],
      },
      {
        label: ' Booking Summary Report',
        key: 'bookingSummaryReport',
        path: '/bookingSummaryReport',
        accessUser: [],
      },
      {
        label: ' Partner Charging Sessions',
        key: 'accessReport',
        path: '/partnerChargingSessions',
        accessUser: [ADMIN, TENANT_ADMIN, DOCO, CPO, CPO_MANAGER],
      },
    ],
  },
  // {
  //   label: 'RFID Master',
  //   key: 'RFIDMaster',
  //   path: '/rfidGroup',
  //   icon: <GiSwipeCard />,
  //   accessUser: [ADMIN, TENANT_ADMIN],
  // },
  {
    label: 'Firmware update',
    key: 'firmwareUpdate',
    path: '/firmWareUpdate',
    icon: <FiRefreshCcw />,
    accessUser: [SUPER_ADMIN],
  },
  {
    label: 'Activity Log',
    key: 'activityLog',
    path: '/activityLog',
    icon: <FiActivity />,
    accessUser: [],
  },
  {
    label: 'Notification',
    key: 'notification',
    path: '/notification',
    icon: <BsBell />,
    accessUser: [ADMIN, TENANT_ADMIN],
  },
  {
    label: 'Offers',
    key: 'offers',
    path: '/offers',
    icon: <MdLocalOffer />,
    accessUser: [],
  },
  {
    label: 'Partner Management',
    key: 'PartnerManagement',
    icon: <FaHandshakeAngle />,
    accessUser: [ADMIN, TENANT_ADMIN],
    children: [
      {
        label: 'CPO',
        key: 'cpo',
        path: '/cpo',
        accessUser: [ADMIN, TENANT_ADMIN],
      },
      {
        label: 'EMSP',
        key: 'emsp',
        path: '/emsp',
        accessUser: [ADMIN, TENANT_ADMIN],
      },
    ],
  },
  {
    label: 'Settings',
    key: 'settings',
    path: '/setting',
    icon: <AiOutlineSetting />,
    accessUser: [ADMIN, TENANT_ADMIN, CPO, SUPPORT],
  },
  {
    label: 'Logout',
    key: 'logout',
    path: '/logout',
    icon: <MdOutlineLogout />,
  },
];

const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentUserRole = cookie.get('user-role');
  const isShowSidebar = useSelector((state) => state.sidebar.showSidebar);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const realTimeDataType = useContext(RealTimeData);
  const notification = useSelector((state) => state.notification.notificationCount);
  // const role = useSelector((state) => state.profile.userProfile.role);
  // const [notificationCount, setNotificationCount] = useState( 0);
  const handleToggleSidebar = () => {
    dispatch({ type: SHOW_SIDEBAR });
  };

  React.useEffect(() => {
    // real-time Notification
    if (
      realTimeDataType &&
      realTimeDataType !== 'update_dashboard' &&
      realTimeDataType !== false &&
      !getActivePage(location.pathname, '/notification') === true
    ) {
      // setNotificationCount(notificationCount + 1);
      dispatch({ type: NOTIFICATION, payload: notification + 1 });
    }
  }, [realTimeDataType]);

  const gotoPage = (item) => {
    if (_.get(item, 'path') !== '') {
      history.push(item.path);
    }
  };

  const removeNotification = () => {
    dispatch({ type: REMOVE_NOTIFICATION });
  };

  React.useEffect(() => {
    if (getActivePage(location.pathname, '/notification') === true) {
      dispatch({ type: REMOVE_NOTIFICATION });
    }
  }, [location]);

  const getActivePage = (currentPage, pageItem) => {
    const currPageName = _.split(currentPage, '/')[1];
    const itemPageName = _.split(pageItem, '/')[1];
    return !!(currPageName && itemPageName && currPageName === itemPageName);
  };

  const getSideMenuByRole = (arrData, role) => {
    return _.filter(arrData, (dataItem) => {
      if (_.has(dataItem, 'accessUser')) {
        return _.filter(dataItem.accessUser, (userRole) => userRole === role).length > 0;
      } else {
        return dataItem;
      }
    });
  };

  return (
    <React.Fragment>
      <aside className="sidebar-main">
        <ProSidebar collapsed={isCollapsed} className="react-sidebar__main" breakPoint="md" toggled={isShowSidebar} onToggle={handleToggleSidebar}>
          <SidebarHeader className="sidebar-header--block">
            <Link to="/dashboard">
              {isCollapsed ? (
                <div className="small-sidebar-logo__block">
                  <div className="small-logo--img">
                    <img src={adnocLogo} alt="" />
                  </div>
                </div>
              ) : (
                <div className="sidebar__logo">
                  <div className="logo--img">
                    <img src={AdnocLogo} alt="adnocLogo" />
                  </div>
                </div>
              )}
            </Link>
          </SidebarHeader>
          <SidebarContent className="sidebar-content--block scrollable">
            <Menu className="side-menu-bar">
              {_.map(getSideMenuByRole(sideMenuData, currentUserRole), (item, index) => {
                const activeMenu = getActivePage(location.pathname, _.get(item, 'path'));
                let subMenuPage;
                if (item.children) {
                  subMenuPage = _.find(item.children, { path: location.pathname });
                }
                if (item.children) {
                  return (
                    <SubMenu
                      key={`sub-menu-${item.key}-${index}`}
                      className="sidebar-menu-item sidebar-sub-menu-item"
                      title={item.label}
                      icon={item.icon}
                      defaultOpen={isShowSidebar && subMenuPage}
                    >
                      {_.map(getSideMenuByRole(item.children, currentUserRole), (subItem, subIndex) => {
                        const activeSubMenu = getActivePage(location.pathname, _.get(subItem, 'path'));
                        return subItem.children ? (
                          <SubMenu
                            key={`sub-menu-${subItem.key}-${subIndex}`}
                            className="sub-menu-inner-menu-item"
                            title={subItem.label}
                            icon={subItem.icon}
                            // defaultOpen={isShowSidebar && subMenuPage}
                          >
                            {_.map(getSideMenuByRole(subItem.children, currentUserRole), (subItem, subIndex) => {
                              const activeSubMenu = getActivePage(location.pathname, _.get(subItem, 'path'));
                              return (
                                <MenuItem
                                  key={`sub-menu-${subItem.key}-${subIndex}`}
                                  className={`sidebar-menu-item ${activeSubMenu ? 'active' : ''}`}
                                  onClick={() => gotoPage(subItem)}
                                >
                                  <Link to={subItem.path} target="_self">
                                    {subItem.label}
                                  </Link>
                                </MenuItem>
                              );
                            })}
                          </SubMenu>
                        ) : (
                          <MenuItem
                            key={`sub-menu-${subItem.key}-${subIndex}`}
                            className={`sidebar-menu-item ${activeSubMenu ? 'active' : ''}`}
                            onClick={() => gotoPage(subItem)}
                          >
                            <Link to={subItem.path} target="_self">
                              {subItem.label}
                            </Link>
                          </MenuItem>
                        );
                      })}
                    </SubMenu>
                  );
                } else {
                  return item.label === 'Notification' ? (
                    <MenuItem
                      key={`menu-${item.key}-${index}`}
                      className={`sidebar-menu-item ${activeMenu ? 'active' : ''}`}
                      icon={item.icon}
                      onClick={() => {
                        gotoPage(item);
                        removeNotification();
                      }}
                    >
                      <Link to={item.path} target="_self">
                        {item.label}
                      </Link>
                      {notification > 0 && !activeMenu && <span className="notification--number"> {notification}</span>}
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={`menu-${item.key}-${index}`}
                      className={`sidebar-menu-item ${activeMenu ? 'active' : ''}`}
                      icon={item.icon}
                      onClick={() => gotoPage(item)}
                    >
                      <Link to={item.path} target="_self">
                        {item.label}
                      </Link>
                    </MenuItem>
                  );
                }
              })}
            </Menu>
          </SidebarContent>
          <SidebarFooter className="sidebar-footer--block">
            <div className="sidebar-footer--block__inner">
              <span onClick={() => setIsCollapsed(!isCollapsed)}>{isCollapsed ? <BsChevronRight /> : <BsChevronLeft />}</span>
            </div>
          </SidebarFooter>
        </ProSidebar>
      </aside>
    </React.Fragment>
  );
};

Sidebar.propTypes = {};

export default Sidebar;
