import { get, post } from 'utils/api';

export const getDashboardData = (data) => {
  return get(`/dashboard`, data);
};

export const getDashboardWidgetData = (data) => {
  return get(`/dashboard/widgets`, data);
};

export const getDashboardChartData = (data) => {
  return get(`/dashboard/charts`, data);
};

export const getDashboardPiechartData = (data) => {
  return get(`/dashboard/piechart`, data);
};

export const getDashboardEnergySold = (data) => {
  return post(`/dashboard/energySold`, data);
};

export const getDashboardSessionCount = (data) => {
  return post(`/dashboard/sessionCount`, data);
};

export const getDashboardDailyWidgetData = (data) => {
  return get(`/dashboard/dailyWidgets`, data);
};

export const getDashboardInceptionWidgetData = (data) => {
  return get(`/dashboard/inceptionWidgets`, data);
};

export const getDashboardAmountCollectedChart = (data) => {
  return post(`/dashboard/amountCollected`, data);
};

export const getDashboardChargerAvailability = (data) => {
  return post(`/dashboard/chargerAvailability`, data);
};

export const dashboard = {
  getDashboardData,
  getDashboardWidgetData,
  getDashboardChartData,
  getDashboardPiechartData,
  getDashboardEnergySold,
  getDashboardSessionCount,
  getDashboardDailyWidgetData,
  getDashboardInceptionWidgetData,
  getDashboardAmountCollectedChart,
  getDashboardChargerAvailability,
};
