import React, { useEffect, useCallback, useState } from 'react';
import { Col, Row, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import * as _ from 'lodash';
import { STATION_DOWNTIME_REPORT, DOWNLOAD_STATION_DOWNTIME_REPORT, DOWNLOAD_PDF_STATION_DOWNTIME_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { convertSeconds } from 'components/common/utils';
import { BsDownload } from 'react-icons/bs';
import DateTimePicker from 'components/inputs/DateTimePicker';
import { BiSort } from 'react-icons/bi';
import { IoSearchOutline } from 'react-icons/io5';
import fileDownload from 'js-file-download';
import ReactPagination from 'components/general/ReactPagination';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import Select from 'components/inputs/Select';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import OffCanvas from 'components/inputs/OffCanvas';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { toast } from 'react-toastify';
import { FETCH_ZONES } from 'actions/zoneManagement';
import PropTypes from 'prop-types';

const StationDownTimeReport = () => {
  const dispatch = useDispatch();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  // const [allStationDownTimeReportData, setAllStationDownTimeReportData] = useState([]);
  const [startDate, setStartDate] = useState(moment().tz('Asia/Dubai').subtract(1, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz('Asia/Dubai').subtract(1, 'day').endOf('day'));
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [changeStation, setChangeStation] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [selectedOption, setSelectedOption] = useState('excel');
  const reportFormats = [
    { label: 'PDF Format', value: 'pdf' },
    { label: 'Excel Sheet', value: 'excel' },
  ];

  const stationDownTimeReportList = useSelector((state) => state?.stationDownTimeReport?.stationDownTimeReport);
  const isLoader = useSelector((state) => state?.stationDownTimeReport?.isLoading);
  const uptimeCount = useSelector((state) => state?.stationDownTimeReport?.uptime);
  const uptimeIncludingWSCount = useSelector((state) => state?.stationDownTimeReport?.uptimeIncludingWS);
  const allStation = useSelector((state) => state?.chargingStation?.chargingStations);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const allZones = useSelector((state) => state?.zoneManagement?.zones);
  const zonePage = useSelector((state) => state?.zoneManagement?.page);
  const zoneTotalPage = useSelector((state) => state?.zoneManagement?.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const hasData = stationDownTimeReportList && stationDownTimeReportList.length > 0;

  const [page, setPage] = useState(1);
  const limit = 10;
  const totalData = stationDownTimeReportList.length;
  const totalPages = Math.ceil(totalData / limit);

  const handlePageClick = (pageVal) => {
    setPage(pageVal.selected + 1);
  };

  const startIndex = (page - 1) * limit;
  const endIndex = Math.min(startIndex + limit, totalData);
  const sortedData = _.orderBy(stationDownTimeReportList, [sortByItem.item], [sortByItem.order]);
  const dataToShow = sortedData.slice(startIndex, endIndex);

  const getAllZones = useCallback((data = {}) => {
    dispatch({ type: FETCH_ZONES, payload: data });
  }, []);

  const stationDownTimeReport = useCallback(
    (value) => {
      if (searchText) {
        const stationDownTimeReportData = {
          ...value,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          search: searchText,
        };
        dispatch({
          type: STATION_DOWNTIME_REPORT,
          payload: stationDownTimeReportData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else if (!_.isEmpty(userProfileStations)) {
        const data = {
          ...value,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          charging_station: userProfileStations,
        };
        dispatch({
          type: STATION_DOWNTIME_REPORT,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
        };
        dispatch({
          type: STATION_DOWNTIME_REPORT,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText]
  );

  const dateFilter = useCallback(() => {
    const startMoment = moment(startDate).tz('Asia/Dubai');
    const endMoment = moment(endDate).tz('Asia/Dubai');

    const startMonth = startMoment.month();
    const startYear = startMoment.year();
    const endMonth = endMoment.month();
    const endYear = endMoment.year();

    if (startMonth === endMonth && startYear === endYear) {
      const daysInMonth = startMoment.daysInMonth();
      const diffInDays = endMoment.diff(startMoment, 'days');

      if (diffInDays >= daysInMonth) {
        toast.error(`The date range exceeds ${daysInMonth} days.`);
        return false;
      }
    } else {
      const startMonthDays = startMoment.daysInMonth();
      const remainingDaysInStartMonth = startMonthDays - startMoment.date() + 1;
      const daysInEndMonth = endMoment.date();

      const totalDays = remainingDaysInStartMonth + daysInEndMonth;

      if (totalDays > startMonthDays) {
        toast.error('The selected date range exceeds the allowed days.');
        return false;
      }
    }
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
        };
        stationDownTimeReport(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
        };
        stationDownTimeReport(data);
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        charging_station: userProfileStations,
      };
      stationDownTimeReport(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
      };
      stationDownTimeReport(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData, searchText]);

  // useEffect(() => {
  //   setAllStationDownTimeReportData(
  //     _.filter(
  //       stationDownTimeReportList,
  //       (item) =>
  //         startsWith(_.get(item, 'downtime_count').toString(), searchText) ||
  //         startsWith(millisecondsToHourMinute(_.get(item, 'issue_duration')), searchText) ||
  //         startsWith([..._.map(item.charger, (data) => _.get(data, 'charger_id', ''))].toString(), searchText) ||
  //         startsWith([..._.map(item.charging_station, (data) => _.get(data, 'name', ''))].toString(), searchText)
  //     )
  //   );
  // }, [searchText, stationDownTimeReportList]);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const handleSearch = () => {
    stationDownTimeReport({});
  };

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  useEffect(() => {
    if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        charging_station: userProfileStations,
      };
      stationDownTimeReport(data);
    } else {
      stationDownTimeReport();
    }
    getAllStation();
    getAllZones({ limit: 99 });
  }, []);

  // Download Excel Functionality

  const downloadStationDownTimeReport = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
        };
        dispatch({
          type: selectedOption === 'excel' ? DOWNLOAD_STATION_DOWNTIME_REPORT : DOWNLOAD_PDF_STATION_DOWNTIME_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Station DownTime Report.${selectedOption === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
        };
        dispatch({
          type: selectedOption === 'excel' ? DOWNLOAD_STATION_DOWNTIME_REPORT : DOWNLOAD_PDF_STATION_DOWNTIME_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Station DownTime Report.${selectedOption === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        charging_station: userProfileStations,
      };
      dispatch({
        type: selectedOption === 'excel' ? DOWNLOAD_STATION_DOWNTIME_REPORT : DOWNLOAD_PDF_STATION_DOWNTIME_REPORT,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `Station DownTime Report.${selectedOption === 'excel' ? 'xlsx' : 'pdf'}`);
            }
          }
        },
      });
    } else if (searchText) {
      const data = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        search: searchText,
      };
      dispatch({
        type: selectedOption === 'excel' ? DOWNLOAD_STATION_DOWNTIME_REPORT : DOWNLOAD_PDF_STATION_DOWNTIME_REPORT,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `Station DownTime Report.${selectedOption === 'excel' ? 'xlsx' : 'pdf'}`);
            }
          }
        },
      });
    } else {
      const data = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
      };
      dispatch({
        type: selectedOption === 'excel' ? DOWNLOAD_STATION_DOWNTIME_REPORT : DOWNLOAD_PDF_STATION_DOWNTIME_REPORT,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `Station DownTime Report.${selectedOption === 'excel' ? 'xlsx' : 'pdf'}`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData, selectedOption, searchText]);

  const downloadFile = () => {
    downloadStationDownTimeReport();
  };

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && stationDownTimeReport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        stationDownTimeReport(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && stationDownTimeReport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        stationDownTimeReport(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const search = (
    <SearchBox
      preIcon={<BsSearch />}
      value={searchText}
      onChange={searchHandler}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
    />
  );
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const stationList = allStation.filter((x) => userProfileStations.indexOf(x.id) !== -1);
  const initialValues = !_.isEmpty(advanceFilterData) ? { ...advanceFilterData } : { charging_station: '', wallet_type: '', type: '', zones: '' };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const tooltip = document.getElementsByClassName('more-button-tooltip')[0];
      if (tooltip && !tooltip.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleTooltip = (content) => {
    setShowTooltip(!showTooltip);
    if (content) {
      setTooltipContent(content);
    }
  };

  const renderZoneNames = (infoTitle, remainingNumbers, tooltipId) => {
    return (
      <>
        {infoTitle}
        {remainingNumbers.length > 0 && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id={tooltipId} className="more-button-tooltip">
                {remainingNumbers.join(', ')}
              </Tooltip>
            }
            show={showTooltip && tooltipContent === tooltipId}
            onHide={() => toggleTooltip('')}
          >
            <span className="more-button" onClick={() => toggleTooltip(tooltipId)}>
              {'More'}
            </span>
          </OverlayTrigger>
        )}
      </>
    );
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Station DownTime Report" />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <div className="data-report--block">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col xl={'auto'} md={'auto'}>
                        <UptimeCard key="Uptime" title={uptimeCount} subTitle="Uptime %" />
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <UptimeCard key="Uptime (Including ws Failure)" title={uptimeIncludingWSCount} subTitle="Uptime % (ws Failure)" />
                      </Col>
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            timeFormatOfStartDate={false}
                            timeFormatOfEndDate={false}
                            initialValueOfStartDate={moment(new Date()).tz('Asia/Dubai').subtract(1, 'day').startOf('day')}
                            initialValueOfEndDate={moment(new Date()).tz('Asia/Dubai').subtract(1, 'day').endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">Search</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">
                              <span>Advanced Filter</span>
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={2} md={2}>
                        <Select
                          options={reportFormats}
                          value={selectedOption}
                          onChange={(value) => setSelectedOption(value)}
                          placeholder="Select Report Format"
                        />
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={hasData ? downloadFile : null}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className={`report-excel-icon  ${!hasData ? 'disabled' : ''}`}>
                            <BsDownload title={!hasData ? '' : 'Download'} size={28} color={!hasData ? '#d3d3d3' : '#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>Sr No.</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Station Name</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Station Code</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Charger ID</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Zones Name</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Downtime Count</span>
                                <span className="ico" onClick={() => handleSorting('downtimeCount', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Uptime %</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Uptime (Including ws Failure) %</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Downtime Percentage %</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Utilization Percentage %</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Issue Duration (HH:MM:SS)</span>
                                <span className="ico" onClick={() => handleSorting('unavailableTime', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={11} className="border-0">
                                <div className="loader--block">
                                  <Spinner as="span" animation="border" size="sm" role="status" />
                                  <span className="ps-2">Loading...</span>
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(dataToShow) ? (
                            <tr>
                              <td colSpan={6} className="border-0">
                                <div className="empty-data-block">No Station DownTime Report Found</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(dataToShow, (item, key) => {
                              const serial_num = limit * (page - 1) + key;
                              const zones = _.get(item, 'zoneNames', '-');
                              return (
                                <tr key={`station-downTime-report-${serial_num}`}>
                                  <td>{serial_num + 1}</td>
                                  <td>{_.get(item, 'charging_station.name', '-')}</td>
                                  <td>{_.get(item, 'charging_station.code', '')}</td>
                                  <td>{_.get(item, 'charger_id', '-')}</td>
                                  <td>
                                    {!_.isEmpty(item?.zoneNames)
                                      ? renderZoneNames(_.head(zones), _.tail(zones), `reason-tooltip-${serial_num}`)
                                      : '-'}
                                  </td>
                                  <td>{_.get(item, 'downtimeCount', '-')}</td>
                                  <td>{_.get(item, 'actualUptime', '-')}</td>
                                  <td>{_.get(item, 'utilization_percent_including_ws', '-')}</td>
                                  <td>{_.get(item, 'downtime_percent', '-')}</td>
                                  <td>{_.get(item, 'utilization_percent', '-')}</td>
                                  <td>{convertSeconds(_.get(item, 'unavailableTime', 0))}</td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(dataToShow) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </LayoutContainer>
      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.charging_station && delete values['charging_station'];
              !values.zones && delete values['zones'];
              if (values.charging_station === 'All') {
                const walletTransactionReportData = { ...values, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
                stationDownTimeReport(walletTransactionReportData);
              } else {
                stationDownTimeReport(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="canvas-selection__block">
                    <Select
                      label="Station"
                      options={
                        !_.isEmpty(stationList)
                          ? !changeStation
                            ? !_.isEmpty(stationList) && [
                                { label: 'All', value: 'All' },
                                ..._.map(stationList, (station) => {
                                  return { label: station.name, value: station.id };
                                }),
                              ]
                            : !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                          : !changeStation
                          ? !_.isEmpty(allStation) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allStation, (station) => {
                                return { label: station.name, value: station.id };
                              }),
                            ]
                          : !_.isEmpty(allStation) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder="Select Station"
                      name="charging_station"
                      isMulti
                      value={values.charging_station}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStation(true);
                          setFieldValue(`charging_station`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`charging_station`, '');
                          setChangeStation(false);
                        } else {
                          setChangeStation(false);
                          setFieldValue('charging_station', val);
                        }
                      }}
                    />
                    <Select
                      label="Zones"
                      isMulti
                      options={_.map(allZones, (item) => {
                        return { label: item.name, value: item.id };
                      })}
                      className="filter-select-box"
                      placeholder="Select Zones"
                      name="zones"
                      value={values.zones}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={getAllZones}
                      page={zonePage}
                      totalPage={zoneTotalPage}
                      onChange={(val) => {
                        setFieldValue(`zones`, val);
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    Apply Filters
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      stationDownTimeReport();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    Clear
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default StationDownTimeReport;

export const UptimeCard = (props) => {
  const { title, subTitle } = props;

  return (
    <Card className="vehicle-card">
      <div className="vehicle-detail-box">
        <div className="vehicle__count-info">
          <div className="total-vehicle">{title}</div>
          <div className="info-text">{subTitle}</div>
        </div>
      </div>
    </Card>
  );
};

UptimeCard.propTypes = {
  title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subTitle: PropTypes.string,
};
