import React from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import Card from 'components/inputs/Card';
import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import Button from 'components/inputs/Button';

const SingleTariff = (props) => {
  const { onClose } = props;
  const TariffDetail = useSelector((state) => state.tariff.tariffDetail);

  return (
    <React.Fragment>
      <div className="chargeSpot-page__main">
        <Card>
          <div className="chargeSpot-header__block">
            <div className="chargeSpot-header-name">
              <span>Tariff Configuration Details</span>
            </div>
            <div className="chargeSpot-close_btn">
              <MdClose size={30} color="#be210b" onClick={onClose} />
            </div>
          </div>
          <TariffBlock headingName={'Basic'}>
            <TariffsDetail chargeSpotText={'Tariff Name'} chargeSpotvalue={_.get(TariffDetail, 'name', '')} />
            <TariffsDetail chargeSpotText={'PLU'} chargeSpotvalue={_.get(TariffDetail, 'plu', '')} />
            <TariffsDetail chargeSpotText={'Version'} chargeSpotvalue={_.get(TariffDetail, 'version', '')} />
          </TariffBlock>
          <div className="chargeSpot-connectors__block">
            {_.get(TariffDetail, 'version') === 'v3' && (
              <div className="dc-connector-table">
                <TariffBlock headingName={'Tariff Tiers'}>
                  {!_.isEmpty(_.get(TariffDetail, 'tariffs')) ? (
                    <div className="table-responsive">
                      <table className="record-list-table">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>#</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Unit</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Unit Price</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Unit AR</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>From Limit</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div className="sorting">
                                <span>To Limit</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div className="sorting">
                                <span>PLU</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div className="sorting">
                                <span>PLU Name EN</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div className="sorting">
                                <span>PLU Name AR</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div className="sorting">
                                <span>SoC Threshold</span>
                              </div>
                            </th>
                            <th className="action-col">
                              <div className="sorting">
                                <span>Initial</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {_.map(_.get(TariffDetail, 'tariffs'), (item, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{_.get(item, 'unit', '')}</td>
                                  <td>{_.get(item, 'unitPrice', '')}</td>
                                  <td>{_.get(item, 'unitAr', '')}</td>
                                  <td>{_.get(item, 'fromLimit', '')}</td>
                                  <td>{_.get(item, 'toLimit', '')}</td>
                                  <td>{_.get(item, 'plu', '')}</td>
                                  <td>{_.get(item, 'pluNameEn', '')}</td>
                                  <td>{_.get(item, 'pluNameAr', '')}</td>
                                  <td>{_.get(item, 'socThreshold', '')}</td>
                                  <td>
                                    {_.get(item, 'initial', '') === true ? (
                                      <div style={{ borderRadius: '30.68px', padding: '10px', textAlign: 'center', background: '#0f9b72' }}>
                                        Initial
                                      </div>
                                    ) : (
                                      <div style={{ borderRadius: '30.68px', padding: '10px', textAlign: 'center', background: '#be210b' }}>
                                        Not Initial
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="empty__data">No Data</div>
                  )}
                </TariffBlock>
              </div>
            )}
            {_.get(TariffDetail, 'version') === 'v2' && (
              <>
                <div className="dc-connector-table">
                  <TariffBlock headingName={'Tariff Tiers'}>
                    {!_.isEmpty(_.get(TariffDetail, 'tariffs')) ? (
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>#</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>Type</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>Unit</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>Tariff</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>Unit Price</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>Unit AR</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>From Limit</span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div className="sorting">
                                  <span>To Limit</span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div className="sorting">
                                  <span>PLU</span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div className="sorting">
                                  <span>PLU Name EN</span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div className="sorting">
                                  <span>PLU Name AR</span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div className="sorting">
                                  <span>Minimum Charging Limit</span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div className="sorting">
                                  <span>Maximum Charging Limit</span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div className="sorting">
                                  <span>SoC Threshold</span>
                                </div>
                              </th>
                              <th className="action-col">
                                <div className="sorting">
                                  <span>Initial</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.map(_.get(TariffDetail, 'tariffs'), (item, index) => {
                              return (
                                <>
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{_.get(item, 'type', '')}</td>
                                    <td>{_.get(item, 'unit', '')}</td>
                                    <td>{_.get(item, 'tariff', '')}</td>
                                    <td>{_.get(item, 'unitPrice', '')}</td>
                                    <td>{_.get(item, 'unitAr', '')}</td>
                                    <td>{_.get(item, 'fromLimit', '')}</td>
                                    <td>{_.get(item, 'toLimit', '')}</td>
                                    <td>{_.get(item, 'PLU', '')}</td>
                                    <td>{_.get(item, 'PLUNameEN', '')}</td>
                                    <td>{_.get(item, 'PLUNameAR', '')}</td>
                                    <td>{_.get(item, 'minChargingLimit', '')}</td>
                                    <td>{_.get(item, 'maxChargingLimit', '')}</td>
                                    <td>{_.get(item, 'socThreshold', '')}</td>
                                    <td>
                                      {_.get(item, 'initial', '') === true ? (
                                        <div style={{ borderRadius: '30.68px', padding: '10px', textAlign: 'center', background: '#0f9b72' }}>
                                          Initial
                                        </div>
                                      ) : (
                                        <div style={{ borderRadius: '30.68px', padding: '10px', textAlign: 'center', background: '#be210b' }}>
                                          Not Initial
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="empty__data">No Data</div>
                    )}
                  </TariffBlock>
                </div>
                <div className="ac-connector-table">
                  <TariffBlock headingName={'Schedule'}>
                    {!_.isEmpty(_.get(TariffDetail, 'schedule')) ? (
                      <div className="table-responsive">
                        <table className="record-list-table">
                          <thead>
                            <tr>
                              <th>
                                <div className="sorting">
                                  <span>#</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>Price Per Unit</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>From</span>
                                </div>
                              </th>
                              <th>
                                <div className="sorting">
                                  <span>To</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {_.map(_.get(TariffDetail, 'schedule'), (item, index) => {
                              return (
                                <>
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{_.get(item, 'price_per_unit', '')}</td>
                                    <td>{_.get(item, 'from', '')}</td>
                                    <td>{_.get(item, 'to', '')}</td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="empty__data">No Data</div>
                    )}
                  </TariffBlock>
                </div>
              </>
            )}
          </div>
          <div className="chargeSpot-close-button-box" onClick={onClose}>
            <Button className="chargeSpot-close-button">Close</Button>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

export const TariffBlock = (props) => {
  const { headingName, children } = props;

  return (
    <div className="chargeSpot-body__block">
      <div className="chargeSpot-inner--block">
        <div className="chargeSpot--header">
          <span className="chargeSpot-header-text">{headingName}</span>
        </div>
        <div className="chargeSpot-content">{children}</div>
      </div>
    </div>
  );
};
TariffBlock.propTypes = {
  headingName: PropTypes.string,
  children: PropTypes.node,
};

export const TariffsDetail = (props) => {
  const { chargeSpotText, chargeSpotvalue } = props;
  return (
    <div className="chargeSpot-content-inner">
      <div className="chargeSpot-content-text">{chargeSpotText}</div>
      <div className="chargeSpot-content-detail">{chargeSpotvalue}</div>
    </div>
  );
};

TariffsDetail.propTypes = {
  chargeSpotText: PropTypes.string,
  chargeSpotvalue: PropTypes.string,
};

SingleTariff.propTypes = {
  onClose: PropTypes.func,
};

export default SingleTariff;
