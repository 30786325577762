import {
  FETCH_CHARGER_STATUS_WIDGETS,
  FETCH_CHARGER_STATUS_WIDGETS_SUCCESS,
  FETCH_CHARGER_STATUS_WIDGETS_FAIL,
} from 'actions/chargerStatus';

const initialState = {
  isLoading: false,
  chargerStatusWidgets: {},
};

const chargerStatusWidgetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CHARGER_STATUS_WIDGETS: {
      return { ...state, isLoading: true };
    }

    case FETCH_CHARGER_STATUS_WIDGETS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        chargerStatusWidgets: {
          ...payload,
          available: payload.data?.availableCount,
          disabled: payload.data?.disabledCount,
          draftCount: payload.data?.draftCount,
          emergencyStopCount: payload.data?.emergencyStopCount,
          enabled: payload.data?.enabledCount,
          faultedCount: payload.data?.faultedCount,
          inuse: payload.data?.inUseCount,
          overVoltage: payload.data?.overVoltage,
          PowerLossCount: payload.data?.powerLossCount,
          total: payload.data?.total,
          unavailable: payload.data?.unavailableCount,
          underVoltage: payload.data?.underVoltage,
        },
      };
      }
          
    case FETCH_CHARGER_STATUS_WIDGETS_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default chargerStatusWidgetReducer;
