import { STATION_DOWNTIME_REPORT, STATION_DOWNTIME_REPORT_FAIL, STATION_DOWNTIME_REPORT_SUCCESS } from 'actions/dataReport';

const initialState = {
  isLoading: false,
  stationDownTimeReport: [],
};

const stationDownTimeReportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case STATION_DOWNTIME_REPORT: {
      return { ...state, isLoading: true };
    }
    case STATION_DOWNTIME_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        stationDownTimeReport: payload?.results,
        uptime: payload?.avgUptime,
        uptimeIncludingWS: payload?.avgUptimeIncludingWs,
      };
    }

    case STATION_DOWNTIME_REPORT_FAIL: {
      return { ...state, isLoading: false };
    }
    default:
      return { ...state };
  }
};
export default stationDownTimeReportReducer;
