import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { GET_DASHBOARD_AMOUNT_COLLECTED } from 'actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/inputs/Card';
import * as _ from 'lodash';
import { Chart as ChartJS, CategoryScale, ArcElement, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment-timezone';
import Select from 'components/inputs/Select';
import seedrandom from 'seedrandom';
import FullScreenWrapper from './FullScreen';
import { styles } from 'components/inputs/Select';
import PropTypes from 'prop-types';

ChartJS.register(ArcElement, CategoryScale, LinearScale, PointElement, BarElement, LineElement, Title, Tooltip, Legend);

const AmountCollectedGraph = ({ fullScreen, setFullScreen, zones, allstations }) => {
  const dispatch = useDispatch();
  const lineChartRef = useRef(null);
  const previousSelectionRef = useRef({ year: null, month: null });
  const hasUserNavigated = useRef(false);

  const dashboardAmountCollectedData = useSelector((state) => state?.dashboardAmountCollected?.dashboardAmountCollected);
  const isLoaded = useSelector((state) => state?.dashboardAmountCollected?.isLoading);

  const [selectedWeek, setSelectedWeek] = useState();
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  const [selectedZone, setSelectedZone] = useState('');
  const [selectedStation, setSelectedStation] = useState([]);
  const [filterState, setFilterState] = useState({
    zone: '',
    stations: [],
    schedule: 'Weekly',
    from: moment().tz('Asia/Dubai').startOf('week'),
    to: moment().tz('Asia/Dubai').endOf('week'),
  });

  useEffect(() => {
    let payload = {
      from: filterState?.from,
      to: filterState?.to,
    };
    if (!_.isEmpty(filterState.zone)) {
      payload = {
        ...payload,
        zone: filterState?.zone,
      };
    } else if (!_.isEmpty(filterState?.stations)) {
      payload = {
        ...payload,
        stations: filterState?.stations,
      };
    }
    getDashboardAmountCollectedData(payload);
  }, [filterState]);

  const [weeks, setWeeks] = useState([]);

  const getDashboardAmountCollectedData = useCallback(
    (values) => {
      dispatch({ type: GET_DASHBOARD_AMOUNT_COLLECTED, payload: values });
    },
    [dispatch]
  );

  const currentYear = moment().year();

  const years = Array.from({ length: currentYear - 2024 + 1 }, (_, i) => 2024 + i);

  const generateMonths = (year) => {
    const currentYear = moment().year();
    const currentMonth = moment().month();

    let startMonth = 1;
    let endMonth = 12;

    if (year === 2024) {
      startMonth = 5;
    } else if (year === currentYear) {
      endMonth = currentMonth + 1;
    }

    return Array.from({ length: endMonth - startMonth + 1 }, (_, i) => startMonth + i);
  };

  const [months, setMonths] = useState(generateMonths(selectedYear));

  useEffect(() => {
    const updatedMonths = generateMonths(selectedYear);
    setMonths(updatedMonths);

    if (selectedMonth !== null && !updatedMonths.includes(selectedMonth)) {
      setSelectedMonth(updatedMonths[updatedMonths.length - 1]);
    }
  }, [selectedYear]);

  function getWeeksInMonth(date) {
    const firstDay = moment(date).startOf('month').startOf('week');
    const lastDay = moment(date).endOf('month').endOf('week');

    return lastDay.diff(firstDay, 'weeks') + 1;
  }

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      const weeksInMonth = getWeeksInMonth(moment([selectedYear, selectedMonth - 1]));

      const weekData = Array.from({ length: weeksInMonth }, (_, i) => {
        const start = moment([selectedYear, selectedMonth - 1])
          .startOf('month')
          .add(i, 'weeks')
          .startOf('week');

        const end = moment(start).endOf('week');

        return {
          value: i + 1,
          label: `${start.format('MMM DD')} - ${end.format('MMM DD')}`,
          startDate: start,
          endDate: end,
        };
      });

      setWeeks(weekData);

      const isCurrentYear = selectedYear === moment().year();
      const isCurrentMonth = selectedMonth === moment().month() + 1;

      if (isCurrentYear && isCurrentMonth && hasUserNavigated.current) {
        setSelectedWeek(null);
        hasUserNavigated.current = false;
      } else {
        setSelectedWeek(weekData.find((w) => moment().isBetween(w.startDate, w.endDate, null, '[]'))?.value);
      }

      if (previousSelectionRef.current.year !== selectedYear || previousSelectionRef.current.month !== selectedMonth) {
        hasUserNavigated.current = true;
      }

      previousSelectionRef.current = { year: selectedYear, month: selectedMonth };
    }
  }, [selectedMonth, selectedYear]);

  const hashStationID = (id) => {
    let hash = 0;
    for (let i = 0; i < id.length; i++) {
      const char = id.charCodeAt(i);
      hash = (hash << 27) - hash + char;
      hash &= hash;
    }
    return Math.abs(hash).toString();
  };

  const generateRandomColor = (stationID) => {
    const seed = hashStationID(stationID);

    const rng = seedrandom(seed);

    const hue = Math.floor(rng() * 360);
    const saturation = Math.floor(rng() * 50) + 50;
    const lightness = Math.floor(rng() * 40) + 40;

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  const amountCollected = useMemo(() => {
    const datasets = [];

    if (filterState?.schedule === 'Weekly') {
      if (!_.isEmpty(filterState?.stations)) {
        filterState?.stations?.forEach((stationId) => {
          const station = allstations.find((s) => s.id === stationId);
          if (station) {
            const stationData = dashboardAmountCollectedData[station.code]?.amountCollected || [];
            const color = generateRandomColor(station.id);
            datasets.push({
              label: station.code,
              data: stationData.map((item) => (item.total === null ? null : item.total)),
              borderColor: color,
              backgroundColor: color,
              tension: 0.4,
              spanGaps: false,
            });
          }
        });
      } else {
        const stationData = allstations?.filter((s) => s.zones?.includes(filterState?.zone));
        stationData.forEach((stationId) => {
          const station = allstations.find((s) => s.id === stationId?.id);
          if (station) {
            const stationData = dashboardAmountCollectedData[station.code]?.amountCollected || [];
            const color = generateRandomColor(station.id);
            datasets.push({
              label: station.code,
              data: stationData.map((item) => (item.total === null ? null : item.total)),
              borderColor: color,
              backgroundColor: color,
              tension: 0.4,
              spanGaps: false,
            });
          }
        });
      }
    } else if (filterState?.schedule === 'Yearly') {
      if (!_.isEmpty(filterState?.stations)) {
        filterState?.stations.forEach((stationId) => {
          const station = allstations.find((s) => s.id === stationId);
          if (station) {
            const stationData = dashboardAmountCollectedData[station.code]?.amountCollected || [];
            const color = generateRandomColor(station.id);
            datasets.push({
              label: station.code,
              data: stationData.map((item) => (item.total === null ? null : item.total)),
              borderColor: color,
              backgroundColor: color,
              tension: 0.4,
              spanGaps: false,
            });
          }
        });
      } else {
        const stationData = allstations?.filter((s) => s.zones?.includes(filterState?.zone));
        stationData.forEach((stationId) => {
          const station = allstations.find((s) => s.id === stationId?.id);
          if (station) {
            const stationData = dashboardAmountCollectedData[station.code]?.amountCollected || [];
            const color = generateRandomColor(station.id);
            datasets.push({
              label: station.code,
              data: stationData.map((item) => (item.total === null ? null : item.total)),
              borderColor: color,
              backgroundColor: color,
              tension: 0.4,
              spanGaps: false,
            });
          }
        });
      }
    } else {
      if (!_.isEmpty(filterState?.stations)) {
        filterState?.stations.forEach((stationId) => {
          const station = allstations.find((s) => s.id === stationId);
          if (station) {
            const stationData = dashboardAmountCollectedData[station.code]?.amountCollected || [];
            const color = generateRandomColor(station.id);
            datasets.push({
              label: station.code,
              data: stationData.map((item) => (item.total === null ? null : item.total)),
              borderColor: color,
              backgroundColor: color,
              tension: 0.4,
              spanGaps: false,
            });
          }
        });
      } else {
        const stationData = allstations?.filter((s) => s.zones?.includes(filterState?.zone));
        stationData.forEach((stationId) => {
          const station = allstations.find((s) => s.id === stationId?.id);
          if (station) {
            const stationData = dashboardAmountCollectedData[station.code]?.amountCollected || [];
            const color = generateRandomColor(station.id);
            datasets.push({
              label: station.code,
              data: stationData.map((item) => (item.total === null ? null : item.total)),
              borderColor: color,
              backgroundColor: color,
              tension: 0.4,
              spanGaps: false,
            });
          }
        });
      }
    }

    if (filterState?.schedule === 'Weekly') {
      if (dashboardAmountCollectedData.average) {
        datasets.push({
          label: 'Total Amount Collected',
          data: dashboardAmountCollectedData?.average?.amountCollected.map((item) => (item.total === null ? null : item.total)),
          borderColor: 'rgba(75,192,192,1)',
          backgroundColor: 'rgba(75,192,192,0.2)',
          tension: 0.4,
          spanGaps: false,
        });
        if (dashboardAmountCollectedData.average.amountCollected.some((item) => item?.average !== undefined)) {
          datasets.push({
            label: 'Average Amount Collected',
            data: dashboardAmountCollectedData.average.amountCollected.map((item) => (item.average === null ? null : item.average)),
            borderColor: '#FED035',
            tension: 0.4,
            spanGaps: false,
          });
        }
      }
    } else if (filterState?.schedule === 'Yearly') {
      if (dashboardAmountCollectedData.average) {
        datasets.push({
          label: 'Total Amount Collected',
          data: dashboardAmountCollectedData?.average?.amountCollected.map((item) => (item.total === null ? null : item.total)),
          borderColor: 'rgba(75,192,192,1)',
          backgroundColor: 'rgba(75,192,192,0.2)',
          tension: 0.4,
          spanGaps: false,
        });
        if (dashboardAmountCollectedData.average.amountCollected.some((item) => item?.average !== undefined)) {
          datasets.push({
            label: 'Average Amount Collected',
            data: dashboardAmountCollectedData.average.amountCollected.map((item) => (item.average === null ? null : item.average)),
            borderColor: '#FED035',
            tension: 0.4,
            spanGaps: false,
          });
        }
      }
    } else {
      if (dashboardAmountCollectedData.average) {
        datasets.push({
          label: 'Total Amount Collected',
          data: dashboardAmountCollectedData?.average?.amountCollected.map((item) => (item.total === null ? null : item.total)),
          borderColor: 'rgba(75,192,192,1)',
          backgroundColor: 'rgba(75,192,192,0.2)',
          tension: 0.4,
          spanGaps: false,
        });
        if (dashboardAmountCollectedData.average.amountCollected.some((item) => item?.average !== undefined)) {
          datasets.push({
            label: 'Average Amount Collected',
            data: dashboardAmountCollectedData.average.amountCollected.map((item) => (item.average === null ? null : item.average)),
            borderColor: '#FED035',
            tension: 0.4,
            spanGaps: false,
          });
        }
      }
    }

    return {
      labels: dashboardAmountCollectedData.average
        ? filterState?.schedule === 'Weekly'
          ? dashboardAmountCollectedData?.average?.amountCollected.map((item) => item.weekDay)
          : filterState?.schedule === 'Yearly'
          ? dashboardAmountCollectedData?.average?.amountCollected.map((item) => item.month)
          : dashboardAmountCollectedData?.average?.amountCollected.map((item) => item.date)
        : [],
      datasets,
    };
  }, [dashboardAmountCollectedData, selectedStation, allstations]);

  const downloadLineGraph = () => {
    if (lineChartRef.current) {
      const chartInstance = lineChartRef.current;
      const originalCanvas = chartInstance.canvas;

      const tempCanvas = document.createElement('canvas');
      const extraHeight = 60;
      tempCanvas.width = originalCanvas.width;
      tempCanvas.height = originalCanvas.height + extraHeight;

      const ctx = tempCanvas.getContext('2d');

      ctx.fillStyle = '#1b2a38';
      ctx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

      const combinedTextElement = document.querySelector('.dashboard-amountCollected_name');
      const [graphTitle, dateInfo] = combinedTextElement ? combinedTextElement.innerText.split('\n') : ['Line Chart', ''];

      ctx.font = '22px Arial';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';

      ctx.fillText(graphTitle, tempCanvas.width / 2, 20);

      ctx.font = '17px Arial';
      ctx.fillStyle = '#999';
      ctx.fillText(dateInfo, tempCanvas.width / 2, 50);

      ctx.drawImage(originalCanvas, 0, extraHeight);

      const imageURL = tempCanvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imageURL;
      link.download = `${graphTitle.replace(/\s+/g, '_')}_${dateInfo.replace(/[^\w\d_-]/g, '')}.png`;
      link.click();
    }
  };

  return (
    <React.Fragment>
      <Card>
        <FullScreenWrapper isFullScreen={fullScreen} setIsFullScreen={setFullScreen} downloadCharts={downloadLineGraph}>
          <div className="dashboard-amountCollected_chart_main">
            <div className="dashboard-amountCollected_chart_inner">
              <div className="dashboard-amountCollected_chart_block">
                <div
                  className="dashboard-amountCollected_name"
                  style={{
                    margin: fullScreen ? '0 1vw' : '0',
                  }}
                >
                  Amount Collected (AED)
                  {filterState.schedule === 'Yearly' ? (
                    <span className="chart-date-info">{selectedYear}</span>
                  ) : filterState.schedule === 'Monthly' && selectedMonth && selectedMonth !== 'All' ? (
                    <span className="chart-date-info">{`${selectedYear} - ${moment()
                      .month(selectedMonth - 1)
                      .format('MMMM')}`}</span>
                  ) : filterState.schedule === 'Weekly' && selectedWeek && selectedWeek !== 'All' ? (
                    <span className="chart-date-info">{`${moment(weeks.find((w) => w.value === selectedWeek)?.startDate).format(
                      'DD/MM/YYYY'
                    )} to ${moment(weeks.find((w) => w.value === selectedWeek)?.endDate).format('DD/MM/YYYY')}`}</span>
                  ) : null}
                </div>
              </div>

              <div
                className="dashboard-amountCollected_filters"
                style={{
                  margin: fullScreen ? '1vw' : '',
                }}
              >
                {_.isEmpty(selectedStation) ? (
                  <Select
                    options={_.map(zones, (item) => {
                      return { label: item.name, value: item.id };
                    })}
                    placeholder="Select Zones"
                    value={selectedZone}
                    onChange={(val) => {
                      setFilterState({
                        ...filterState,
                        zone: val,
                      });
                      setSelectedZone(val);
                    }}
                    className="select-container"
                    isClearable
                    styles={{
                      ...styles(),
                      menu: (provided) => ({
                        ...provided,
                        width: '350px',
                      }),
                    }}
                  />
                ) : null}
                {!selectedZone ? (
                  <Select
                    options={
                      selectedStation?.includes('All')
                        ? [{ label: 'All', value: 'All' }]
                        : [
                            { value: 'All', label: 'All' },
                            ...allstations.map((station) => ({
                              label: station?.name,
                              value: station?.id,
                            })),
                          ]
                    }
                    placeholder="Select Station"
                    isMulti
                    value={selectedStation}
                    onChange={(val) => {
                      if (val === null) {
                        setFilterState({
                          ...filterState,
                          stations: [],
                        });
                      } else if (val.includes('All')) {
                        setFilterState({
                          ...filterState,
                          stations: allstations.map((station) => station?.id),
                        });
                      } else {
                        setFilterState({
                          ...filterState,
                          stations: val,
                        });
                      }
                      setSelectedStation(val);
                    }}
                    className="select-container"
                    isClearable
                    styles={{
                      ...styles(),
                      menu: (provided) => ({
                        ...provided,
                        width: '350px',
                      }),
                    }}
                  />
                ) : null}
                <Select
                  options={_.map(years, (year) => {
                    return { label: year, value: year };
                  })}
                  placeholder="Select Year"
                  value={selectedYear}
                  onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      schedule: 'Yearly',
                      from: moment().year(Number(e)).tz('Asia/Dubai').startOf('year'),
                      to: moment().year(Number(e)).tz('Asia/Dubai').endOf('year'),
                    });
                    setSelectedYear(Number(e));
                    setSelectedMonth(null);
                  }}
                  className="select-container"
                />
                <Select
                  options={[
                    { value: 'All', label: 'All' },
                    ..._.map(months, (month) => {
                      return {
                        label: moment()
                          .month(month - 1)
                          .format('MMMM'),
                        value: month,
                      };
                    }),
                  ]}
                  value={selectedMonth ?? 'All'}
                  onChange={(e) => {
                    if (e === null || e === 'All') {
                      setFilterState({
                        ...filterState,
                        schedule: 'Yearly',
                        from: moment(filterState?.from).tz('Asia/Dubai').startOf('year'),
                        to: moment(filterState?.to).tz('Asia/Dubai').endOf('year'),
                      });
                    } else {
                      setFilterState({
                        ...filterState,
                        schedule: 'Monthly',
                        from: moment(filterState?.from)
                          .set({ month: Number(e) - 1 })
                          .tz('Asia/Dubai')
                          .startOf('month'),
                        to: moment(filterState?.to)
                          .set({ month: Number(e) - 1 })
                          .tz('Asia/Dubai')
                          .endOf('month'),
                      });
                    }
                    setSelectedMonth(e);
                    setSelectedWeek(null);
                  }}
                  className="select-container"
                  isClearable
                />
                {selectedMonth ? (
                  selectedMonth !== 'All' ? (
                    <Select
                      options={[
                        { value: 'All', label: 'All' },
                        ...weeks.map((w) => ({
                          value: w.value,
                          label: w.label,
                        })),
                      ]}
                      placeholder="Select Week"
                      value={selectedWeek ?? 'All'}
                      onChange={(e) => {
                        if (e === null || e === 'All') {
                          setFilterState({
                            ...filterState,
                            schedule: 'Monthly',
                            from: moment(filterState?.from)
                              .year(selectedYear)
                              .set({ month: selectedMonth - 1 })
                              .tz('Asia/Dubai')
                              .startOf('month'),
                            to: moment(filterState?.to)
                              .year(selectedYear)
                              .set({ month: selectedMonth - 1 })
                              .tz('Asia/Dubai')
                              .endOf('month'),
                          });
                        } else {
                          setFilterState({
                            ...filterState,
                            schedule: 'Weekly',
                            from: moment(weeks.find((w) => w.value === parseInt(e, 10))?.startDate).tz('Asia/Dubai', true),
                            to: moment(weeks.find((w) => w.value === parseInt(e, 10))?.endDate).tz('Asia/Dubai', true),
                          });
                        }
                        setSelectedWeek(e);
                      }}
                      className="select-weekly-container"
                      isClearable
                    />
                  ) : null
                ) : null}
              </div>
            </div>
            <div className="dashboard-amountCollected_chart-img">
              {isLoaded ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <div
                  className="amountCollected_chart-img"
                  style={{
                    flexGrow: '1',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: fullScreen ? 'calc(90vh - 100px)' : 'calc(70vh - 200px)',
                    // minHeight: '400px',
                    margin: fullScreen ? '0 1vw' : '0',
                  }}
                >
                  <Line
                    ref={lineChartRef}
                    data={amountCollected}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          position: 'bottom',
                          labels: {
                            boxWidth: 10,
                            color: '#ffffff',
                          },
                        },
                      },
                    }}
                    height="100%"
                  />
                </div>
              )}
            </div>
          </div>
        </FullScreenWrapper>
      </Card>
    </React.Fragment>
  );
};

AmountCollectedGraph.propTypes = {
  fullScreen: PropTypes.bool,
  setFullScreen: PropTypes.func,
  allstations: PropTypes.array,
  zones: PropTypes.array,
};

export default AmountCollectedGraph;
