import {
    GET_DASHBOARD_SESSION_COUNT,
    GET_DASHBOARD_SESSION_COUNT_SUCCESS,
    GET_DASHBOARD_SESSION_COUNT_FAIL,
  } from 'actions/dashboard';
  
  const initialState = {
    isLoading: false,
    dashboardSessionCount: {},
  };
  
  const dashboardSessionCountReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case GET_DASHBOARD_SESSION_COUNT: {
        return { ...state, isLoading: true };
      }
        
      case GET_DASHBOARD_SESSION_COUNT_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          dashboardSessionCount: payload,
        };
      }
  
      case GET_DASHBOARD_SESSION_COUNT_FAIL: {
        return { ...state, isLoading: false };
      }
  
      default:
        return { ...state };
    }
  };
  export default dashboardSessionCountReducer;
  