import {
  GET_DASHBOARD_WIDGET_DATA,
  GET_DASHBOARD_WIDGET_DATA_SUCCESS,
  GET_DASHBOARD_WIDGET_DATA_FAIL,
  GET_DASHBOARD_DAILY_WIDGET_DATA,
  GET_DASHBOARD_DAILY_WIDGET_DATA_SUCCESS,
  GET_DASHBOARD_DAILY_WIDGET_DATA_FAIL,
  GET_DASHBOARD_INCEPTION_WIDGET_DATA,
  GET_DASHBOARD_INCEPTION_WIDGET_DATA_SUCCESS,
  GET_DASHBOARD_INCEPTION_WIDGET_DATA_FAIL,
} from 'actions/dashboard';

const initialState = {
  isLoading: false,
  dashboardWidgets: {},
  dailyWidgets: {},
  inceptionWidgets: {},
};

const dashboardWidgetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DASHBOARD_WIDGET_DATA: {
      return { ...state, isLoading: true };
    }

    case GET_DASHBOARD_DAILY_WIDGET_DATA: {
      return { ...state, isLoading: true };
    }

    case GET_DASHBOARD_INCEPTION_WIDGET_DATA: {
      return { ...state, isLoading: true };
    }

    case GET_DASHBOARD_WIDGET_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dashboardWidgets: payload,
      };
    }

    case GET_DASHBOARD_DAILY_WIDGET_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        dailyWidgets: payload,
      };
    }

    case GET_DASHBOARD_INCEPTION_WIDGET_DATA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        inceptionWidgets: payload,
      };
    }

    case GET_DASHBOARD_WIDGET_DATA_FAIL:
    case GET_DASHBOARD_DAILY_WIDGET_DATA_FAIL:
    case GET_DASHBOARD_INCEPTION_WIDGET_DATA_FAIL: {
      return { ...state, isLoading: false };
    }

    default:
      return { ...state };
  }
};
export default dashboardWidgetReducer;
