export const FETCH_TARIFF = 'FETCH_TARIFF';
export const FETCH_TARIFF_SUCCESS = 'FETCH_TARIFF_SUCCESS';
export const FETCH_TARIFF_FAIL = 'FETCH_TARIFF_FAIL';

export const CREATE_TARIFF = 'CREATE_TARIFF';
export const CREATE_TARIFF_SUCCESS = 'CREATE_TARIFF_SUCCESS';
export const CREATE_TARIFF_FAIL = 'CREATE_TARIFF_FAIL';

export const DELETE_TARIFF = 'DELETE_TARIFF';
export const DELETE_TARIFF_SUCCESS = 'DELETE_TARIFF_SUCCESS';
export const DELETE_TARIFF_FAIL = 'DELETE_TARIFF_FAIL';

export const UPDATE_TARIFF = 'UPDATE_TARIFF';
export const UPDATE_TARIFF_SUCCESS = 'UPDATE_TARIFF_SUCCESS';
export const UPDATE_TARIFF_FAIL = 'UPDATE_TARIFF_FAIL';

export const GET_TARIFF = 'GET_TARIFF';
export const GET_TARIFF_SUCCESS = 'GET_TARIFF_SUCCESS';
export const GET_TARIFF_FAIL = 'GET_TARIFF_FAIL';

export const GET_CHARGER_TARIFF = 'GET_CHARGER_TARIFF';
export const GET_CHARGER_TARIFF_SUCCESS = 'GET_CHARGER_TARIFF_SUCCESS';
export const GET_CHARGER_TARIFF_FAIL = 'GET_CHARGER_TARIFF_FAIL';

export const DOWNLOAD_CHARGER_TARIFF = 'DOWNLOAD_CHARGER_TARIFF';
export const DOWNLOAD_CHARGER_TARIFF_SUCCESS = 'DOWNLOAD_CHARGER_TARIFF_SUCCESS';
export const DOWNLOAD_CHARGER_TARIFF_FAIL = 'DOWNLOAD_CHARGER_TARIFF_FAIL';

export const BULK_UPLOAD_CHARGER_TARIFF = 'BULK_UPLOAD_CHARGER_TARIFF';
export const BULK_UPLOAD_CHARGER_TARIFF_SUCCESS = 'BULK_UPLOAD_CHARGER_TARIFF_SUCCESS';
export const BULK_UPLOAD_CHARGER_TARIFF_FAIL = 'BULK_UPLOAD_CHARGER_TARIFF_FAIL';

export const UPDATED_TARIFF_LOG_LIST = 'UPDATED_TARIFF_LOG_LIST';
export const UPDATED_TARIFF_LOG_LIST_SUCCESS = 'UPDATED_TARIFF_LOG_LIST_SUCCESS';
export const UPDATED_TARIFF_LOG_LIST_FAIL = 'UPDATED_TARIFF_LOG_LIST_FAIL';

export const DOWNLOAD_UPDATED_TARIFF_LOG = 'DOWNLOAD_UPDATED_TARIFF_LOG';
export const DOWNLOAD_UPDATED_TARIFF_LOG_SUCCESS = 'DOWNLOAD_UPDATED_TARIFF_LOG_SUCCESS';
export const DOWNLOAD_UPDATED_TARIFF_LOG_FAIL = 'DOWNLOAD_UPDATED_TARIFF_LOG_FAIL';

export const FETCH_TARIFF_V2 = 'FETCH_TARIFF_V2';
export const FETCH_TARIFF_V2_SUCCESS = 'FETCH_TARIFF_V2_SUCCESS';
export const FETCH_TARIFF_V2_FAIL = 'FETCH_TARIFF_V2_FAIL';

export const FETCH_TARIFF_V3 = 'FETCH_TARIFF_V3';
export const FETCH_TARIFF_V3_SUCCESS = 'FETCH_TARIFF_V3_SUCCESS';
export const FETCH_TARIFF_V3_FAIL = 'FETCH_TARIFF_V3_FAIL';
