import React, { useEffect, useState, useCallback } from 'react';
import { Col, Row, Spinner, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
// import DatePickerWithFloatingLabel from 'components/inputs/DatePickerWithFloatingLabel';
import DateTimePicker from 'components/inputs/DateTimePicker';
import * as _ from 'lodash';
// import DropDown from 'components/inputs/DropDown';
// import { AiFillCaretDown } from 'react-icons/ai';
import SearchBox from 'components/general/SearchBox';
import { DOWNLOAD_FAILED_TRANSACTION_REPORT, FAILED_TRANSACTION_REPORT } from 'actions/dataReport';
import { useSelector, useDispatch } from 'react-redux';
// import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import moment from 'moment-timezone';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { IoSearchOutline } from 'react-icons/io5';
import { BsDownload } from 'react-icons/bs';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
// import { FETCH_COUNTRY, FETCH_STATE, GET_CITY_BY_STATE } from 'actions/address';
import { FETCH_CHARGING_STATION } from 'actions/chargingStation';
import { MdRefresh } from 'react-icons/md';
import { Form, Formik } from 'formik';
import Select from 'components/inputs/Select';
import TextInput from 'components/inputs/Input';
import OffCanvas from 'components/inputs/OffCanvas';
import ReactPagination from 'components/general/ReactPagination';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { FETCH_ZONES } from 'actions/zoneManagement';
import { BiPlus, BiTrash } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';

const FailedTransactionReport = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  // const [cityLimit, setCityLimit] = useState(false);
  const [startDate, setStartDate] = useState(moment(new Date()).tz('Asia/Dubai').subtract(1, 'day').startOf('day'));
  const [endDate, setEndDate] = useState(moment(new Date()).tz('Asia/Dubai').subtract(1, 'day').endOf('day'));
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [showTimeRangeModel, setShowTimeRangeModel] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  // const [changeState, setChangeState] = useState(false);
  // const [changeCity, setChangeCity] = useState(false);
  const [changeStation, setChangeStation] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  // const [country, setCountry] = useState('');
  // const [state, setState] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipContent, setTooltipContent] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [fields, setFields] = useState([
    { range: 5, condition: 'LessThan', type: 'mint' },
    { range: 0, condition: 'LessThan', type: 'unit' },
  ]);
  const searchOptions = [
    { label: 'Less Than or Equal To ≤', value: 'LessThan' },
    { label: 'Greater Than or Equal To ≥', value: 'GreaterThan' },
  ];
  const typeOptions = [
    { label: 'Unit Consumption', value: 'unit' },
    { label: 'Time Duration', value: 'mint' },
  ];
  const [selectedOption, setSelectedOption] = useState('excel');
  const reportFormats = [
    { label: 'PDF Format', value: 'pdf' },
    { label: 'Excel Sheet', value: 'excel' },
  ];

  const failedTransactionList = useSelector((state) => state.failedTransactionReport.failedTransactionReport);
  const isLoader = useSelector((state) => state.failedTransactionReport.isLoading);
  const page = useSelector((state) => state.failedTransactionReport.page);
  const totalData = useSelector((state) => state.failedTransactionReport.total);
  const limit = useSelector((state) => state.failedTransactionReport.limit);
  const totalPages = useSelector((state) => state.failedTransactionReport.totalPages);
  const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  const hasData = failedTransactionList && failedTransactionList.length > 0;

  const failedTransactionReport = useCallback(
    (value) => {
      if (searchText) {
        const failedTransactionData = {
          ...value,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          search: searchText,
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: FAILED_TRANSACTION_REPORT,
          payload: failedTransactionData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [startDate, endDate, searchText, fields]
  );

  const dateFilter = useCallback(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        filter: fields,
        status: ['completed'],
        charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    } else if (!_.isEmpty(advanceFilterData)) {
      if (
        advanceFilterData.charging_station === 'All' &&
        advanceFilterData.state === 'All' &&
        advanceFilterData.city === 'All' &&
        advanceFilterData.access_type === 'All'
      ) {
        const filterData = { ...advanceFilterData, charging_station: '', state: '', city: '', access_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', city: '', charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      } else if (advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, city: '', charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      } else if (advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '' };
        const data = {
          ...filterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      } else {
        const data = {
          ...advanceFilterData,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        filter: fields,
        status: ['completed'],
        charging_station: userProfileStations,
      };
      failedTransactionReport(filterDateData);
    } else {
      const filterDateData = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        filter: fields,
        status: ['completed'],
      };
      failedTransactionReport(filterDateData);
    }
  }, [startDate, endDate, sortByItem, advanceFilterData, fields, searchText]);

  useEffect(() => {
    if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        charging_station: userProfileStations,
      };
      failedTransactionReport(data);
    } else {
      failedTransactionReport();
    }
  }, []);

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page && startDate && endDate) {
        const data = {
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && failedTransactionReport(data);
      } else if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        failedTransactionReport(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        endDate && failedTransactionReport(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        failedTransactionReport(data);
      }
    },
    [startDate, endDate]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else if (!_.isEmpty(advanceFilterData)) {
        if (
          advanceFilterData.charging_station === 'All' &&
          advanceFilterData.state === 'All' &&
          advanceFilterData.city === 'All' &&
          advanceFilterData.access_type === 'All'
        ) {
          const filterData = { ...advanceFilterData, charging_station: '', state: '', city: '', access_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
            filter: fields,
            status: ['completed'],
          };
          failedTransactionReport(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, state: '', city: '', charging_station: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
            filter: fields,
            status: ['completed'],
          };
          failedTransactionReport(data);
        } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, access_type: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
            filter: fields,
            status: ['completed'],
          };
          failedTransactionReport(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, access_type: '', state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
            filter: fields,
            status: ['completed'],
          };
          failedTransactionReport(data);
        } else if (advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, access_type: '', city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
            filter: fields,
            status: ['completed'],
          };
          failedTransactionReport(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, state: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
            filter: fields,
            status: ['completed'],
          };
          failedTransactionReport(data);
        } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, city: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
            filter: fields,
            status: ['completed'],
          };
          failedTransactionReport(data);
        } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '', state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
            filter: fields,
            status: ['completed'],
          };
          failedTransactionReport(data);
        } else if (advanceFilterData.state === 'All') {
          const filterData = { ...advanceFilterData, state: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
            filter: fields,
            status: ['completed'],
          };
          failedTransactionReport(data);
        } else if (advanceFilterData.city === 'All') {
          const filterData = { ...advanceFilterData, city: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
            filter: fields,
            status: ['completed'],
          };
          failedTransactionReport(data);
        } else if (advanceFilterData.charging_station === 'All') {
          const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
            filter: fields,
            status: ['completed'],
          };
          failedTransactionReport(data);
        } else if (advanceFilterData.access_type === 'All') {
          const filterData = { ...advanceFilterData, access_type: '' };
          const data = {
            ...filterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
            filter: fields,
            status: ['completed'],
          };
          failedTransactionReport(data);
        } else {
          const data = {
            ...advanceFilterData,
            page: page.selected + 1,
            from: moment(startDate).tz('Asia/Dubai').utc(),
            to: moment(endDate).tz('Asia/Dubai').utc(),
            filter: fields,
            status: ['completed'],
          };
          failedTransactionReport(data);
        }
      } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const filterDateData = {
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
          charging_station: userProfileStations,
          page: page.selected + 1,
        };
        failedTransactionReport(filterDateData);
      } else if (startDate && endDate && searchText) {
        const data = {
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
          search: searchText,
          page: page.selected + 1,
        };
        failedTransactionReport(data);
      } else if (startDate && endDate) {
        const data = {
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
          page: page.selected + 1,
        };
        failedTransactionReport(data);
      } else {
        const data = {
          page: page.selected + 1,
          filter: fields,
          status: ['completed'],
        };
        failedTransactionReport(data);
      }
    },
    [sortByItem, startDate, endDate, advanceFilterData, searchText, fields]
  );

  // Download Excel Functionality

  const downloadfailedTransactionReport = useCallback(() => {
    if (!_.isEmpty(advanceFilterData)) {
      if (
        advanceFilterData.charging_station === 'All' &&
        advanceFilterData.state === 'All' &&
        advanceFilterData.city === 'All' &&
        advanceFilterData.access_type === 'All'
      ) {
        const filterData = {
          ...advanceFilterData,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
          state: '',
          city: '',
          access_type: '',
        };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = {
          ...advanceFilterData,
          state: '',
          city: '',
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', state: '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All' && advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '', city: '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, state: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All' && advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, city: '', charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All' && advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '', state: '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.state === 'All') {
        const filterData = { ...advanceFilterData, state: '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.city === 'All') {
        const filterData = { ...advanceFilterData, city: '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.charging_station === 'All') {
        const filterData = { ...advanceFilterData, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else if (advanceFilterData.access_type === 'All') {
        const filterData = { ...advanceFilterData, access_type: '' };
        const data = {
          ...filterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      } else {
        const data = {
          ...advanceFilterData,
          // excel: true,
          reportFormat: selectedOption,
          from: moment(startDate).tz('Asia/Dubai').utc(),
          to: moment(endDate).tz('Asia/Dubai').utc(),
          filter: fields,
          status: ['completed'],
        };
        dispatch({
          type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
          payload: data,
          cb: (res) => {
            if (res) {
              if (_.get(res, 'status') === 200) {
                fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
              }
            }
          },
        });
      }
    } else if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
      const data = {
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        filter: fields,
        status: ['completed'],
        charging_station: userProfileStations,
        // excel: true,
        reportFormat: selectedOption,
      };
      dispatch({
        type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
            }
          }
        },
      });
    } else if (searchText) {
      const data = {
        // excel: true,
        reportFormat: selectedOption,
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        filter: fields,
        status: ['completed'],
        search: searchText,
      };
      dispatch({
        type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
            }
          }
        },
      });
    } else {
      const data = {
        // excel: true,
        reportFormat: selectedOption,
        from: moment(startDate).tz('Asia/Dubai').utc(),
        to: moment(endDate).tz('Asia/Dubai').utc(),
        filter: fields,
        status: ['completed'],
      };
      dispatch({
        type: DOWNLOAD_FAILED_TRANSACTION_REPORT,
        payload: data,
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              fileDownload(res.data, `Failed Transaction Report.${data.reportFormat === 'excel' ? 'xlsx' : 'pdf'}`);
            }
          }
        },
      });
    }
  }, [startDate, endDate, advanceFilterData, fields, selectedOption, searchText]);

  const downloadFile = () => {
    downloadfailedTransactionReport();
  };
  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const initialFields = [
    { range: 5, condition: 'LessThan', type: 'mint' },
    { range: 0, condition: 'LessThan', type: 'unit' },
  ];

  const handleCloseModel = () => {
    setFields((prevFields) => {
      const validFields = prevFields.filter(
        (field) => field.type && field.range !== '' && !isNaN(parseFloat(field.range)) && field.condition
      );
  
      const restoredFields = initialFields.map((field) => {
        const existingField = validFields.find((f) => f.type === field.type);
        return existingField || field;
      });
  
      return restoredFields;
    });
  
    setShowTimeRangeModel(false);
  };

  const handleSubmit = () => {
    dateFilter();
  
    setFields((prevFields) =>
      prevFields.filter(
        (field) => field.type && field.range !== '' && !isNaN(parseFloat(field.range)) && field.condition
      )
    );
  
    setShowTimeRangeModel(false);
  };

  const search = <SearchBox preIcon={<BsSearch />} value={searchText} onChange={searchHandler} />;
  const searchIcon = <IoSearchOutline size={27} onClick={() => setShowSearchBar(true)} className="report-search-icon" />;
  // const allCountries = useSelector((state) => state.country.countries);
  // const allState = useSelector((state) => state.state.states);
  // const totalStateData = useSelector((state) => state.state.total);
  // const allCityByState = useSelector((state) => state.cityByState.cityByStates);
  // const totalCityData = useSelector((state) => state.cityByState.total);
  // const countryPage = useSelector((state) => state.country.page);
  // const statePage = useSelector((state) => state.state.page);
  // const cityByStatePage = useSelector((state) => state.cityByState.page);
  // const countryTotalPage = useSelector((state) => state.country.totalPages);
  // const stateTotalPage = useSelector((state) => state.state.totalPages);
  // const cityByStateTotalPage = useSelector((state) => state.cityByState.totalPages);
  const allStation = useSelector((state) => state.chargingStation.chargingStations);
  const allZones = useSelector((state) => state?.zoneManagement?.zones);
  const zonePage = useSelector((state) => state?.zoneManagement?.page);
  const zoneTotalPage = useSelector((state) => state?.zoneManagement?.totalPages);
  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);
  const stationList = allStation.filter((x) => userProfileStations.indexOf(x.id) !== -1);

  const initialValues = !_.isEmpty(advanceFilterData)
    ? { ...advanceFilterData }
    : { country: '', state: '', city: '', charging_station: '', access_type: '', zones: '' };

  // const getAllCountry = useCallback((data = {}) => {
  //   dispatch({ type: FETCH_COUNTRY, payload: data });
  // }, []);

  const getAllZones = useCallback((data = {}) => {
    dispatch({ type: FETCH_ZONES, payload: data });
  }, []);

  // const getStateByCountry = useCallback((data = {}) => {
  //   dispatch({ type: FETCH_STATE, payload: data });
  // }, []);

  // const getCityByStateName = useCallback((data = {}) => {
  //   dispatch({ type: GET_CITY_BY_STATE, payload: data });
  // }, []);

  const getAllStation = useCallback((data = {}) => {
    const stationData = {
      ...data,
      request_all: true,
    };
    dispatch({ type: FETCH_CHARGING_STATION, payload: stationData });
  }, []);

  // useEffect(() => {
  //   if (showAdvanceFilterPopup) {
  //     getStateByCountry({ country_name: country, limit: totalStateData });
  //   }
  //   if (cityLimit) {
  //     getCityByStateName({ state_names: state, limit: totalCityData });
  //   }
  // }, [country, state, totalStateData, totalCityData, cityLimit]);

  useEffect(() => {
    // getAllCountry();
    getAllStation();
    getAllZones({ limit: 999 });
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const tooltip = document.getElementsByClassName('more-button-tooltip')[0];
      if (tooltip && !tooltip.contains(event.target)) {
        setShowTooltip(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleTooltip = (content) => {
    setShowTooltip(!showTooltip);
    if (content) {
      setTooltipContent(content);
    }
  };

  const renderZoneNames = (infoTitle, remainingNumbers, tooltipId) => {
    return (
      <>
        {infoTitle}
        {remainingNumbers.length > 0 && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id={tooltipId} className="more-button-tooltip">
                {remainingNumbers.join(', ')}
              </Tooltip>
            }
            show={showTooltip && tooltipContent === tooltipId}
            onHide={() => toggleTooltip('')}
          >
            <span className="more-button" onClick={() => toggleTooltip(tooltipId)}>
              {'More'}
            </span>
          </OverlayTrigger>
        )}
      </>
    );
  };

  const addField = () => {
    setFields([...fields, { range: '', condition: null, type: null }]);
  };

  const removeField = (index) => {
    const updatedFields = fields.filter((_, i) => i !== index);
    setFields(updatedFields);
  };

  const handleFieldChange = (index, key, value) => {
    setFields((prevFields) =>
      prevFields.map((field, i) => {
        if (i === index) {
          return key === 'type' ? { ...field, type: value, condition: null } : { ...field, [key]: value };
        }
        return field;
      })
    );
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Failed Transaction Report" />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <div className="data-report--block">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col
                        xl={`${showSearchBar ? 'auto' : 'auto'}`}
                        md={`${showSearchBar ? 'auto' : 'auto'}`}
                        className={`${showSearchBar ? '' : 'data-report-search-box'}`}
                      >
                        {showSearchBar ? search : searchIcon}
                      </Col>
                      <Col xl={`${showSearchBar ? 'auto' : 'auto'}`} md={`${showSearchBar ? 'auto' : 'auto'}`}>
                        <div className="data-report-date_picker">
                          <DateTimePicker
                            onChangeOfStartDate={(item) => setStartDate(item)}
                            onChangeOfEndDate={(item) => setEndDate(item)}
                            initialValueOfStartDate={moment(new Date()).tz('Asia/Dubai').subtract(1, 'day').startOf('day')}
                            initialValueOfEndDate={moment(new Date()).tz('Asia/Dubai').subtract(1, 'day').endOf('day')}
                          />
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div
                          className="report-search-box"
                          onClick={() => {
                            // setSavedFields(fields);
                            setShowTimeRangeModel(true);
                          }}
                        >
                          <Button className="report-search-button">Enter Range</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-box" onClick={dateFilter}>
                          <Button className="report-search-button">Search</Button>
                        </div>
                      </Col>
                      <Col xl={'auto'} md={'auto'}>
                        <div className="report-search-excel-block">
                          <div className="advance-filter-btn" onClick={() => setShowAdvanceFilterPopup(true)}>
                            <Button className="report-search-button">Advance Filter</Button>
                          </div>
                        </div>
                      </Col>
                      <Col xl={2} md={'auto'}>
                        <Select
                          options={reportFormats}
                          value={selectedOption}
                          onChange={(value) => setSelectedOption(value)}
                          placeholder="Select Report Format"
                        />
                      </Col>
                      <Col xl={'auto'} md={'auto'} className="excel-icon--block" onClick={hasData ? downloadFile : null}>
                        {isLoading ? (
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                          </div>
                        ) : (
                          <div className={`report-excel-icon  ${!hasData ? 'disabled' : ''}`}>
                            <BsDownload title={!hasData ? '' : 'Download'} size={28} color={!hasData ? '#d3d3d3' : '#3c7cdd'} />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>Sr No.</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>PLU</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Station Name</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Station Code</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Transaction ID </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Maximum Capacity</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Unit Consumed (kWh)</span>
                                <span className="ico" onClick={() => handleSorting('units_consumed', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Booking Status</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Zones Name</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Start SOC</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Stop SOC</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Meter start</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Meter stop</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Total Charging Duration (HH:MM:SS)</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Start date time</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Stop date time</span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={14} className="border-0">
                                <div className="loader--block">
                                  <Spinner as="span" animation="border" size="sm" role="status" />
                                  <span className="ps-2">Loading...</span>
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(failedTransactionList) ? (
                            <tr>
                              <td colSpan={14} className="border-0">
                                <div className="empty-data-block">No Data Found</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(failedTransactionList, (item, index) => {
                              const serial_num = limit * (page - 1) + index;
                              const zones = _.get(item, 'zoneNames', '-');

                              let plu;
                              if (_.get(item, 'plu_1') && _.get(item, 'plu_2') === 0) {
                                plu = `${_.get(item, 'plu_1', '')}`;
                              } else if (_.get(item, 'plu_1') === '' && _.get(item, 'plu_2')) {
                                plu = `${_.get(item, 'plu_2', '')}`;
                              } else if (_.get(item, 'plu_1') && _.get(item, 'plu_2')) {
                                plu = `${_.get(item, 'plu_1', '')}, ${_.get(item, 'plu_2', '')}`;
                              } else if ((_.get(item, 'plu_1') === '' || _.get(item, 'plu_1') === 0) && _.get(item, 'plu_2') === 0) {
                                plu = '-';
                              } else {
                                plu = '-';
                              }

                              return (
                                <>
                                  <tr key={`station-unit-consumption-${serial_num}`}>
                                    <td>{serial_num + 1}</td>
                                    <td>{plu}</td>
                                    <td>{_.get(item, 'station_name', '-')}</td>
                                    <td>{_.get(item, 'station_code', '-')}</td>
                                    <td>{_.get(item, 'transaction_id', '-')}</td>
                                    <td>{_.get(item, 'maxChargingLimit', '-')}</td>
                                    <td>{_.get(item, 'units_consumed', '-')}</td>
                                    <td>{_.get(item, 'status', '-')}</td>
                                    <td>
                                      {!_.isEmpty(item?.zoneNames)
                                        ? renderZoneNames(_.head(zones), _.tail(zones), `reason-tooltip-${serial_num}`)
                                        : '-'}
                                    </td>
                                    <td>{_.get(item, 'StartSoC', '-')}</td>
                                    <td>{_.get(item, 'StopSoC', '-')}</td>
                                    <td>{_.get(item, 'meter_start', '')}</td>
                                    <td>{_.get(item, 'meter_stop', '')}</td>
                                    <td>{!_.isEmpty(_.get(item, 'total_duration')) ? _.get(item, 'total_duration') : '-'}</td>
                                    <td>
                                      {_.get(item, 'booking_start_date', '') && _.get(item, 'booking_start_time', '')
                                        ? `${_.get(item, 'booking_start_date', '')}  ${_.get(item, 'booking_start_time', '')}`
                                        : '-'}
                                    </td>
                                    <td>
                                      {_.get(item, 'booking_stop_date', '') && _.get(item, 'booking_stop_time', '')
                                        ? `${_.get(item, 'booking_stop_date', '')}  ${_.get(item, 'booking_stop_time', '')}`
                                        : '-'}
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(failedTransactionList) && (
                    <ReactPagination
                      currentPage={page}
                      limit={limit}
                      total={totalData}
                      handlePageClick={(pageVal) => handlePageClick(pageVal)}
                      totalPages={totalPages}
                      marginPagesDisplayed={1}
                    />
                  )}
                </div>
              </Card>
            </div>
          </div>
          {showTimeRangeModel && (
            <Modal show={showTimeRangeModel} size="xl" centered onHide={handleCloseModel}>
              <div className="wallet-transaction_main">
                <Card>
                  <div className="wallet-transaction-header__block">
                    <div className="wallet-transaction-name">
                      <span>{'Range'}</span>
                    </div>
                    <div className="close-btn">
                      <MdClose size={30} color="#be210b" onClick={handleCloseModel} />
                    </div>
                  </div>

                  {fields.map((field, index) => {
                    return (
                      <>
                        <Row key={index}>
                          <Col xl={4} md={12}>
                            <Select
                              placeholder="Select Type"
                              options={typeOptions}
                              value={field.type}
                              onChange={(e) => handleFieldChange(index, 'type', e)}
                            />
                          </Col>
                          <Col xl={3} md={12}>
                            <TextInput
                              placeholder="Enter Range"
                              type="number"
                              value={parseFloat(field.range)}
                              onChange={(e) => handleFieldChange(index, 'range', parseFloat(e.target.value))}
                            />
                          </Col>
                          <Col xl={4} md={12}>
                            <Select
                              placeholder="Select Condition"
                              options={searchOptions.filter((option) => {
                                if (option.value === field.condition) return true;

                                const selectedType = field.type;
                                if (!selectedType) return true;

                                const isAlreadyUsed = fields.some((f, i) => f.type === selectedType && f.condition === option.value && i !== index);

                                return !isAlreadyUsed;
                              })}
                              value={field.condition}
                              onChange={(e) => handleFieldChange(index, 'condition', e)}
                            />
                          </Col>
                          <Col xl={'auto'} md={'auto'}>
                            <Button className="field--btn" onClick={() => removeField(index)}>
                              <BiTrash size={26} />
                            </Button>
                          </Col>
                        </Row>
                      </>
                    );
                  })}
                  {fields.length !== 4 ? (
                    <Row>
                      <Col xl={2} md={2}>
                        <Button className="field--btn" onClick={addField}>
                          <BiPlus size={22} className="plus--icon" />
                          Add
                        </Button>
                      </Col>
                    </Row>
                  ) : null}
                  <Row>
                    <Col xl={12} md={12}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          onClick={() => {
                            const hasEmptyFields = fields.some(
                              (field) => !field.type || field.range === '' || isNaN(parseFloat(field.range)) || !field.condition
                            );

                            if (hasEmptyFields) {
                              toast.error('Please fill all the fields');
                              return;
                            }
                            handleSubmit();
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Modal>
          )}
        </div>
      </LayoutContainer>

      {/* Report Advance Filter Functionality */}

      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.country && delete values['country'];
              !values.state && delete values['state'];
              !values.city && delete values['city'];
              !values.charging_station && delete values['charging_station'];
              !values.zones && delete values['zones'];
              if (values.charging_station === 'All' && values.state === 'All' && values.city === 'All' && values.access_type === 'All') {
                const failedTransactionData = {
                  ...values,
                  state: '',
                  city: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                  access_type: '',
                };
                failedTransactionReport(failedTransactionData);
              } else if (values.charging_station === 'All' && values.state === 'All' && values.city === 'All') {
                const failedTransactionData = {
                  ...values,
                  state: '',
                  city: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                failedTransactionReport(failedTransactionData);
              } else if (values.charging_station === 'All' && values.access_type === 'All') {
                const failedTransactionData = {
                  ...values,
                  access_type: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                failedTransactionReport(failedTransactionData);
              } else if (values.state === 'All' && values.access_type === 'All') {
                const failedTransactionData = { ...values, access_type: '', state: '' };
                failedTransactionReport(failedTransactionData);
              } else if (values.city === 'All' && values.access_type === 'All') {
                const failedTransactionData = { ...values, access_type: '', city: '' };
                failedTransactionReport(failedTransactionData);
              } else if (values.state === 'All' && values.charging_station === 'All') {
                const failedTransactionData = {
                  ...values,
                  state: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                failedTransactionReport(failedTransactionData);
              } else if (values.city === 'All' && values.charging_station === 'All') {
                const failedTransactionData = {
                  ...values,
                  city: '',
                  charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
                };
                failedTransactionReport(failedTransactionData);
              } else if (values.state === 'All' && values.city === 'All') {
                const failedTransactionData = { ...values, state: '', city: '' };
                failedTransactionReport(failedTransactionData);
              } else if (values.city === 'All') {
                const failedTransactionData = { ...values, city: '' };
                failedTransactionReport(failedTransactionData);
              } else if (values.state === 'All') {
                const failedTransactionData = { ...values, state: '' };
                failedTransactionReport(failedTransactionData);
              } else if (values.charging_station === 'All') {
                const failedTransactionData = { ...values, charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '' };
                failedTransactionReport(failedTransactionData);
              } else if (values.access_type === 'All') {
                const failedTransactionData = { ...values, access_type: '' };
                failedTransactionReport(failedTransactionData);
              } else {
                failedTransactionReport(values);
              }
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  {/* <Select
                    label="Country"
                    options={_.map(allCountries, (item) => {
                      return { label: item.name, value: item.name };
                    })}
                    className="filter-select-box"
                    placeholder="Select Country"
                    name="country"
                    value={values.country}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllCountry}
                    page={countryPage}
                    totalPage={countryTotalPage}
                    onChange={(val) => {
                      getStateByCountry({ country_name: val });
                      setCountry(val);
                      setFieldValue(`country`, val);
                      // setFieldValue(`state`, '');
                      setFieldValue(`city`, '');
                    }}
                  /> */}
                  {/* <Select
                    label="State"
                    options={
                      !changeState
                        ? values.country &&
                        !_.isEmpty(allState) && [
                          { label: 'All', value: 'All' },
                          ..._.map(allState, (state) => {
                            return { label: state.name, value: state.name };
                          }),
                        ]
                        : values.country && !_.isEmpty(allState) && [{ label: 'All', value: 'All' }]
                    }
                    className="filter-select-box"
                    placeholder="Select State"
                    isMulti
                    name="state"
                    value={values.state}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getStateByCountry({ ...data, country_name: values.country })}
                    page={statePage}
                    totalPage={stateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        getCityByStateName({ state_names: '' });
                        setChangeState(true);
                        setFieldValue(`state`, 'All');
                      } else if (_.isEmpty(val)) {
                        setChangeState(false);
                        setChangeCity(false);
                        setFieldValue(`state`, '');
                      } else {
                        getCityByStateName({ state_names: val });
                        setState(val);
                        setCityLimit(true);
                        setChangeState(false);
                        setChangeCity(false);
                        setFieldValue(`state`, val);
                      }
                      setFieldValue(`city`, '');
                    }}
                  /> */}
                  {/* <Select
                    label="City"
                    options={
                      !changeCity
                        ? !_.isEmpty(values.state) &&
                        !_.isEmpty(allCityByState) && [
                          { label: 'All', value: 'All' },
                          ..._.map(allCityByState, (state) => {
                            return { label: state.name, value: state.name };
                          }),
                        ]
                        : !_.isEmpty(values.state) && values.country && !_.isEmpty(allCityByState) && [{ label: 'All', value: 'All' }]
                    }
                    placeholder="Select City"
                    name="city"
                    isMulti
                    value={values.city}
                    onMenuScrollDown={true}
                    // getDataOnScrollDown={(data) => getCityByStateName({ ...data, state_names: values.state === 'All' ? '' : values.state })}
                    page={cityByStatePage}
                    totalPage={cityByStateTotalPage}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        setChangeCity(true);
                        setFieldValue(`city`, 'All');
                      } else if (_.isEmpty(val)) {
                        setFieldValue(`city`, '');
                        setChangeCity(false);
                      } else {
                        setChangeCity(false);
                        setFieldValue('city', val);
                      }
                    }}
                  /> */}
                  <Select
                    label="Station"
                    options={
                      !_.isEmpty(stationList)
                        ? !changeStation
                          ? !_.isEmpty(stationList) && [
                              { label: 'All', value: 'All' },
                              ..._.map(stationList, (station) => {
                                return { label: station.name, value: station.id };
                              }),
                            ]
                          : !_.isEmpty(stationList) && [{ label: 'All', value: 'All' }]
                        : !changeStation
                        ? !_.isEmpty(allStation) && [
                            { label: 'All', value: 'All' },
                            ..._.map(allStation, (station) => {
                              return { label: station.name, value: station.id };
                            }),
                          ]
                        : !_.isEmpty(allStation) && [{ label: 'All', value: 'All' }]
                    }
                    placeholder="Select Station"
                    name="charging_station"
                    isMulti
                    value={values.charging_station}
                    onMenuScrollDown={true}
                    onChange={(val) => {
                      if (_.includes(val, 'All')) {
                        setChangeStation(true);
                        setFieldValue(`charging_station`, 'All');
                      } else if (_.isEmpty(val)) {
                        setFieldValue(`charging_station`, '');
                        setChangeStation(false);
                      } else {
                        setChangeStation(false);
                        setFieldValue('charging_station', val);
                      }
                    }}
                  />
                  <Select
                    label="Zones"
                    isMulti
                    options={_.map(allZones, (item) => {
                      return { label: item.name, value: item.id };
                    })}
                    className="filter-select-box"
                    placeholder="Select Zones"
                    name="zones"
                    value={values.zones}
                    onMenuScrollDown={true}
                    getDataOnScrollDown={getAllZones}
                    page={zonePage}
                    totalPage={zoneTotalPage}
                    onChange={(val) => {
                      setFieldValue(`zones`, val);
                    }}
                  />
                  {/* <div className="canvas-selection__block">
                    <Select
                      label="Station Type"
                      options={
                        !changeStationType
                          ? !_.isEmpty(allStationType) && [
                              { label: 'All', value: 'All' },
                              ..._.map(allStationType, (item) => {
                                return { label: item.label, value: item.value };
                              }),
                            ]
                          : !_.isEmpty(allStationType) && [{ label: 'All', value: 'All' }]
                      }
                      placeholder="Select Station Type"
                      name="access_type"
                      isMulti
                      value={values.access_type}
                      onMenuScrollDown={true}
                      onChange={(val) => {
                        if (_.includes(val, 'All')) {
                          setChangeStationType(true);
                          setFieldValue(`access_type`, 'All');
                        } else if (_.isEmpty(val)) {
                          setFieldValue(`access_type`, '');
                          setChangeStationType(false);
                        } else {
                          setChangeStationType(false);
                          setFieldValue('access_type', val);
                        }
                      }}
                    />
                  </div> */}
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    Apply Filters
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      failedTransactionReport();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdRefresh size={24} className="reset--icon" />
                    Reset
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default FailedTransactionReport;
