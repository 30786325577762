import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import { cookie } from 'utils/cookies/cookies';
import { apiService, getHeaders, url } from 'utils/api';
import { getFCMToken } from '../../firebase';

const Loginas = (props) => {
  const { showLoader } = props;

  useEffect(() => {
    const { token, role, refreshtoken } = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    token && cookie.set('token', token);
    role && cookie.set('user-role', role);
    refreshtoken && cookie.set('refreshToken', refreshtoken);

    sendFCMTokenToServer();
  }, []);

  const openPage = () => {
    if (cookie.get('user-role') === 'super_admin') {
      setTimeout(() => {
        window.location.href = '/tenantAdmin';
      }, 1000);
    } else if (cookie.get('user-role') === 'support') {
      setTimeout(() => {
        window.location.href = '/chargerStatus';
      }, 1000);
    } else {
      setTimeout(() => {
        window.location.href = '/dashboard';
      }, 1000);
    }
  };

  const sendFCMTokenToServer = async () => {
    try {
      const fcmToken = await getFCMToken();
      if (fcmToken) {
        const params = { web_token: fcmToken };
        try {
          await apiService.post(url('/util/savetoken', params), params, {
            headers: getHeaders(true),
          });
          //console.log('FCM Token Saved');
        } catch (error) {
          //console.log('Getting error while saving fcmToken');
        } finally {
          openPage();
        }
      } else {
        //console.log('No registration token available. Request permission to generate one.');
      }
    } catch (error) {
      //console.log('Getting error while retrieving fcmToken');
      openPage();
    }
  };

  return (
    <>
      {showLoader ? (
        <div className="profile-loader--block">
          <div className="spinner-border text-danger" />
        </div>
      ) : (
        <div>Loadding....</div>
      )}
    </>
  );
};
Loginas.propTypes = {
  location: PropTypes.object,
  showLoader: PropTypes.bool,
};
export default Loginas;
