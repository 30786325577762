import {
    GET_DASHBOARD_CHARGER_AVAILABILITY,
    GET_DASHBOARD_CHARGER_AVAILABILITY_SUCCESS,
    GET_DASHBOARD_CHARGER_AVAILABILITY_FAIL,
  } from 'actions/dashboard';
  
  const initialState = {
    isLoading: false,
    dashboardChargerAvailability: {},
  };
  
  const dashboardChargerAvailabilityReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case GET_DASHBOARD_CHARGER_AVAILABILITY: {
        return { ...state, isLoading: true };
      }
        
      case GET_DASHBOARD_CHARGER_AVAILABILITY_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          dashboardChargerAvailability: payload,
        };
      }
  
      case GET_DASHBOARD_CHARGER_AVAILABILITY_FAIL: {
        return { ...state, isLoading: false };
      }
  
      default:
        return { ...state };
    }
  };
  export default dashboardChargerAvailabilityReducer;
  