import {
    GET_DASHBOARD_ENERGY_SOLD,
    GET_DASHBOARD_ENERGY_SOLD_SUCCESS,
    GET_DASHBOARD_ENERGY_SOLD_FAIL,
  } from 'actions/dashboard';
  
  const initialState = {
    isLoading: false,
    dashboardEnergySold: {},
  };
  
  const dashboardEnergySoldReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case GET_DASHBOARD_ENERGY_SOLD: {
        return { ...state, isLoading: true };
      }
        
      case GET_DASHBOARD_ENERGY_SOLD_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          dashboardEnergySold: payload,
        };
      }
  
      case GET_DASHBOARD_ENERGY_SOLD_FAIL: {
        return { ...state, isLoading: false };
      }
  
      default:
        return { ...state };
    }
  };
  export default dashboardEnergySoldReducer;
  