import {
    UPDATED_TARIFF_LOG_LIST,
    UPDATED_TARIFF_LOG_LIST_SUCCESS,
    UPDATED_TARIFF_LOG_LIST_FAIL,
  } from 'actions/tariff';
  
  const initialState = {
    isLoading: false,
    updatedTariff: {},
  };
  
  const updatedTariffReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case UPDATED_TARIFF_LOG_LIST: {
        return { ...state, isLoading: true };
      }
  
      case UPDATED_TARIFF_LOG_LIST_SUCCESS: {
          return {
            ...state,
            isLoading: false,
            updatedTariff: payload,
          };
      }
      case UPDATED_TARIFF_LOG_LIST_FAIL: {
        return { ...state, isLoading: false };
      }
      default:
        return { ...state };
    }
  };
  export default updatedTariffReducer;
  