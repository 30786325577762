import React, { useRef, useEffect } from 'react';
import { MdFileDownload, MdClose } from 'react-icons/md';
import { ImEnlarge } from 'react-icons/im';
import PropTypes from 'prop-types';

const FullScreenWrapper = ({ children, isFullScreen, setIsFullScreen, downloadCharts = () => {} }) => {
  const chartRef = useRef(null);

  const enterFullScreen = () => {
    if (chartRef.current) {
      if (chartRef.current.requestFullscreen) {
        chartRef.current.requestFullscreen();
      } else if (chartRef.current.webkitRequestFullscreen) {
        chartRef.current.webkitRequestFullscreen();
      } else if (chartRef.current.msRequestFullscreen) {
        chartRef.current.msRequestFullscreen();
      }
      setIsFullScreen(true);
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
    setIsFullScreen(false);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        exitFullScreen();
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    document.addEventListener('fullscreenchange', () => setIsFullScreen(!!document.fullscreenElement));

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div
      ref={chartRef}
      style={{
        position: 'relative',
        width: '100%',
        height: isFullScreen ? '100vh' : 'auto',
        backgroundColor: isFullScreen ? '#1b2a38' : 'transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 999,
          gap: '10px',
          display: 'flex',
        }}
      >
        <MdFileDownload onClick={downloadCharts} style={{ cursor: 'pointer' }} color="#3c7cdd" size={25} />
        {!isFullScreen ? (
          <ImEnlarge onClick={enterFullScreen} style={{ cursor: 'pointer' }} size={25} />
        ) : (
          <MdClose onClick={exitFullScreen} style={{ cursor: 'pointer' }} size={25} />
        )}
      </div>
      <div style={{ width: '100%', height: isFullScreen ? '100%' : 'auto' }}>{children}</div>
    </div>
  );
};

FullScreenWrapper.propTypes = {
  children: PropTypes.node,
  isFullScreen: PropTypes.bool,
  setIsFullScreen: PropTypes.func,
  downloadCharts: PropTypes.func,
};

export default FullScreenWrapper;
