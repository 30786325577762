import React, { useState, useEffect, useCallback } from 'react';
import LayoutContainer from 'components/layout/LayoutContainer';
import AdminHeader from 'components/header';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { FETCH_REPORT_DOWNLOAD_REQUEST, GET_SIGNED_URL } from 'actions/downloadAdvanceReport';
import { BiSort } from 'react-icons/bi';
import { Col, Row, Modal, Spinner } from 'react-bootstrap';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import ReactPagination from 'components/general/ReactPagination';
import DownloadReportForm from './DownloadReportForm';
import moment from 'moment-timezone';
import { BiLoaderAlt, BiError } from 'react-icons/bi';
import { MdOutlineDownloading } from 'react-icons/md';
import { LuRefreshCw } from 'react-icons/lu';
import { toast } from 'react-toastify';

const DownloadAdvanceReport = () => {
  const dispatch = useDispatch();
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [ShowForm, setShowForm] = useState(false);

  const reportDownloadRequestList = useSelector((state) => state?.reportDownloadRequest?.reportDownloadRequest);
  const isLoading = useSelector((state) => state?.reportDownloadRequest?.isLoading);
  const page = useSelector((state) => state?.reportDownloadRequest?.page);
  const totalData = useSelector((state) => state?.reportDownloadRequest?.total);
  const limit = useSelector((state) => state?.reportDownloadRequest?.limit);
  const totalPages = useSelector((state) => state?.reportDownloadRequest?.totalPages);

  const fetchReportDownloadRequest = useCallback((data) => {
    dispatch({
      type: FETCH_REPORT_DOWNLOAD_REQUEST,
      payload: data,
    });
  }, []);

  const getSortByItem = useCallback((name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      fetchReportDownloadRequest(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      fetchReportDownloadRequest(data);
    }
  }, []);

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        fetchReportDownloadRequest(data);
      }
    },
    [sortByItem]
  );

  useEffect(() => {
    fetchReportDownloadRequest();
  }, []);

  const handleClose = () => {
    setShowForm(false);
  };

  const handleDownload = useCallback(
    (id) => {
      dispatch({
        type: GET_SIGNED_URL,
        payload: id,
        cb: (res) => {
          if (_.get(res, 'status') === 200) {
            toast.success('Download Completed');
            const url = _.get(res, 'data.presignedUrl', '');
            if (url) {
              window.open(url, '_self');
            } else {
              toast.success('URL Not Found');
            }
          }
        },
      });
    },
    [dispatch]
  );

  const [isSpinning, setIsSpinning] = useState(false);

  const handleRefresh = () => {
    setIsSpinning(true);

    setTimeout(() => {
      setIsSpinning(false);
      fetchReportDownloadRequest({});
    }, 500);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={'Download Advance Report'} />
        <div className="page-content-wrapper scrollable">
          <div className="data-report--main">
            <Card>
              <div className="data-report__inner">
                <div className="data-report__box">
                  <Row className="data-report__row">
                    <Col xl={10} lg={10} md={10}>
                      <div style={{ display: 'flex', margin: '1rem', fontSize: '1.1rem', fontWeight: 'bold' }}>{'Downloaded Reports'}</div>
                    </Col>
                    <Col xl={'auto'} lg={'auto'} md={'auto'}>
                      <div
                        style={{
                          border: 'none',
                          cursor: 'pointer',
                          borderRadius: '15px',
                          padding: '0.75rem 1rem',
                          fontSize: '0.9rem',
                        }}
                        onClick={() => setShowForm(true)}
                      >
                        <Button className="report-search-button">
                          <span>{'Download Report'}</span>
                        </Button>
                      </div>
                    </Col>
                    <Col xl={'auto'} lg={'auto'} md={'auto'} className="excel-icon--block">
                      <div
                        style={{
                          border: 'none',
                          cursor: 'pointer',
                          borderRadius: '15px',
                          padding: '0.8rem',
                          fontSize: '0.9rem',
                        }}
                      >
                        <LuRefreshCw size={28} color={'#3c7cdd'} className={`${isSpinning ? 'spin' : ''} reload-icon`} onClick={handleRefresh} />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="data-report__table">
                  <div className="table-responsive">
                    <table className="record-list-table" id="table-to-xls">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>{'Sr.no'}</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{'Name'}</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{'Duration From'}</span>
                              <span className="ico" onClick={() => handleSorting('filter.from', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{'Duration To'}</span>
                              <span className="ico" onClick={() => handleSorting('filter.to', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>{'Status'}</span>
                              <span className="ico" onClick={() => handleSorting('status', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan={9} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(reportDownloadRequestList) ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="empty-data-block">{'No Downloaded Reports Found'}</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoading || _.isUndefined(isLoading)) &&
                          _.map(reportDownloadRequestList, (item, index) => {
                            const serial_num = limit * (page - 1) + index;
                            return (
                              <tr key={`advance-report-item-${serial_num}`}>
                                <td>{serial_num + 1}</td>
                                <td>{_.get(item, 'name', '-')}</td>

                                <td>
                                  {_.get(item, 'filter.from')
                                    ? moment(_.get(item, 'filter.from')).tz('Asia/Dubai').format('DD/MM/YYYY HH:mm:ss')
                                    : '-'}
                                </td>

                                <td>
                                  {_.get(item, 'filter.to') ? moment(_.get(item, 'filter.to')).tz('Asia/Dubai').format('DD/MM/YYYY HH:mm:ss') : '-'}
                                </td>
                                <td>
                                  <>
                                    {_.get(item, 'status') === 'Pending' ? (
                                      <BiLoaderAlt size={28} style={{ animation: 'spin 1s linear infinite' }} color={'#109b72'} />
                                    ) : _.get(item, 'status') === 'Success' ? (
                                      <MdOutlineDownloading
                                        size={28}
                                        color={'#109b72'}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleDownload(_.get(item, 'id'))}
                                      />
                                    ) : (
                                      <BiError size={28} color={'#FFDF00'} title={_.get(item, 'reason')} />
                                    )}
                                  </>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoading || _.isUndefined(isLoading)) && !_.isEmpty(reportDownloadRequestList) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}

                <Modal show={ShowForm} size="lg" centered>
                  <DownloadReportForm onClose={handleClose} downloadReport={fetchReportDownloadRequest} />
                </Modal>
              </div>
            </Card>
          </div>
        </div>
      </LayoutContainer>
    </>
  );
};

export default DownloadAdvanceReport;
