import React from 'react';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import style from '../../assets/style';
import ErrorMessage from './ErrorMessage';
import MenuArrow from 'components/general/MenuArrow';
import { components } from 'react-select';
import { default as ReactSelect } from 'react-select';
import { Form } from 'react-bootstrap';
import * as _ from 'lodash';

const getOption = (value, options, isMulti) => {
  if (isMulti) {
    return options.filter((option) => value.includes(option.value) || value.includes(option.id));
  } else {
    return options.find((option) => option.value === value || option.id === value);
  }
};

const getSelectedValue = (isAsync, isMulti, options, value) => {
  if (isAsync) {
    if (value && Object.keys(value).length) {
      if (value) return value;
      else return null;
    } else {
      return null;
    }
  } else if (value) {
    return getOption(value, options, isMulti);
  } else {
    return null;
  }
};

const CustomSelect = ({
  width,
  size,
  value,
  options = [],
  onChange,
  style,
  placeholder,
  isMulti,
  className,
  label,
  labelSmall,
  selectClassName,
  isAsync,
  isCreatable,
  onCreateOption,
  getDataOnScrollDown,
  page,
  totalPage,
  onMenuScrollDown,
  horizontal,
  loadOptions,
  getOptionLabel,
  getOptionValue,
  isClearable,
  isDisabled,
  isRequired,
  error,
  ...rest
}) => {
  const selected = getSelectedValue(isAsync, isMulti, options, value);
  const Tag = isAsync ? AsyncSelect : isCreatable ? CreatableSelect : ReactSelect;
  const { Option, SingleValue, MultiValue } = components;

  const CustomSingleValue = (props) => (
    <SingleValue {...props}>
      {props.data.icon && <span>{props.data.icon}</span>}
      <span className={'ml-2'}>{props.data.label}</span>
    </SingleValue>
  );

  const CustomMultiValue = (props) => (
    <MultiValue {...props}>
      {props.data.icon && <span>{props.data.icon}</span>}
      <span className={'ml-2'}>{props.data.label}</span>
    </MultiValue>
  );

  const IconOption = (props) => (
    <Option {...props}>
      {props.data.icon && <span>{props.data.icon}</span>}
      <span className={'ml-2'}>{props.data.label}</span>
    </Option>
  );

  const handleScrolling = (e) => {
    //scrolling functionality for react-Select
    const scrollHeight = e.target.scrollHeight;
    const clientHeight = e.target.clientHeight;
    const currentScrollY = e.target.scrollTop;

    if (e && scrollHeight - clientHeight === currentScrollY && page < totalPage) {
      const data = {
        page: page + 1,
      };
      getDataOnScrollDown(data);
    }
  };

  return (
    <>
      <Form.Group
        className={`select-input-form-group ${className ? className : ''} ${horizontal ? 'form-group--horizontal' : ''} 
        ${isDisabled ? 'disabled' : ''}`}
      >
        {label && (
          <Form.Label className="form-select-label">
            {label}
            {isRequired && <span className="require--star">*</span>}
            {labelSmall && <small>{labelSmall}</small>}
          </Form.Label>
        )}
        <Tag
          size={size}
          value={selected}
          options={_.sortBy(options, ['label'])}
          onMenuScrollToBottom={onMenuScrollDown ? (e) => handleScrolling(e) : () => {}}
          onChange={(opt) => {
            const getValue = (opt) => (opt ? (isAsync ? opt : opt.value ? opt.value : opt.id) : null);
            const value = isMulti ? opt && opt.map(getValue) : getValue(opt);
            onChange(value);
          }}
          styles={styles(width, size)}
          components={{ DropdownIndicator, Option: IconOption, SingleValue: CustomSingleValue, MultiValue: CustomMultiValue }}
          style={style}
          placeholder={placeholder}
          className={`form-field react-select ${selectClassName ? selectClassName : ''} ${isDisabled ? 'disabled' : ''}`}
          classNamePrefix={`react-select`}
          isMulti={isMulti ? isMulti : false}
          loadOptions={isAsync ? loadOptions : null}
          getOptionLabel={getOptionLabel ? getOptionLabel : (option) => option.label}
          getOptionValue={getOptionValue ? getOptionValue : (option) => option.value}
          isClearable={isClearable}
          isDisabled={isDisabled ? isDisabled : false}
          onCreateOption={isCreatable && onCreateOption ? onCreateOption : null}
          // menuIsOpen={true}
          {...rest}
        />

        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Form.Group>
    </>
  );
};

CustomSelect.propTypes = {
  width: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.string,
  /**
   * value:
   * it contains the select-box values.
   */
  options: PropTypes.array,
  getDataOnScrollDown: PropTypes.func,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  onMenuScrollDown: PropTypes.bool,
  /**
   * options:
   * It contains all the option related to selected component.
   *
   */
  onChange: PropTypes.func,
  /**
   * onChange:
   * Do: Set the value of the component.
   */
  style: PropTypes.object,
  /**
   * style:
   * Style of selected component.
   */
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  labelSmall: PropTypes.string,
  selectClassName: PropTypes.string,
  isAsync: PropTypes.bool,
  isCreatable: PropTypes.bool,
  onCreateOption: PropTypes.func,
  horizontal: PropTypes.bool,
  loadOptions: PropTypes.func,
  getOptionLabel: PropTypes.func,
  /**
   * getOptionLabel:
   * Get the label for the components.
   */
  getOptionValue: PropTypes.func,
  /**
   * getOptionValue:
   * Get the options for the components.
   */
  isRequired: PropTypes.bool,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  data: PropTypes.shape({
    icon: PropTypes.node,
    label: PropTypes.string,
  }),
};
export default CustomSelect;

export const styles = (width, size) => ({
  container: (base) => ({
    ...base,
    width: width || '100%',
    color: style.color.font,
  }),
  control: () => ({
    display: 'flex',
    borderRadius: 1,
    minHeight: '2rem',
    flexWrap: 'wrap',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: size === 'large' ? 12 : 10,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (base) => ({
    ...base,
    margin: '2px',
  }),
  multiValueLabel: (base) => ({
    ...base,
    maxWidth: '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }),
  multiValueRemove: (base) => ({
    ...base,
    cursor: 'pointer',
  }),
  valueContainer: (base) => ({
    ...base,
    maxHeight: '50px',
    overflowY: 'auto', 
    padding: '2px',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  }),
});

export const DropdownIndicator = (props) => {
  const { menuIsOpen, size } = props.selectProps;
  return (
    components.DropdownIndicator && (
      <div className={menuIsOpen ? 'menu-is-open' : ''}>
        <components.DropdownIndicator {...props}>
          <MenuArrow open={menuIsOpen} size={size === 'large' ? 24 : 20} />
        </components.DropdownIndicator>
      </div>
    )
  );
};
DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool,
    size: PropTypes.string,
  }),
};
