import React, { useState, useEffect, useCallback } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import * as _ from 'lodash';
import { FiEdit2 } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/inputs/Card';
import { Modal, Spinner } from 'react-bootstrap';
import { FETCH_TARIFF, GET_TARIFF } from 'actions/tariff';
// import Pagination from 'components/general/Pagination';
import ReactPagination from 'components/general/ReactPagination';
import { DELETE_TARIFF } from 'actions/tariff';
import DeleteModal from 'components/general/DeleteModal';
import { CPO_MANAGER, DELETE_STATUS_CODE } from 'components/common/constant';
import Button from 'components/inputs/Button';
import { BiSort } from 'react-icons/bi';
import { useHistory } from 'react-router';
import { BsEye } from 'react-icons/bs';
import SingleTariff from './SingleTariff';
// import moment from 'moment';

const TariffList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const allTariffList = useSelector((state) => state.tariff.tariff);
  const isLoader = useSelector((state) => state.tariff.isLoading);
  const page = useSelector((state) => state.tariff.page);
  const totalData = useSelector((state) => state.tariff.total);
  const limit = useSelector((state) => state.tariff.limit);
  const totalPages = useSelector((state) => state.tariff.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserRole = _.get(profileData, 'role', '');
  const [tariffId, setTariffId] = useState('');
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [pageWiseTariffData, setPageWiseTariffData] = useState([]);

  const tariffData = allTariffList.filter(({ id: id1 }) => pageWiseTariffData.some(({ id: id2 }) => id2 === id1));

  const deleteTariff = useCallback(
    () =>
      dispatch({
        type: DELETE_TARIFF,
        payload: tariffId,
        cb: (res) => {
          if (_.get(res, 'status', '') === DELETE_STATUS_CODE) {
            handleCloseDeleteModel();
            getTariff({ page });
          }
        },
      }),
    [tariffId]
  );

  const getTariff = useCallback(
    (data = {}) => {
      dispatch({
        type: FETCH_TARIFF,
        payload: data ? data : {},
        cb: (res) => {
          if (res) {
            if (_.get(res, 'status') === 200) {
              setPageWiseTariffData(res.data.results);
            }
          }
        },
      });
    },
    [pageWiseTariffData, allTariffList]
  );

  const getTariffList = useCallback((id) => {
    dispatch({ type: GET_TARIFF, payload: id });
  }, []);

  useEffect(() => {
    getTariff();
  }, []);

  const handlePageClick = useCallback(
    (page) => {
      if (sortByItem.item && sortByItem.order) {
        getSortByItem(sortByItem.item, sortByItem.order, page);
      } else {
        const data = {
          page: page.selected + 1,
        };
        getTariff(data);
      }
    },
    [sortByItem]
  );

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getTariff(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getTariff(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const handleCloseDeleteModel = () => {
    setShowDeleteModal(false);
    setTariffId('');
  };

  const handleCloseTariff = () => {
    setShowModal(false);
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Tariff List" />
        <div className="page-content-wrapper scrollable">
          <div className="existing-pricing__main">
            <div className="existing-pricing--block">
              <Card>
                <div className="pricing-block-content">
                  <div className="pricing-block-text">Existing Pricing Models</div>
                  {currentUserRole !== CPO_MANAGER ? (
                    <div className="add-new-price" onClick={() => history.push(`/addTariff`)}>
                      <Button>Add Pricing Model</Button>
                    </div>
                  ) : null}
                </div>
                <div className="pricing-list-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr>
                          <th>
                            <div className="sorting">
                              <span>Name</span>
                              <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Version</span>
                            </div>
                          </th>
                            <th className="action-col">
                              <span>Action</span>
                            </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(tariffData) ? (
                          <tr key={`tariff-empty-row`}>
                            <td colSpan={5}>
                              <div className="empty-data-block">No tariff data</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(tariffData, (tariff, index) => {
                            return (
                              <tr key={`tariff-${index}`}>
                                <td>{_.get(tariff, 'name')}</td>
                                <td>{_.get(tariff, 'version', '-')}</td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    {currentUserRole !== CPO_MANAGER ? (
                                      <>
                                        <div className="edit-icon" onClick={() => history.push(`/editTariff/${tariff.id}`)}>
                                          <FiEdit2 />
                                        </div>
                                        <div
                                          className="delete-icon"
                                          onClick={() => {
                                            setShowDeleteModal(true);
                                            setTariffId(_.get(tariff, 'id', ''));
                                          }}
                                        >
                                          <RiDeleteBinLine />
                                        </div>
                                      </>
                                    ) : null}
                                    <span
                                      className="eye-icon"
                                      onClick={() => {
                                        setShowModal(true);
                                        getTariffList(_.get(tariff, 'id', ''));
                                      }}
                                    >
                                      <BsEye size={16} />
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(tariffData) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}
              </Card>
            </div>
          </div>
        </div>

        {showModal && (
          <Modal show={showModal} size="lg" centered className="scrollable">
            <SingleTariff onClose={handleCloseTariff} />
          </Modal>
        )}

        {showDeleteModal && (
          <Modal show={showDeleteModal} centered onHide={handleCloseDeleteModel}>
            <DeleteModal title="tariff" onClose={handleCloseDeleteModel} onRemove={deleteTariff} />
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};

export default TariffList;
