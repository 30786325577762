import React, { useState, useEffect, useCallback, useRef } from 'react';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Card from 'components/inputs/Card';
import { Modal, Spinner } from 'react-bootstrap';
import {
  GET_CHARGER_TARIFF,
  DOWNLOAD_CHARGER_TARIFF,
  BULK_UPLOAD_CHARGER_TARIFF,
  UPDATED_TARIFF_LOG_LIST,
  DOWNLOAD_UPDATED_TARIFF_LOG,
} from 'actions/tariff';
import ReactPagination from 'components/general/ReactPagination';
import Button from 'components/inputs/Button';
import { BiSort } from 'react-icons/bi';
import fileDownload from 'js-file-download';
import { FiUpload } from 'react-icons/fi';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { PiMicrosoftExcelLogo } from 'react-icons/pi';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import { MdClose } from 'react-icons/md';
import { BsDownload } from 'react-icons/bs';
import { CPO_MANAGER } from 'components/common/constant';

const TariffOperations = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const chargerTariffList = useSelector((state) => state?.chargerTariff?.chargerTariff);
  const tariffLogList = useSelector((state) => state?.updatedTariff?.updatedTariff);
  const isLoading = useSelector((state) => state.updatedTariff.isLoading);
  const isLoader = useSelector((state) => state.chargerTariff.isLoading);
  const isLoad = useSelector((state) => state.downloadingLoader.isLoading);
  const profileData = useSelector((state) => state.profile.userProfile);
  const currentUserId = _.get(profileData, 'id', '');
  const currentUserRole = _.get(profileData, 'role', '');
  const [showModal, setShowModal] = useState(false);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [filePopupVisible, setFilePopupVisible] = useState(false);
  const [logId, setLogId] = useState('');
  const [selectedFileName, setSelectedFileName] = useState('');

  const [page, setPage] = useState(1);
  const limit = 10;
  const totalData = chargerTariffList?.chargers?.length;
  const totalPages = Math.ceil(totalData / limit);

  const handlePageClick = (pageVal) => {
    setPage(pageVal.selected + 1);
  };

  const startIndex = (page - 1) * limit;
  const endIndex = Math.min(startIndex + limit, totalData);
  const sortedData = _.orderBy(chargerTariffList?.chargers, [sortByItem.item], [sortByItem.order]);
  const dataToShow = sortedData.slice(startIndex, endIndex);

  const [modalPage, setModalPage] = useState(1);
  const modaLlimit = 10;
  const totalModalData = tariffLogList?.log?.length;
  const totalModalPages = Math.ceil(totalModalData / modaLlimit);

  const handleModalPageClick = (pageVal) => {
    setModalPage(pageVal.selected + 1);
  };

  const modalStartIndex = (modalPage - 1) * modaLlimit;
  const modalEndIndex = Math.min(modalStartIndex + modaLlimit, totalModalData);
  const sortedModalData = _.orderBy(tariffLogList?.log, [sortByItem.item], [sortByItem.order]);
  const dataToShowInModal = sortedModalData.slice(modalStartIndex, modalEndIndex);

  const getChargerTariff = useCallback((data) => {
    dispatch({
      type: GET_CHARGER_TARIFF,
      payload: data,
    });
  }, []);

  const updatedTariffLog = useCallback(() => {
    dispatch({
      type: UPDATED_TARIFF_LOG_LIST,
      payload: { id: logId },
    });
  }, [logId]);

  const downloadChargerTariff = useCallback((data) => {
    dispatch({
      type: DOWNLOAD_CHARGER_TARIFF,
      payload: { ...data, excel: true },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `Sample_tariff.xlsx`);
          }
        }
      },
    });
  }, []);

  const downloadUpdatedTariffLogs = useCallback(() => {
    dispatch({
      type: DOWNLOAD_UPDATED_TARIFF_LOG,
      payload: { id: logId, excel: true },
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `updated_tariff_logs.xlsx`);
          }
        }
      },
    });
  }, [logId]);

  const bulkUploadChargerTariff = useCallback((data) => {
    dispatch({
      type: BULK_UPLOAD_CHARGER_TARIFF,
      payload: { user: currentUserId, ...data },
      cb: (res) => {
        if (_.get(res, 'status') === 200) {
          if (_.get(res, 'data.success') === true) {
            toast.success('Tariffs Uploaded successfully');
            setFilePopupVisible(false);
            setSelectedFileName('');
            getChargerTariff();
            setShowModal(true);
            setLogId(_.get(res, 'data.logId', ''));
          } else {
            _.map(_.get(res, 'data.logDetails', ''), (item) => {
              toast.error(item.error);
            });
            setFilePopupVisible(false);
            setSelectedFileName('');
            getChargerTariff();
            setShowModal(true);
            setLogId(_.get(res, 'data.logId', ''));
          }
        }
      },
    });
  }, []);

  useEffect(() => {
    getChargerTariff();
  }, []);

  useEffect(() => {
    logId && updatedTariffLog();
  }, [logId]);

  const handleCloseTable = () => {
    setShowModal(false);
    setModalPage(1);
  };

  const downloadFile = () => {
    downloadChargerTariff();
  };

  const downloadUpdatedFile = () => {
    downloadUpdatedTariffLogs();
  };

  const handleFileUpload = () => {
    setFilePopupVisible(true);
  };

  const closeFileUpload = () => {
    setFilePopupVisible(false);
    setSelectedFileName('');
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFileName(file.name);
    if (file) {
      processExcel(file);
    }
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files[0];
    setSelectedFileName(droppedFiles.name);
    if (droppedFiles) {
      processExcel(droppedFiles);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const processExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const firstSheet = workbook.SheetNames[0];
      const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
      const modifiedData = {
        Charger_Tariff_List: excelRows.map((item) => ({
          s_no: item['Sr. No'],
          code: item['Code'],
          capacity: item['Capacity'],
          charger_id: item['Charger ID'],
          v2_tariff_name: item['V2 Tariff Name'],
          v3_tariff_name: item['V3 Tariff Name'],
          v3_plu1: item['V3 PLU-1'],
          v3_plu2: item['V3 PLU-2'],
          v3_plu1_unitPrice: item['V3 PLU-1 Unit Price'],
          v3_plu2_unitPrice: item['V3 PLU-2 Unit Price'],
          v3_plu1_unit: item['V3 PLU-1 Unit'],
          v3_plu2_unit: item['V3 PLU-2 Unit'],
          v2_price_per_unit: item['V2 Unit Price'],
        })),
      };
      bulkUploadChargerTariff(modifiedData);
    };
    reader.readAsBinaryString(file);
  };

  const getSortByItem = (name, order, page) => {
    const sortedOrder = order || 'asc';
    if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getChargerTariff(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getChargerTariff(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <>
      <LayoutContainer>
        <AdminHeader title="Tariff Operations" />
        <div className="page-content-wrapper scrollable">
          <div className="existing-pricing__main">
            <div className="existing-pricing--block">
              <Card>
                <div className="pricing-block-content">
                  <div className="pricing-block-text">Tariff List</div>
                  {currentUserRole !== CPO_MANAGER ? (
                    <div className="upload-new-price">
                      <Button onClick={downloadFile}>Download Sample Sheet</Button>
                      <Button onClick={handleFileUpload}>
                        <FiUpload size={20} /> {'Bulk Upload Tariffs'}
                      </Button>
                    </div>
                  ) : null}
                </div>
                <div className="pricing-list-table">
                  <div className="table-responsive">
                    <table className="record-list-table">
                      <thead>
                        <tr key={`tariff-header-row`}>
                          <th>
                            <div className="sorting">
                              <span>Sr. no</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Charger ID</span>
                              <span className="ico" onClick={() => handleSorting('charger_id', sortByItem)}>
                                <BiSort size={15} />
                              </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Station Code</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Capacity</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Tariff (v2)</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>PLU (v2)</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Price per unit (v2)</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>Tariff (v3)</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>PLU 1 (v3) </span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>PLU 1 price per unit (v3)</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>PLU 2 (v3)</span>
                            </div>
                          </th>
                          <th>
                            <div className="sorting">
                              <span>PLU 2 price per unit (v3)</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoader ? (
                          <tr>
                            <td colSpan={10} className="border-0">
                              <div className="loader--block">
                                <Spinner as="span" animation="border" size="sm" role="status" />
                                <span className="ps-2">Loading...</span>
                              </div>
                            </td>
                          </tr>
                        ) : _.isEmpty(dataToShow) ? (
                          <tr key={`tariff-empty-row`}>
                            <td colSpan={5}>
                              <div className="empty-data-block">No tariff data</div>
                            </td>
                          </tr>
                        ) : (
                          !(isLoader || _.isUndefined(isLoader)) &&
                          _.map(dataToShow, (item, index) => {
                            return (
                              <tr key={`tariff-${index}`}>
                                <td>{_.get(item, 's_no')}</td>
                                <td>{_.get(item, 'charger_id', '')}</td>
                                <td>{_.get(item, 'code', '')}</td>
                                <td>{_.get(item, 'capacity', '')}</td>
                                <td>{_.get(item, 'v2_tariff_name', '')}</td>
                                <td>{_.get(item, 'v2_plu', '')}</td>
                                <td>{_.get(item, 'v2_price_per_unit', '')}</td>
                                <td>{_.get(item, 'v3_tariff_name', '')}</td>
                                <td>{_.get(item, 'v3_plu1', '')}</td>
                                <td>{_.get(item, 'v3_plu1_unitPrice', '')}</td>
                                <td>{_.get(item, 'v3_plu2', '')}</td>
                                <td>{_.get(item, 'v3_plu2_unitPrice', '')}</td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(dataToShow) && (
                  <ReactPagination
                    currentPage={page}
                    limit={limit}
                    total={totalData}
                    handlePageClick={(pageVal) => handlePageClick(pageVal)}
                    totalPages={totalPages}
                    marginPagesDisplayed={1}
                  />
                )}
              </Card>
            </div>
          </div>
        </div>

        {filePopupVisible && (
          <Modal show={filePopupVisible} centered onHide={closeFileUpload}>
            <div className="file-upload-popup">
              <div className="file-drop-area" onClick={handleClick} onDrop={handleFileDrop} onDragOver={handleDragOver}>
                {selectedFileName ? (
                  <p className="small-text">
                    <PiMicrosoftExcelLogo style={{ fontSize: '24px', marginBottom: '5px', color: '#5dad5d' }} />
                    <br />
                    {selectedFileName}
                  </p>
                ) : (
                  <>
                    <p className="small-text">
                      <FaCloudUploadAlt style={{ fontSize: '24px', marginBottom: '5px' }} />
                      <br />
                      {'Drag & Drop Files Here or Click to Select'}
                    </p>
                    <input type="file" ref={fileInputRef} onChange={handleFileSelect} style={{ display: 'none' }} />
                  </>
                )}
              </div>
              <Button onClick={closeFileUpload}> {'Cancel'}</Button>
            </div>
          </Modal>
        )}

        {showModal && (
          <Modal show={showModal} size="xl" centered>
            <div className="tariff-log-model_main_content">
              <div className="title_name">{'Updated Tariffs'}</div>
              {isLoad ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <div className="table-download-btn" onClick={downloadUpdatedFile}>
                  <BsDownload size={28} color={'#3c7cdd'} />
                </div>
              )}
              <div className="table-close-btn">{<MdClose size={30} color="#be210b" onClick={handleCloseTable} />}</div>
            </div>
            <div className="tariff-log-table">
              <div className="user-log">
                <div className="user-info">
                  <span className="user-header">{'Updated By:'}</span>
                  <span className="user-data">{_.get(tariffLogList, 'user.name', '')}</span>
                </div>

                <div className="user-info">
                  <span className="user-header">{'Email:'}</span>
                  <span className="user-data">{_.get(tariffLogList, 'user.email', '')}</span>
                </div>
              </div>
              <div className="table-responsive scrollable">
                <table className="record-list-table ">
                  <thead>
                    <tr>
                      <th>
                        <div className="sorting">
                          <span>{'Sr. No'}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{'Charger Id'}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{'Old Tariff (v2)'}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{'New Tariff (v2)'}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{'Old Tariff (v3)'}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{'New Tariff (v3)'}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{'Status'}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{'Message'}</span>
                        </div>
                      </th>
                      <th>
                        <div className="sorting">
                          <span>{'Error'}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={10} className="border-0">
                          <div className="loader--block">
                            <Spinner as="span" animation="border" size="sm" role="status" />
                            <span className="ps-2">Loading...</span>
                          </div>
                        </td>
                      </tr>
                    ) : _.isEmpty(dataToShowInModal) ? (
                      <tr>
                        <td colSpan={10} className="border-0">
                          <div className="tariff-log-empty-data-block">{'No Data'}</div>
                        </td>
                      </tr>
                    ) : (
                      !(isLoading || _.isUndefined(isLoading)) &&
                      _.map(dataToShowInModal, (item, key) => {
                        const serial_num = modaLlimit * (modalPage - 1) + key;
                        return (
                          <>
                            <tr key={`booking-history-list-${serial_num}`}>
                              <td>{serial_num + 1}</td>
                              <td>{_.get(item, 'charger_id', '')}</td>
                              <td>{_.get(item, 'old_v2', '')}</td>
                              <td>{_.get(item, 'v2_tariff', '')}</td>
                              <td>{_.get(item, 'old_v3', '')}</td>
                              <td>{_.get(item, 'v3_tariff', '')}</td>
                              <td>{_.get(item, 'status', '')}</td>
                              <td>{_.get(item, 'message', '')}</td>
                              <td>{_.get(item, 'error', '')}</td>
                            </tr>
                          </>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
              {!(isLoading || _.isUndefined(isLoading)) && !_.isEmpty(dataToShowInModal) && (
                <ReactPagination
                  currentPage={modalPage}
                  limit={modaLlimit}
                  total={totalModalData}
                  handlePageClick={(page) => handleModalPageClick(page)}
                  totalPages={totalModalPages}
                  marginPagesDisplayed={1}
                />
              )}
            </div>
          </Modal>
        )}
      </LayoutContainer>
    </>
  );
};

export default TariffOperations;
