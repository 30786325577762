import React, { useEffect, useCallback, useState, useRef } from 'react';
import { Spinner, Col, Row } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import * as _ from 'lodash';
import { CHARGER_STATUS, FETCH_CHARGER_STATUS_WIDGETS } from 'actions/chargerStatus';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import SearchBox from 'components/general/SearchBox';
import moment from 'moment';
import AdminHeader from 'components/header';
import LayoutContainer from 'components/layout/LayoutContainer';
import Card from 'components/inputs/Card';
import Button from 'components/inputs/Button';
import { BiSort } from 'react-icons/bi';
import ReactPagination from 'components/general/ReactPagination';
import Select from 'components/inputs/Select';
import { MdClear } from 'react-icons/md';
import { Form, Formik } from 'formik';
import OffCanvas from 'components/inputs/OffCanvas';
import { UPDATE_STATUS_CODE } from 'components/common/constant';
import { GiLogicGateAnd } from 'react-icons/gi';
import { RiChargingPile2Line } from 'react-icons/ri';
// import fileDownload from 'js-file-download';
// import { BsDownload } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';
import { TiTick } from 'react-icons/ti';

import { RiEqualizerFill } from 'react-icons/ri';
import { BsPlugin } from 'react-icons/bs';
import ToggleSwitch from 'components/inputs/ToggleSwitch';
import { FETCH_ZONES } from 'actions/zoneManagement';
import { FiZapOff } from 'react-icons/fi';
import { BsPlug } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import { TbPlugOff } from 'react-icons/tb';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { GoStop } from 'react-icons/go';

const ChargerStatus = () => {
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);
  const history = useHistory();
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const [searchText, setSearchText] = useState('');
  const [showAdvanceFilterPopup, setShowAdvanceFilterPopup] = useState(false);
  const [advanceFilterData, setAdvanceFilterData] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);

  const chargerStatusList = useSelector((state) => state.chargerStatus.chargerStatus);
  const isLoader = useSelector((state) => state.chargerStatus.isLoading);
  const page = useSelector((state) => state.chargerStatus.page);
  const totalData = useSelector((state) => state.chargerStatus.total);
  const limit = useSelector((state) => state.chargerStatus.limit);
  const totalPages = useSelector((state) => state.chargerStatus.totalPages);
  const profileData = useSelector((state) => state.profile.userProfile);
  const userProfileStations = profileData.charging_stations;
  // const isLoading = useSelector((state) => state.downloadingLoader.isLoading);
  const [isCutVisible, setIsCutVisible] = useState(false);
  const [status, setStatus] = useState('');

  const [isChecked, setIsChecked] = useState(false);

  const chargerStatus = useCallback(
    (value) => {
      if (searchText) {
        const chargerStatusData = {
          ...value,
          search: searchText,
        };
        dispatch({
          type: CHARGER_STATUS,
          payload: chargerStatusData,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      } else {
        const data = {
          ...value,
        };
        dispatch({
          type: CHARGER_STATUS,
          payload: data,
          cb: (res) => {
            if (_.get(res, 'status') === UPDATE_STATUS_CODE) {
              onCloseAdvanceFilterPopup();
            }
          },
        });
      }
    },
    [searchText]
  );

  const getWidgets = useCallback(
    (values) => {
      let data;
      if (!_.isEmpty(userProfileStations)) {
        data = {
          ...values,
          charging_station: userProfileStations,
        };
      } else {
        data = {
          ...values,
        };
      }
      dispatch({
        type: FETCH_CHARGER_STATUS_WIDGETS,
        payload: data,
      });
    },
    [userProfileStations]
  );

  useEffect(() => {
    let data;
    if (!_.isEmpty(advanceFilterData)) {
      data = {
        ...advanceFilterData,
        widgets_by_plug: isChecked,
      };
    } else {
      data = {
        widgets_by_plug: isChecked,
      };
    }
    getWidgets(data);
  }, [isChecked]);

  useEffect(() => {
    if (chargerFilter) {
      if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        chargerStatus(chargerFilter);
      } else {
        chargerStatus(chargerFilter);
      }
    } else {
      if (_.isEmpty(advanceFilterData.charging_station) && !_.isEmpty(userProfileStations)) {
        const sortedOrder = 'desc';
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}status`,
          charging_station: userProfileStations,
        };
        chargerStatus(data);
      } else {
        const sortedOrder = 'desc';
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}status`,
        };
        chargerStatus(data);
      }
    }
  }, [searchText]);

  // const downloadFile = () => {
  //   downloadChargerStatus();
  // };

  const getSortByItem = useCallback(
    (name, order, page, filterDateData) => {
      const sortedOrder = order || 'asc';
      if (page) {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          page: page.selected + 1,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        chargerStatus(data);
      } else if (filterDateData) {
        const data = {
          ...filterDateData,
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        chargerStatus(data);
      } else {
        const data = {
          sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
          charging_station: !_.isEmpty(userProfileStations) ? userProfileStations : '',
        };
        chargerStatus(data);
      }
    },
    [chargerStatus]
  );

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  const [chargerFilter, setChargerFilter] = useState('');

  const handlePageClick = useCallback(
    (page) => {
      setActiveIndex(null);
      const sortedOrder = sortByItem.order ? sortByItem.order : 'desc';
      const sortedItem = sortByItem.item ? sortByItem.item : 'status';
      let data = { page: page.selected + 1, sortBy: `${sortedOrder === 'asc' ? '' : '-'}${sortedItem}` };

      if (!_.isEmpty(advanceFilterData)) {
        data = { ...data, ...advanceFilterData };
      } else if (searchText) {
        // only use page number in this case
      } else if (chargerFilter) {
        data = { ...data, ...chargerFilter };
      }

      chargerStatus(data);
    },
    [sortByItem, advanceFilterData, userProfileStations, chargerFilter]
  );

  // const downloadChargerStatus = useCallback(() => {
  //   let basePayload = {
  //     excel: true,
  //   };

  //   if (searchText) {
  //     basePayload = { ...basePayload, search: searchText };
  //   }

  //   if (!_.isEmpty(advanceFilterData)) {
  //     basePayload = { ...basePayload, ...advanceFilterData };
  //   }

  //   if (chargerFilter) {
  //     basePayload = { ...basePayload, ...chargerFilter };
  //   }

  //   dispatch({
  //     type: CHARGER_STATUS_DOWNLOAD,
  //     payload: basePayload,
  //     cb: (res) => {
  //       if (res && _.get(res, 'status') === 200) {
  //         fileDownload(res.data, `${'Charger Status'}.xlsx`);
  //       }
  //     },
  //   });
  // }, [advanceFilterData, searchText, chargerFilter]);

  const allZones = useSelector((state) => state?.zoneManagement?.zones);
  const zonePage = useSelector((state) => state?.zoneManagement?.page);
  const zoneTotalPage = useSelector((state) => state?.zoneManagement?.totalPages);

  const initialValues = !_.isEmpty(advanceFilterData)
    ? { ...advanceFilterData }
    : { country: '', state: '', city: '', oem: '', charger_type: '', is_active: '', charger_availibity: '', access_type: '', zones: '' };

  const getAllZones = useCallback((data = {}) => {
    dispatch({ type: FETCH_ZONES, payload: data });
  }, []);

  useEffect(() => {
    getAllZones({ limit: 999 });
  }, []);

  const onCloseAdvanceFilterPopup = () => setShowAdvanceFilterPopup(false);

  const handleAdvanceFilterData = (data) => setAdvanceFilterData(data);

  const isLoad = useSelector((state) => state.chargerStatusWidget.isLoading);
  const totalCount = useSelector((state) => state.chargerStatusWidget.chargerStatusWidgets.total);
  const availableData = useSelector((state) => state.chargerStatusWidget.chargerStatusWidgets.available);
  const unavailableData = useSelector((state) => state.chargerStatusWidget.chargerStatusWidgets.unavailable);
  const inuseData = useSelector((state) => state.chargerStatusWidget.chargerStatusWidgets.inuse);
  const powerlossCount = useSelector((state) => state.chargerStatusWidget.chargerStatusWidgets.PowerLossCount);
  const faultedCount = useSelector((state) => state.chargerStatusWidget.chargerStatusWidgets.faultedCount);
  const enabledCount = useSelector((state) => state.chargerStatusWidget.chargerStatusWidgets.enabled);
  const disabledCount = useSelector((state) => state.chargerStatusWidget.chargerStatusWidgets.disabled);
  const emergencyStopCount = useSelector((state) => state.chargerStatusWidget.chargerStatusWidgets.emergencyStopCount);

  const updateChargerStatus = (availability, isEnabled) => {
    let chargerStatusData;
    if (!_.isEmpty(advanceFilterData)) {
      if (!_.isEmpty(userProfileStations)) {
        chargerStatusData = {
          ...advanceFilterData,
          charger_status: availability,
          charging_station: userProfileStations,
          is_enabled: isEnabled,
        };
      } else {
        chargerStatusData = {
          ...advanceFilterData,
          charger_status: availability,
          is_enabled: isEnabled,
        };
      }
    } else {
      if (!_.isEmpty(userProfileStations)) {
        chargerStatusData = {
          charger_status: availability,
          charging_station: userProfileStations,
          is_enabled: isEnabled,
        };
      } else {
        chargerStatusData = {
          charger_status: availability,
          is_enabled: isEnabled,
        };
      }
    }
    setActiveIndex(null);
    setChargerFilter(chargerStatusData);
    dispatch({
      type: CHARGER_STATUS,
      payload: chargerStatusData,
    });
  };

  const getStatusFromCpoErrorCode = (status, cpoErrorCode) => {
    return cpoErrorCode.includes('Power Loss') ? 'Power Loss' : status;
  };

  const handleClose = () => {
    if (!_.isEmpty(advanceFilterData)) {
      const stationData = {
        sortBy: `${sortByItem.order === 'asc' ? '' : '-'}status`,
        ...advanceFilterData,
      };
      chargerStatus(stationData);
    } else {
      chargerStatus({ sortBy: `${sortByItem.order === 'asc' ? '' : '-'}status` });
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 300;
      checkArrowVisibility();
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 300;
      checkArrowVisibility();
    }
  };

  const checkArrowVisibility = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;

      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    const handleResize = () => {
      checkArrowVisibility();
    };

    if (container) {
      checkArrowVisibility();
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <LayoutContainer>
        <AdminHeader title={'Charger Status'} />
        <div className="page-content-wrapper scrollable">
          <div className="wise_status" title="Switch Between Charger Wise Data and Connector Wise Data">
            {'Charger wise'}
            <ToggleSwitch id="toggle-switch" name="toggle-switch" checked={isChecked} onChange={(event) => setIsChecked( event.target.checked)} />
            {'ChargePoint wise'}
          </div>
          <div className="scroll-container">
            {showLeftArrow && <FaChevronLeft className="scroll-button left" onClick={scrollLeft} />}
            {showRightArrow && <FaChevronRight className="scroll-button right" onClick={scrollRight} />}
            {isChecked ? (
              <div className="status-blocks" ref={scrollContainerRef} onScroll={checkArrowVisibility}>
                <div className="status-block" title="Total Number of Charge Points">
                  <div className="breating-area" style={{ background: '#ff8b001a' }}>
                    {<RiChargingPile2Line color="#FF8B00" size={35} />}
                  </div>
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{totalCount || 0}</h1>
                    )}
                    <p>{'Total Charge Points'}</p>
                  </div>
                </div>
                <div className="status-block" title="Number of Charge Points are enabled">
                  <div className="breating-area" style={{ background: '#14ac7f1a' }}>
                    {<RiChargingPile2Line color="#14AC7F" size={35} />}
                  </div>
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{enabledCount || 0}</h1>
                    )}
                    <p>{'Enabled'}</p>
                  </div>
                </div>
                <div className="status-block" title="Number of Charge Points Currently In Use">
                  <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
                    {<GiLogicGateAnd color="#4975F2" size={35} />}
                  </div>
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{inuseData || 0}</h1>
                    )}
                    <p>{'In Use'}</p>
                  </div>
                </div>
                <div className="status-block" title="Number of Availabe Charge Points">
                  <div className="breating-area" style={{ background: '#14ac7f1a' }}>
                    {<BsPlug color="#14AC7F" size={35} />}
                  </div>
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{availableData || 0}</h1>
                    )}
                    <p>{'Available'}</p>
                  </div>
                </div>
                <div className="status-block" title="Number of Unavailabe Charge Points">
                  <div className="breating-area" style={{ background: '#be210b21' }}>
                    {<BsPlug color="#BE210B" size={35} />}
                  </div>
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{unavailableData || 0}</h1>
                    )}
                    <p>{'Unavailable'}</p>
                  </div>
                </div>
                <div className="status-block" title="Number of Charge Points are faulted">
                  <div className="breating-area" style={{ background: '#ff8b001a' }}>
                    {<FiZapOff color="#FF8B00" size={35} />}
                  </div>
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{faultedCount || 0}</h1>
                    )}
                    <p>{'Faulted'}</p>
                  </div>
                </div>
                <div className="status-block" title="Number of Charge Points with No Power Availability">
                  <div className="breating-area" style={{ background: '#ff8b001a' }}>
                    {<FiZapOff color="#FF8B00" size={35} />}
                  </div>
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{powerlossCount || 0}</h1>
                    )}
                    <p>{'PowerLoss'}</p>
                  </div>
                </div>
                <div className="status-block" title="Number of Charge Points where Pressed Emergency Stop Button">
                  <div className="breating-area" style={{ background: '#c9a11b1a' }}>
                    {<GoStop color="#C9A11B" size={35} />}
                  </div>
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{emergencyStopCount || 0}</h1>
                    )}
                    <p>{'EmergencyStop'}</p>
                  </div>
                </div>
                <div className="status-block" title="Number of Charge Points are disabled">
                  <div className="breating-area" style={{ background: '#be210b21' }}>
                    {<TbPlugOff color="#BE210B" size={35} />}
                  </div>
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{disabledCount || 0}</h1>
                    )}
                    <p>{'Disabled'}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="status-blocks" ref={scrollContainerRef} onScroll={checkArrowVisibility}>
                <div
                  className="status-block"
                  onClick={() => {
                    updateChargerStatus('', '');
                    setStatus('Total');
                  }}
                  title="Total Number of Chargers"
                >
                  <div className="breating-area" style={{ background: '#ff8b001a' }}>
                    {<RiChargingPile2Line color="#FF8B00" size={35} />}
                  </div>
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{totalCount || 0}</h1>
                    )}
                    <p>{'Total Chargers'}</p>
                  </div>
                </div>
                <div
                  className="status-block"
                  onClick={() => {
                    updateChargerStatus('', true);
                    setIsCutVisible(true);
                    setStatus('Enabled');
                  }}
                  title="Number of Chargers enabled"
                >
                  <div className="breating-area" style={{ background: '#14ac7f1a' }}>
                    {<RiChargingPile2Line color="#14AC7F" size={35} />}
                  </div>
                  {status !== 'Total' && status === 'Enabled' && isCutVisible && (
                    <MdClose
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        cursor: 'pointer',
                        marginBottom: '0.5rem',
                      }}
                      size={20}
                      color="#be210b"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsCutVisible(false);
                        handleClose();
                      }}
                    />
                  )}
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{enabledCount || 0}</h1>
                    )}
                    <p>{'Enabled'}</p>
                  </div>
                </div>
                <div
                  className="status-block"
                  onClick={() => {
                    updateChargerStatus('In Use', true);
                    setIsCutVisible(true);
                    setStatus('In Use');
                  }}
                  title="Number of Chargers Currently In Use"
                >
                  <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
                    {<GiLogicGateAnd color="#4975F2" size={35} />}
                  </div>
                  {status !== 'Total' && status === 'In Use' && isCutVisible && (
                    <MdClose
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        cursor: 'pointer',
                        marginBottom: '0.5rem',
                      }}
                      size={20}
                      color="#be210b"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsCutVisible(false);
                        handleClose();
                      }}
                    />
                  )}
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{inuseData || 0}</h1>
                    )}
                    <p>{'In Use'}</p>
                  </div>
                </div>
                <div
                  className="status-block"
                  onClick={() => {
                    updateChargerStatus('Available', true);
                    setIsCutVisible(true);
                    setStatus('Available');
                  }}
                  title="Number of Availabe Chargers"
                >
                  <div className="breating-area" style={{ background: '#14ac7f1a' }}>
                    {<BsPlug color="#14AC7F" size={35} />}
                  </div>
                  {status !== 'Total' && status === 'Available' && isCutVisible && (
                    <MdClose
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        cursor: 'pointer',
                        marginBottom: '0.5rem',
                      }}
                      size={20}
                      color="#be210b"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsCutVisible(false);
                        handleClose();
                      }}
                    />
                  )}
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{availableData || 0}</h1>
                    )}
                    <p>{'Available'}</p>
                  </div>
                </div>
                <div
                  className="status-block"
                  onClick={() => {
                    updateChargerStatus('Unavailable', true);
                    setIsCutVisible(true);
                    setStatus('Unavailable');
                  }}
                  title="Number of Unavailabe Chargers"
                >
                  <div className="breating-area" style={{ background: '#be210b21' }}>
                    {<BsPlug color="#BE210B" size={35} />}
                  </div>
                  {status !== 'Total' && status === 'Unavailable' && isCutVisible && (
                    <MdClose
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        cursor: 'pointer',
                        marginBottom: '0.5rem',
                      }}
                      size={20}
                      color="#be210b"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsCutVisible(false);
                        handleClose();
                      }}
                    />
                  )}
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{unavailableData || 0}</h1>
                    )}
                    <p>{'Unavailable'}</p>
                  </div>
                </div>
                <div
                  className="status-block"
                  onClick={() => {
                    updateChargerStatus('Faulted', true);
                    setIsCutVisible(true);
                    setStatus('Faulted');
                  }}
                  title="Number of Chargers faulted"
                >
                  <div className="breating-area" style={{ background: '#ff8b001a' }}>
                    {<FiZapOff color="#FF8B00" size={35} />}
                  </div>
                  {status !== 'Total' && status === 'Faulted' && isCutVisible && (
                    <MdClose
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        cursor: 'pointer',
                        marginBottom: '0.5rem',
                      }}
                      size={20}
                      color="#be210b"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsCutVisible(false);
                        handleClose();
                      }}
                    />
                  )}
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{faultedCount || 0}</h1>
                    )}
                    <p>{'Faulted'}</p>
                  </div>
                </div>
                <div
                  className="status-block"
                  onClick={() => {
                    updateChargerStatus('PowerLoss', true);
                    setIsCutVisible(true);
                    setStatus('PowerLoss');
                  }}
                  title="Number of Chargers with No Power Availability"
                >
                  <div className="breating-area" style={{ background: '#ff8b001a' }}>
                    {<FiZapOff color="#FF8B00" size={35} />}
                  </div>
                  {status !== 'Total' && status === 'PowerLoss' && isCutVisible && (
                    <MdClose
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        cursor: 'pointer',
                        marginBottom: '0.5rem',
                      }}
                      size={20}
                      color="#be210b"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsCutVisible(false);
                        handleClose();
                      }}
                    />
                  )}
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{powerlossCount || 0}</h1>
                    )}
                    <p>{'PowerLoss'}</p>
                  </div>
                </div>
                <div
                  className="status-block"
                  onClick={() => {
                    updateChargerStatus('EmergencyStopPressed', true);
                    setIsCutVisible(true);
                    setStatus('EmergencyStopPressed');
                  }}
                  title="Number of Chargers where Pressed Emergency Stop Button"
                >
                  <div className="breating-area" style={{ background: '#c9a11b1a' }}>
                    {<GoStop color="#C9A11B" size={35} />}
                  </div>
                  {status !== 'Total' && status === 'EmergencyStopPressed' && isCutVisible && (
                    <MdClose
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        cursor: 'pointer',
                        marginBottom: '0.5rem',
                      }}
                      size={20}
                      color="#be210b"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsCutVisible(false);
                        handleClose();
                      }}
                    />
                  )}
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{emergencyStopCount || 0}</h1>
                    )}
                    <p>{'EmergencyStop'}</p>
                  </div>
                </div>
                <div
                  className="status-block"
                  onClick={() => {
                    updateChargerStatus('', false);
                    setIsCutVisible(true);
                    setStatus('Disabled');
                  }}
                  title="Number of Chargers disabled"
                >
                  <div className="breating-area" style={{ background: '#be210b21' }}>
                    {<TbPlugOff color="#BE210B" size={35} />}
                  </div>
                  {status !== 'Total' && status === 'Disabled' && isCutVisible && (
                    <MdClose
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        cursor: 'pointer',
                        marginBottom: '0.5rem',
                      }}
                      size={20}
                      color="#be210b"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsCutVisible(false);
                        handleClose();
                      }}
                    />
                  )}
                  <div className="status-info">
                    {isLoad ? (
                      <div className="loader--block">
                        <Spinner as="span" animation="border" size="sm" role="status" />
                      </div>
                    ) : (
                      <h1>{disabledCount || 0}</h1>
                    )}
                    <p>{'Disabled'}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="data-report--main">
            <div className="data-report--block">
              <Card>
                <div className="data-report__inner">
                  <div className="data-report__box">
                    <Row className="data-report__row">
                      <Col xl={11} lg={11} md={11} className="search_box_div">
                        <SearchBox value={searchText} preIcon={<BsSearch />} onChange={(e) => setSearchText(e.target.value)} />
                      </Col>

                      <Col xl={1} md={1} className="search_btn_download">
                        {/* <div className="excel-icon--block" onClick={downloadFile} title="Download">
                          {isLoading ? (
                            <div className="loader--block">
                              <Spinner as="span" animation="border" size="sm" role="status" />
                            </div>
                          ) : (
                            <div className="report-excel-icon">
                              <BsDownload title="Download" size={28} color={'#FFFFFF'} />
                            </div>
                          )}
                        </div> */}
                        <div className="advance-filter-icon" title="Open Advance Filter">
                          <RiEqualizerFill size={28} color={'#FFFFFF'} onClick={() => setShowAdvanceFilterPopup(true)} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="data-report__table">
                    <div className="table-responsive">
                      <table className="record-list-table chargerstatus-list-table" id="table-to-xls">
                        <thead>
                          <tr>
                            <th>
                              <div className="sorting">
                                <span>Charger ID</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Station Name</span>
                                <span className="ico" onClick={() => handleSorting('name', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Station ID</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Connectors</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Charger Published</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Status</span>
                                <span className="ico" onClick={() => handleSorting('status', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Site Published</span>
                              </div>
                            </th>
                            <th>
                              <div className="sorting">
                                <span>Last Updated</span>
                                <span className="ico" onClick={() => handleSorting('updatedAt', sortByItem)}>
                                  <BiSort size={15} />
                                </span>
                              </div>
                            </th>
                            <th colSpan={2}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoader ? (
                            <tr>
                              <td colSpan={11} className="border-0">
                                <div className="loader--block">
                                  <Spinner as="span" animation="border" size="sm" role="status" />
                                  <span className="ps-2">Loading...</span>
                                </div>
                              </td>
                            </tr>
                          ) : _.isEmpty(chargerStatusList) ? (
                            <tr>
                              <td colSpan={9} className="border-0">
                                <div className="empty-data-block">{'No Charger Status Found'}</div>
                              </td>
                            </tr>
                          ) : (
                            !(isLoader || _.isUndefined(isLoader)) &&
                            _.map(chargerStatusList, (item0, index0) => {
                              const globalIndex = parseInt('' + index0);
                              const serial_num = limit * (page - 1) + index0;
                              const isPowerLoss = _.get(item0, 'charger_status', '') === 'PowerLoss';
                              return (
                                <React.Fragment key={`charger-status-${serial_num}`}>
                                  <div className="charger-status-tr">
                                    <tr>
                                      <td
                                        className="charger-link"
                                        onClick={() => history.push(`/charger/${_.get(item0, 'id', '')}`, { backTo: 'chargerStatus' })}
                                      >
                                        {_.get(item0, 'charger_id', '')}
                                      </td>
                                      <td
                                        className="station-link"
                                        onClick={() =>
                                          history.push(`/stations/${_.get(item0, 'charging_station.id', '')}`, { backTo: 'chargerStatus' })
                                        }
                                      >
                                        {<span>{_.get(item0, 'charging_station.name', '')}</span>}
                                      </td>
                                      <td>{_.get(item0, 'charging_station.code', '')}</td>
                                      <td>
                                        <div className="connector-wrapper">
                                          {_.map(_.get(item0, 'plugs'), (item1, index1) => {
                                            const isCharging = _.get(item1, 'status', '') === 'Charging';
                                            const isPreparing = _.get(item1, 'status', '') === 'Preparing';
                                            const isFinishing = _.get(item1, 'status', '') === 'Finishing';
                                            const isAvailable = _.get(item1, 'status', '') === 'Available';
                                            const isUnavailable = _.get(item1, 'status', '') === 'Unavailable';
                                            const isFaulted = _.get(item1, 'status', '') === 'Faulted';
                                            const name = _.get(item1, 'name', '');
                                            const hasMoreThanOneLetter = name.length > 1;

                                            let className = 'connector-circle';
                                            if (isCharging) {
                                              className += ' charging';
                                            } else if (isAvailable) {
                                              className += ' available';
                                            } else if (isUnavailable && isPowerLoss) {
                                              className += ' unavailable';
                                            } else if (isFaulted && isPowerLoss) {
                                              className += ' PowerLoss';
                                            } else if (isPreparing || isFinishing) {
                                              className += ' preparing';
                                            } else {
                                              className += ' unavailable';
                                            }

                                            if (hasMoreThanOneLetter) {
                                              className += ' more-than-one-letter';
                                            }

                                            return (
                                              <div key={index1} className={className}>
                                                {<BsPlugin size={28} />}
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </td>

                                      <td>
                                        {_.get(item0, 'is_enabled') === true ? (
                                          <TiTick color={'#0f9b72'} size={30} />
                                        ) : (
                                          <RxCross2 color={'#BD210B'} size={30} />
                                        )}
                                      </td>
                                      <td>
                                        <div className={`status_charger status-${_.get(item0, 'charger_status')}`}>
                                          {_.get(item0, 'charger_status', '')}
                                        </div>
                                      </td>
                                      
                                      <td>
                                        {_.get(item0, 'charging_station.is_enabled') === true ? (
                                          <TiTick color={'#0f9b72'} size={30} />
                                        ) : (
                                          <RxCross2 color={'#BD210B'} size={30} />
                                        )}
                                      </td>
                                      <td>{moment.utc(_.get(item0, 'updatedAt', '')).local().format('DD/MM/YYYY H:mm:ss')}</td>
                                      <td className="dropdown-link" colSpan={2}>
                                        {activeIndex === globalIndex ? (
                                          <FaChevronUp size={28} onClick={() => setActiveIndex(null)} title="Collapse" />
                                        ) : (
                                          <FaChevronDown size={28} onClick={() => setActiveIndex(globalIndex)} title="Expand" />
                                        )}
                                      </td>
                                    </tr>
                                    {activeIndex === globalIndex && (
                                      <tr className="sub-table">
                                        <React.Fragment>
                                          <table>
                                            {/* sub-table-head-row */}
                                            <tr className="sub-table-head-row" style={{ backgroundColor: '#141f29' }}>
                                              <th>
                                                <div className="sorting">
                                                  <span>ChargePoint ID</span>
                                                </div>
                                              </th>
                                              <th>
                                                <div className="sorting">
                                                  <span>Connectors</span>
                                                </div>
                                              </th>
                                              <th>
                                                <div className="sorting">
                                                  <span>Status</span>
                                                </div>
                                              </th>
                                              <th>
                                                <div className="sorting">
                                                  <span>Error Code</span>
                                                </div>
                                              </th>
                                              <th>
                                                <div className="sorting">
                                                  <span>Error Message</span>
                                                </div>
                                              </th>
                                              <th>
                                                <div className="sorting">
                                                  <span>CPO Code</span>
                                                </div>
                                              </th>
                                            </tr>
                                            {/* sub-table-body-row */}
                                            {_.map(_.get(item0, 'plugs'), (item2, index2) => {
                                              const isCharging = _.get(item2, 'status', '') === 'Charging';
                                              const isPreparing = _.get(item2, 'status', '') === 'Preparing';
                                              const isFinishing = _.get(item2, 'status', '') === 'Finishing';
                                              const isAvailable = _.get(item2, 'status', '') === 'Available';
                                              const isUnavailable = _.get(item2, 'status', '') === 'Unavailable';
                                              const isFaulted = _.get(item2, 'status', '') === 'Faulted';
                                              const chargerId = _.get(item0, 'charger_id', '');
                                              const chargerpointId = chargerId.slice(1) + _.get(item2, 'connector_id', '');
                                              const name = _.get(item2, 'name', '');
                                              const hasMoreThanOneLetter = name.length > 1;
                                              let connectorClassName = 'connector-circle';
                                              if (isCharging || isPreparing || isFinishing) {
                                                connectorClassName += 'connector-charging';
                                              } else if (!isAvailable) {
                                                connectorClassName += 'connector-unavailable';
                                              }

                                              let statusClassName = '';
                                              if (isCharging) {
                                                statusClassName = 'charging';
                                              } else if (isAvailable) {
                                                statusClassName = 'available';
                                              } else if (isUnavailable && isPowerLoss) {
                                                statusClassName = 'unavailable';
                                              } else if (isFaulted && isPowerLoss) {
                                                statusClassName = 'PowerLoss';
                                              } else if (isPreparing || isFinishing) {
                                                statusClassName = 'preparing';
                                              } else {
                                                statusClassName = 'unavailable';
                                              }
                                              return (
                                                <tr key={index2} className="sub-table-body-row">
                                                  <td style={{ backgroundColor: '#141f29' }}>
                                                    <div className="connector-wrapper">
                                                      <div
                                                        className={
                                                          hasMoreThanOneLetter ? `${connectorClassName} more-than-one-letter` : connectorClassName
                                                        }
                                                      >
                                                        {chargerpointId}
                                                      </div>
                                                    </div>
                                                  </td>
                                                  <td className={statusClassName} style={{ backgroundColor: '#141f29' }}>
                                                    <div>{name}</div>
                                                  </td>
                                                  <td className={statusClassName} style={{ backgroundColor: '#141f29' }}>
                                                    <BsPlugin size={28} />
                                                    {getStatusFromCpoErrorCode(_.get(item2, 'status', ''), _.get(item2, 'cpoErrorCode', ''))}
                                                  </td>
                                                  <td style={{ backgroundColor: '#141f29' }}>{_.get(item2, 'errorMessage', '')}</td>
                                                  <td style={{ backgroundColor: '#141f29' }}>{_.get(item2, 'errorInfo', '')}</td>
                                                  <td style={{ backgroundColor: '#141f29' }}>{_.get(item2, 'cpoErrorCode', '')}</td>
                                                </tr>
                                              );
                                            })}
                                          </table>
                                        </React.Fragment>
                                      </tr>
                                    )}
                                  </div>
                                </React.Fragment>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {!(isLoader || _.isUndefined(isLoader)) && !_.isEmpty(chargerStatusList) && (
                    <>
                      <ReactPagination
                        currentPage={page}
                        limit={limit}
                        total={totalData}
                        handlePageClick={(pageVal) => handlePageClick(pageVal)}
                        totalPages={totalPages}
                        marginPagesDisplayed={1}
                      />
                    </>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </LayoutContainer>
      {showAdvanceFilterPopup && (
        <OffCanvas show={showAdvanceFilterPopup} onClose={onCloseAdvanceFilterPopup}>
          <Formik
            enableReinitialize={!_.isEmpty(advanceFilterData)}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
              !values.country && delete values['country'];
              !values.state && delete values['state'];
              !values.city && delete values['city'];
              !values.oem && delete values['oem'];
              !values.charger_type && delete values['charger_type'];
              !values.access_type && delete values['access_type'];
              !values.is_active && delete values['is_active'];
              !values.charger_availibity && delete values['charger_availibity'];
              !values.zones && delete values['zones'];
              const filterData = {
                ...values,
                is_active: values.is_active === 'Not In Use' ? false : true,
              };
              chargerStatus(filterData);
              getWidgets(values);
              handleAdvanceFilterData(values);
              setSubmitting(false);
            }}
          >
            {({ values, handleSubmit, setFieldValue, resetForm }) => (
              <Form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <div className="canvas-selection__block">
                    <Select
                      label="Zones"
                      isMulti
                      options={_.map(allZones, (item) => {
                        return { label: item.name, value: item.id };
                      })}
                      className="filter-select-box"
                      placeholder="Select Zones"
                      name="zones"
                      value={values.zones}
                      onMenuScrollDown={true}
                      getDataOnScrollDown={getAllZones}
                      page={zonePage}
                      totalPage={zoneTotalPage}
                      onChange={(val) => {
                        setFieldValue(`zones`, val);
                      }}
                    />
                  </div>
                </div>
                <div className="canvas-btn__block">
                  <Button type="submit" className="apply--btn w-100">
                    {'Apply Filters'}
                  </Button>
                  <div
                    className="reset--block"
                    onClick={() => {
                      setAdvanceFilterData({});
                      chargerStatus();
                      getWidgets();
                      resetForm();
                      onCloseAdvanceFilterPopup();
                    }}
                  >
                    <MdClear size={24} className="reset--icon" />
                    {'Clear'}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </OffCanvas>
      )}
    </>
  );
};

export default ChargerStatus;
