import { get, deleteRequest, post, put, download } from 'utils/api';

export const getTariff = (data) => {
  return get(`/tarrif`, data);
};

export const addTariff = (data) => {
  return post(`/tarrif`, data);
};

export const updateTariff = (data) => {
  const id = data.id;
  delete data['id'];
  return put(`/tarrif/${id}`, data);
};

export const deleteTariff = (id) => {
  return deleteRequest(`/tarrif/${id}`);
};

export const getTariffById = (id) => {
  return get(`/tarrif/${id}`);
};

export const getChargerTariff = (data) => {
  return get(`/chargers/sample_tariffs`, data);
};

export const downloadChargerTariff = (data) => {
  return download(`/chargers/sample_tariffs`, data);
};

export const getUpdatedTarifflogs = (data) => {
  const id = data.id;
  delete data['id'];
  return get(`/util/tariff_update_log/${id}`, data);
};

export const downloadUpdatedTarifflogs = (data) => {
  const id = data.id;
  delete data['id'];
  return download(`/util/tariff_update_log/${id}`, data);
};

export const bulkUploadChargerTariff = (data) => {
  return post(`/chargers/update_tariffs`, data);
};

export const getTariffV2 = (data) => {
  return get(`/tarrif/version/v2`, data);
};

export const getTariffV3 = (data) => {
  return get(`/tarrif/version/v3`, data);
};

export const tariff = {
  getTariff,
  addTariff,
  updateTariff,
  deleteTariff,
  getTariffById,
  getChargerTariff,
  downloadChargerTariff,
  getUpdatedTarifflogs,
  downloadUpdatedTarifflogs,
  bulkUploadChargerTariff,
  getTariffV2,
  getTariffV3,
};
