import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { BsLightningCharge } from 'react-icons/bs';
import { BsBarChartFill } from 'react-icons/bs';
// import { IoTimeOutline } from 'react-icons/io5';
// import { BsPlugin } from 'react-icons/bs';
import { GET_DASHBOARD_DAILY_WIDGET_DATA } from 'actions/dashboard';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { roundOfDigit } from 'components/common/utils';
import { commaSeperate } from 'components/common/utils';
import { TbCurrencyDirham } from 'react-icons/tb';
import { MdMultilineChart } from 'react-icons/md';
import { RxLapTimer } from 'react-icons/rx';
import { FaFileInvoice } from 'react-icons/fa';
import { GiCharging } from 'react-icons/gi';
import { CgSandClock } from 'react-icons/cg';
// import { MdOutlineReceiptLong } from 'react-icons/md';
import { FaArrowUp, FaArrowDown, FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import moment from 'moment-timezone';

const DailyWidgets = () => {
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);

  const isLoading = useSelector((state) => state.dashboardWidget.isLoading);
  const dailyWidgetList = useSelector((state) => state.dashboardWidget.dailyWidgets);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const getDashboardDailyWidgetData = useCallback(() => {
    dispatch({ type: GET_DASHBOARD_DAILY_WIDGET_DATA });
  }, []);

  useEffect(() => {
    getDashboardDailyWidgetData();
  }, []);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 300;
      checkArrowVisibility();
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 300;
      checkArrowVisibility();
    }
  };

  const checkArrowVisibility = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;

      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    const handleResize = () => {
      checkArrowVisibility();
    };

    if (container) {
      checkArrowVisibility();
      window.addEventListener('resize', handleResize);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="dashboard-widget-title">
        {`Today's Insights`}
        <span className="dashboard-widget-date">- {moment().tz('Asia/Dubai').format('MMMM D, YYYY')}</span>
      </div>
      <div className="scroll-container">
        {showLeftArrow && <FaChevronLeft className="scroll-button left" onClick={scrollLeft} />}
        {showRightArrow && <FaChevronRight className="scroll-button right" onClick={scrollRight} />}
        <div className="status-blocks" ref={scrollContainerRef} onScroll={checkArrowVisibility}>
          <div className="status-block" style={{ cursor: 'default', position: 'relative' }} title="Number of Sessions Done Today">
            <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
              <BsLightningCharge color="#3C7CDD" size={35} className="icon--color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>
                  {_.get(dailyWidgetList, 'total_session_count')
                    ? _.toNumber(_.get(dailyWidgetList, 'total_session_count')).toLocaleString('en-US')
                    : 0}
                </h1>
              )}
              <p>{'Sessions Today'}</p>
            </div>
            {(() => {
              const number = _.get(dailyWidgetList, 'sessionCountUpOnYesterday', 0);
              return (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '10px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: number > 0 ? 'green' : '#BE210B',
                  }}
                >
                  {number > 0 ? (
                    <>
                      <FaArrowUp color="green" />
                      {`${number}%`}
                    </>
                  ) : number === 0 || number === null ? (
                    <>{'0%'}</>
                  ) : (
                    <>
                      <FaArrowDown color="#BE210B" />
                      {`${Math.abs(number)}%`}
                    </>
                  )}
                </div>
              );
            })()}
          </div>
          <div className="status-block" style={{ cursor: 'default', position: 'relative' }} title="Number of Units Consumed Today">
            <div className="breating-area" style={{ background: '#be210b21' }}>
              <GiCharging color="#BE210B" size={35} className="icon-inner-color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{_.get(dailyWidgetList, 'total_unit_consumed') ? commaSeperate(_.get(dailyWidgetList, 'total_unit_consumed'), 2) : 0}</h1>
              )}
              <p>{'Unit Consumed (kWh)'}</p>
            </div>

            {(() => {
              const number = _.get(dailyWidgetList, 'unitConsumedUpOnYesterday', 0);
              return (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '10px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: number > 0 ? 'green' : '#BE210B',
                  }}
                >
                  {number > 0 ? (
                    <>
                      <FaArrowUp color="green" />
                      {`${number}%`}
                    </>
                  ) : number === 0 || number === null ? (
                    <>{'0%'}</>
                  ) : (
                    <>
                      <FaArrowDown color="#BE210B" />
                      {`${Math.abs(number)}%`}
                    </>
                  )}
                </div>
              );
            })()}
          </div>
          <div className="status-block" style={{ cursor: 'default', position: 'relative' }} title="Average Units Consumed Per Session Today">
            <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
              <BsBarChartFill color="#3C7CDD" size={35} className="icon-inner-color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{_.get(dailyWidgetList, 'average_unitconsumed') ? commaSeperate(_.get(dailyWidgetList, 'average_unitconsumed'), 2) : 0}</h1>
              )}
              <p>{'Average Consumption (kWh)'}</p>
            </div>
            {(() => {
              const number = _.get(dailyWidgetList, 'averageUnitConsumptionUpOnYesterday', 0);
              return (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '10px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: number > 0 ? 'green' : '#BE210B',
                  }}
                >
                  {number > 0 ? (
                    <>
                      <FaArrowUp color="green" />
                      {`${number}%`}
                    </>
                  ) : number === 0 || number === null ? (
                    <>{'0%'}</>
                  ) : (
                    <>
                      <FaArrowDown color="#BE210B" />
                      {`${Math.abs(number)}%`}
                    </>
                  )}
                </div>
              );
            })()}
          </div>
          <div className="status-block" style={{ cursor: 'default', position: 'relative' }} title="Amount Collected of the day in AED">
            <div className="breating-area" style={{ background: '#be210b21' }}>
              <TbCurrencyDirham color="#BE210B" size={35} className="icon--color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{_.get(dailyWidgetList, 'total_amount_collected') ? commaSeperate(_.get(dailyWidgetList, 'total_amount_collected'), 2) : 0}</h1>
              )}
              <p>{'Total Amount (AED)'}</p>
            </div>
            {(() => {
              const number = _.get(dailyWidgetList, 'revenueUpOnYesterday', 0);
              return (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '10px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: number > 0 ? 'green' : '#BE210B',
                  }}
                >
                  {number > 0 ? (
                    <>
                      <FaArrowUp color="green" />
                      {`${number}%`}
                    </>
                  ) : number === 0 || number === null ? (
                    <>{'0%'}</>
                  ) : (
                    <>
                      <FaArrowDown color="#BE210B" />
                      {`${Math.abs(number)}%`}
                    </>
                  )}
                </div>
              );
            })()}
          </div>
          <div className="status-block" style={{ cursor: 'default', position: 'relative' }} title="Average Duration of the day in Minutes">
            <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
              <CgSandClock color="#3C7CDD" size={35} className="icon-inner-color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{_.get(dailyWidgetList, 'average_duration') ? `${roundOfDigit(_.get(dailyWidgetList, 'average_duration'))}m` : '0m'}</h1>
              )}
              <p>{'Average Duration (min)'}</p>
            </div>
            {(() => {
              const number = _.get(dailyWidgetList, 'averageDurationUpOnYesterday', 0);
              return (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '10px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: number > 0 ? 'green' : '#BE210B',
                  }}
                >
                  {number > 0 ? (
                    <>
                      <FaArrowUp color="green" />
                      {`${number}%`}
                    </>
                  ) : number === 0 || number === null ? (
                    <>{'0%'}</>
                  ) : (
                    <>
                      <FaArrowDown color="#BE210B" />
                      {`${Math.abs(number)}%`}
                    </>
                  )}
                </div>
              );
            })()}
          </div>
          <div className="status-block" style={{ cursor: 'default', position: 'relative' }} title="Overstay Count of the day">
            <div className="breating-area" style={{ background: '#be210b21' }}>
              <MdMultilineChart color="#BE210B" size={35} className="icon-inner-color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{_.get(dailyWidgetList, 'total_overstay_count', 0)}</h1>
              )}
              <p>{'OverStay Count'}</p>
            </div>
            {(() => {
              const number = _.get(dailyWidgetList, 'overstayCountUpOnYesterday', 0);
              return (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '10px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: number > 0 ? 'green' : '#BE210B',
                  }}
                >
                  {number > 0 ? (
                    <>
                      <FaArrowUp color="green" />
                      {`${_.toNumber(number).toLocaleString('en-US')}`}
                    </>
                  ) : number === 0 || number === null ? (
                    <>{'0'}</>
                  ) : (
                    <>
                      <FaArrowDown color="#BE210B" />
                      {`${_.toNumber(Math.abs(number)).toLocaleString('en-US')}`}
                    </>
                  )}
                </div>
              );
            })()}
          </div>
          <div className="status-block" style={{ cursor: 'default', position: 'relative' }} title="Overstay Minutes of the day">
            <div className="breating-area" style={{ background: '#3c7cdd1a' }}>
              <RxLapTimer color="#3C7CDD" size={35} className="icon-inner-color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{`${_.toNumber(_.get(dailyWidgetList, 'total_overstay_mins', 0)).toLocaleString('en-US')}m`}</h1>
              )}
              <p>{'OverStay Minutes'}</p>
            </div>
            {(() => {
              const number = _.get(dailyWidgetList, 'overstayMinsUpOnYesterday', 0);
              return (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '10px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: number > 0 ? 'green' : '#BE210B',
                  }}
                >
                  {number > 0 ? (
                    <>
                      <FaArrowUp color="green" />
                      {`${_.toNumber(number).toLocaleString('en-US')}`}
                    </>
                  ) : number === 0 || number === null ? (
                    <>{'0'}</>
                  ) : (
                    <>
                      <FaArrowDown color="#BE210B" />
                      {`${_.toNumber(Math.abs(number)).toLocaleString('en-US')}`}
                    </>
                  )}
                </div>
              );
            })()}
          </div>
          <div className="status-block" style={{ cursor: 'default', position: 'relative' }} title="Overstay Amount of the day">
            <div className="breating-area" style={{ background: '#be210b21' }}>
              <FaFileInvoice color="#BE210B" size={35} className="icon-inner-color" />
            </div>
            <div className="status-info">
              {isLoading ? (
                <div className="loader--block">
                  <Spinner as="span" animation="border" size="sm" role="status" />
                </div>
              ) : (
                <h1>{`${commaSeperate(_.get(dailyWidgetList, 'total_overstay_amount', 0), 2)}`}</h1>
              )}
              <p>{'OverStay Amount'}</p>
            </div>
            {(() => {
              const number = _.get(dailyWidgetList, 'overstayAmountUpOnYesterday', 0);
              return (
                <div
                  style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '10px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: number > 0 ? 'green' : '#BE210B',
                  }}
                >
                  {number > 0 ? (
                    <>
                      <FaArrowUp color="green" />
                      {`${_.toNumber(number).toLocaleString('en-US')}`}
                    </>
                  ) : number === 0 || number === null ? (
                    <>{'0'}</>
                  ) : (
                    <>
                      <FaArrowDown color="#BE210B" />
                      {`${_.toNumber(Math.abs(number)).toLocaleString('en-US')}`}
                    </>
                  )}
                </div>
              );
            })()}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DailyWidgets;
