import {
    GET_DASHBOARD_AMOUNT_COLLECTED,
    GET_DASHBOARD_AMOUNT_COLLECTED_SUCCESS,
    GET_DASHBOARD_AMOUNT_COLLECTED_FAIL,
  } from 'actions/dashboard';
  
  const initialState = {
    isLoading: false,
    dashboardAmountCollected: {},
  };
  
  const dashboardAmountCollectedReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case GET_DASHBOARD_AMOUNT_COLLECTED: {
        return { ...state, isLoading: true };
      }
        
      case GET_DASHBOARD_AMOUNT_COLLECTED_SUCCESS: {
        return {
          ...state,
          isLoading: false,
          dashboardAmountCollected: payload,
        };
      }
  
      case GET_DASHBOARD_AMOUNT_COLLECTED_FAIL: {
        return { ...state, isLoading: false };
      }
  
      default:
        return { ...state };
    }
  };
  export default dashboardAmountCollectedReducer;
  