export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAIL = 'GET_DASHBOARD_DATA_FAIL';

export const GET_DASHBOARD_WIDGET_DATA = 'GET_DASHBOARD_WIDGET_DATA';
export const GET_DASHBOARD_WIDGET_DATA_SUCCESS = 'GET_DASHBOARD_WIDGET_DATA_SUCCESS';
export const GET_DASHBOARD_WIDGET_DATA_FAIL = 'GET_DASHBOARD_WIDGET_DATA_FAIL';

export const GET_DASHBOARD_CHART_DATA = 'GET_DASHBOARD_CHART_DATA';
export const GET_DASHBOARD_CHART_DATA_SUCCESS = 'GET_DASHBOARD_CHART_DATA_SUCCESS';
export const GET_DASHBOARD_CHART_DATA_FAIL = 'GET_DASHBOARD_CHART_DATA_FAIL';

export const GET_DASHBOARD_PIECHART_DATA = 'GET_DASHBOARD_PIECHART_DATA';
export const GET_DASHBOARD_PIECHART_DATA_SUCCESS = 'GET_DASHBOARD_PIECHART_DATA_SUCCESS';
export const GET_DASHBOARD_PIECHART_DATA_FAIL = 'GET_DASHBOARD_PIECHART_DATA_FAIL';

export const GET_DASHBOARD_ENERGY_SOLD = 'GET_DASHBOARD_ENERGY_SOLD';
export const GET_DASHBOARD_ENERGY_SOLD_SUCCESS = 'GET_DASHBOARD_ENERGY_SOLD_SUCCESS';
export const GET_DASHBOARD_ENERGY_SOLD_FAIL = 'GET_DASHBOARD_ENERGY_SOLD_FAIL';

export const GET_DASHBOARD_SESSION_COUNT = 'GET_DASHBOARD_SESSION_COUNT';
export const GET_DASHBOARD_SESSION_COUNT_SUCCESS = 'GET_DASHBOARD_SESSION_COUNT_SUCCESS';
export const GET_DASHBOARD_SESSION_COUNT_FAIL = 'GET_DASHBOARD_SESSION_COUNT_FAIL';

export const GET_DASHBOARD_DAILY_WIDGET_DATA = 'GET_DASHBOARD_DAILY_WIDGET_DATA';
export const GET_DASHBOARD_DAILY_WIDGET_DATA_SUCCESS = 'GET_DASHBOARD_DAILY_WIDGET_DATA_SUCCESS';
export const GET_DASHBOARD_DAILY_WIDGET_DATA_FAIL = 'GET_DASHBOARD_DAILY_WIDGET_DATA_FAIL';

export const GET_DASHBOARD_INCEPTION_WIDGET_DATA = 'GET_DASHBOARD_INCEPTION_WIDGET_DATA';
export const GET_DASHBOARD_INCEPTION_WIDGET_DATA_SUCCESS = 'GET_DASHBOARD_INCEPTION_WIDGET_DATA_SUCCESS';
export const GET_DASHBOARD_INCEPTION_WIDGET_DATA_FAIL = 'GET_DASHBOARD_INCEPTION_WIDGET_DATA_FAIL';

export const GET_DASHBOARD_AMOUNT_COLLECTED = 'GET_DASHBOARD_AMOUNT_COLLECTED';
export const GET_DASHBOARD_AMOUNT_COLLECTED_SUCCESS = 'GET_DASHBOARD_AMOUNT_COLLECTED_SUCCESS';
export const GET_DASHBOARD_AMOUNT_COLLECTED_FAIL = 'GET_DASHBOARD_AMOUNT_COLLECTED_FAIL';

export const GET_DASHBOARD_CHARGER_AVAILABILITY = 'GET_DASHBOARD_CHARGER_AVAILABILITY';
export const GET_DASHBOARD_CHARGER_AVAILABILITY_SUCCESS = 'GET_DASHBOARD_CHARGER_AVAILABILITY_SUCCESS';
export const GET_DASHBOARD_CHARGER_AVAILABILITY_FAIL = 'GET_DASHBOARD_CHARGER_AVAILABILITY_FAIL';
